import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonsModule } from '../../../../ui-components/buttons/buttons.module';
import { DirectivesModule } from '../../../../directives/directives.module';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { PipeModule } from '../../../../pipe/pipe.module';
import { IExpandableLinks } from '../../common-header.interface';
import { menuEnum, menuType } from '../../enums/enums';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
    selector: 'app-expandable-links-panel',
    standalone: true,
    imports: [ButtonsModule, DirectivesModule, PipeModule, NgTemplateOutlet, NgClass],
    templateUrl: './expandable-links-panel.component.html',
    styleUrl: './expandable-links-panel.component.scss',
    animations: [
        trigger('dropdownAnimation', [
            state(
                'closed',
                style({
                    height: '0',
                    overflow: 'hidden',
                    opacity: 0,
                })
            ),
            state(
                'open',
                style({
                    height: '*',
                    overflow: 'hidden',
                    opacity: 1,
                })
            ),
            transition('closed <=> open', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class ExpandableLinksPanelComponent implements OnInit {
    showDropdown = false;
    @Input() config: IExpandableLinks[] = [];
    @Output() toggleMenuModal = new EventEmitter();

    deviceInfo = this.utilsService.getDeviceInfo();
    protected readonly menuType = menuType;
    constructor(
        private utilsService: UtilsService,
        private router: Router
    ) {}

    ngOnInit() {
        this.openDropdownForCurrentUrl();
    }

    toggleDropdown(show: boolean): void {
        this.showDropdown = show;
    }

    itemById(index: number, item: IExpandableLinks) {
        return item.id;
    }

    handleDropdownToggle(menuItem: menuEnum) {
        this.config = this.config.map(item => {
            if (item.type === menuType.ACCORDION) {
                if (item.id === menuItem) {
                    return { ...item, isOpen: !item.isOpen };
                } else {
                    item.items = item.items.map(subItem => {
                        if (subItem.type === menuType.ACCORDION && subItem.id === menuItem) {
                            return { ...subItem, isOpen: !subItem.isOpen };
                        }
                        return subItem;
                    });
                    return { ...item };
                }
            }
            return item;
        });
    }
    calcStyles(level: number) {
        const borderStyle = '1px solid #E0E0E0';

        if (level <= 1) {
            return {
                'margin-left': `${24 * (level - 1)}px`,
            };
        } else {
            return {
                'margin-left': `24px`,
                'border-left': borderStyle,
            };
        }
    }

    handleNavigation(event: Event, link: string) {
        event.preventDefault();
        event.stopPropagation();
        if (link) {
            this.router.navigate([link]);
            this.toggleMenuModal.emit();
        }
    }
    isSelected(link?: string) {
        return this.router.url === link;
    }

    /**
     * Opens dropdown for the item or sub-item that matches the current URL.
     */
    openDropdownForCurrentUrl() {
        const currentUrl = this.router.url;
        this.config = this.config.map(expandableLinks => {
            if (
                expandableLinks?.type === menuType.ACCORDION &&
                (this.hasMatchingLink(expandableLinks?.items, currentUrl) || expandableLinks?.link === currentUrl)
            ) {
                return { ...expandableLinks, isOpen: true };
            }
            return expandableLinks;
        }) as IExpandableLinks[];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasMatchingLink(items: any, currentUrl: string): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return items?.some((item: any) => item?.link === currentUrl);
    }
}
