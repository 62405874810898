@if (whyMyHQContent$ | async; as whyMyHq) {
    <div class="why-myhq-cont padding-24" [ngClass]="class">
        @if (whyMyHq?.benefits) {
            <div>
                <h2 class="font-18 font-24-desk font-bolder margin-bottom-24">{{ whyMyHq?.heading }}</h2>
                <div [ngClass]="containerClass">
                    @for (benefit of whyMyHq?.benefits; track benefit) {
                        <div class="card-container">
                            <div [ngClass]="itemContainerClass">
                                <div class="icon-cont d-flx">
                                    <img
                                        appImageShimmerLazyLoad
                                        class="image-cover object-fit-contain"
                                        [imageSrc]="benefit.image.url"
                                        [alt]="benefit.image.alt"
                                    />
                                </div>
                                <div>
                                    <h3 class="heading">{{ benefit.heading }}</h3>
                                    @if (benefit.description) {
                                        <div
                                            class="desc font-normal"
                                            [ngClass]="{ 'show-desk': whyMyHq.hideDescriptionsMob }"
                                        >
                                            {{ benefit.description }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}
@if (footerTemplate) {
    <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
}
