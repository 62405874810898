export enum VirtualOfficeFormType {
    STICKY_BUTTON = 'STICKY_BUTTON',
    FLOATING_BUTTON = 'FLOATING_BUTTON',
    MODAL = 'MODAL',
    STICKY_FORM = 'STICKY_FORM',
    DEFAULT = 'DEFAULT',
}

export enum VirtualOfficeFormSuccessType {
    COUPON = 'COUPON',
    DEFAULT = 'DEFAULT',
}
