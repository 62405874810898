import { map } from 'rxjs/operators';
import { ObserverQueue } from '../../../classes/observerQueue.class';
import { Injectable, NgZone } from '@angular/core';
import { zip, interval } from 'rxjs';
import { toPromiseAndEnqueueTask } from '../../../decorators/toPromiseAndEnqueueTask.decorator';
import {
    SpecificPageViewedEvents,
    FlexiTrialFunnelStages,
    DLFormFunnelStages,
    TeamFlexiLFormFunnelStages,
    DVisitFormFunnelStages,
    VirtualOfficeLFormFunnelStages,
    MeetingRoomLFormFunnelStages,
    MOFormFunnelStages,
    HomeFormFunnelStages,
    ExitIntentFormEvents,
    FlexiBookingEvents,
} from '../enums';
import { productsMap } from '../../../../shared/enums/enums';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

const queue = new ObserverQueue();

@Injectable({
    providedIn: 'root',
})
export class FacebookAnalyticsService {
    // Doc details this https://developers.facebook.com/docs/facebook-pixel/reference#utilizing_params
    init() {
        window['facebookAnalyticsService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoaded(),
        };
    }

    onScriptLoaded() {
        zip(queue, interval(10))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .pipe(map(([queueItem, d]) => queueItem))
            .subscribe(({ event, resolve }) => {
                resolve(event());
            });
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackPageView(): any {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'PageView');
        }
    }

    @toPromiseAndEnqueueTask(queue)
    trackPurchasedEvent(properties) {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'Purchase', {currency: "INR", value: properties.credits});
        }
    }

    @toPromiseAndEnqueueTask(queue)
    trackEvent(action, properties) {
        if (Object.values(SpecificPageViewedEvents).includes(action)) {
            this.trackSpecificPageViewEvents(action, properties);
        } else if (
            Object.values(TeamFlexiLFormFunnelStages).includes(action) || 
            Object.values(DLFormFunnelStages).includes(action) || 
            Object.values(VirtualOfficeLFormFunnelStages).includes(action) ||
            Object.values(MeetingRoomLFormFunnelStages).includes(action) ||
            Object.values(MOFormFunnelStages).includes(action) ||
            Object.values(HomeFormFunnelStages).includes(action) || 
            Object.values(ExitIntentFormEvents).includes(action)
        ) {
            this.trackLeadEvents(action);
        } else if (Object.values(FlexiTrialFunnelStages).includes(action)) {
            this.trackTrialEvent(action);
        } else if (Object.values(DVisitFormFunnelStages).includes(action)) {
            this.trackVisitEvent(action);
        } else if(action === FlexiBookingEvents.BOOKING_SUCCESS_DESK || action === FlexiBookingEvents.BOOKING_SUCCESS_MR) {
            this.trackCustomEvents(action, properties);
            this.trackPurchasedEvent(properties);
        } else {
            this.trackCustomEvents(action, properties);
        }
    }

    constructor(private ngZone: NgZone) {}

    private trackContentView(properties) {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'ViewContent', properties);
        }
    }

    private trackLead(properties) {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'Lead', properties);
        }
    }

    private trackCustomEvents(action, properties) {
        if (typeof fbq !== 'undefined') {
            fbq('trackCustom', action, properties);
        }
    }

    private trackSpecificPageViewEvents(action, properties) {
        switch (action) {
            case SpecificPageViewedEvents.WORKSPACE_VIEWED:
                return this.trackContentView({
                    content_name: action,
                    content_category: properties.product,
                    content_ids: this.getWorkspaceFBProductId([properties.workspaceId]),
                });
            // TODO: Setupremarketing based on Search & Listing
            case SpecificPageViewedEvents.LISTING_PAGE_VIEWED:
                return this.trackContentView({
                    content_name: action,
                    content_category: properties.product,
                });
            default:
                return this.trackContentView({
                    content_name: action,
                });
        }
    }

    private trackLeadEvents(action) {
        switch (action) {
            case DLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.DEDICATED.productNameBackend,
                });
            case MOFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.MANAGED_OFFICE.productNameBackend,
                });
            case TeamFlexiLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.CORPORATE_FLEXI.productNameBackend,
                });
            case VirtualOfficeLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.VIRTUAL_OFFICE.productNameBackend,
                });
            case MeetingRoomLFormFunnelStages.MR_LFORM_SUBMIT: 
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.MEETING_ROOM.productNameBackend,
                });
            case HomeFormFunnelStages.FORM_STEP1_SUBMIT: 
                return this.trackLead({
                    content_name: action,
                    content_category: 'GENERIC',
                });
            case ExitIntentFormEvents.LFORM_SUBMIT: 
                return this.trackLead({
                    content_name: action,
                    content_category: 'GENERIC',
                });
            default:
                return;
        }
    }

    private trackTrialEvent(action) {
        switch (action) {
            case FlexiTrialFunnelStages.TRIAL_DETAIL_FORM_SUBMIT:
            case FlexiTrialFunnelStages.SUCCESS:
                return this.trackCustomEvents(action, {});
            default:
                return;
        }
    }

    private trackVisitEvent(action) {
        switch (action) {
            case DVisitFormFunnelStages.STEP1_SUBMIT:
            case DVisitFormFunnelStages.SUCCESS:
                return this.trackCustomEvents(action, {});
            default:
                return;
        }
    }

    private getWorkspaceFBProductId(workspaceIds: [string]) {
        return workspaceIds.map(id => `${id.slice(0, 4)}${id.slice(-4)}`);
    }

}
