import { Injectable, OnInit } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor(private utilsService: UtilsService) {}

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle, @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    getCacheUrlKey(request) {
        let key = request.urlWithParams;
        if (request.method === 'POST') {
            let stringifiedPayload = '';
            const flatObject = this.utilsService.getFlattenObjectSortedOnKeys(request.body);
            stringifiedPayload = JSON.stringify(flatObject, Object.keys(flatObject).sort());
            key = `${key}-${stringifiedPayload}`;
        }
        return key;
    }
}
