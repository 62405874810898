import { Observable, of } from 'rxjs';
import { DestroyRef, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/core';
import { InterceptorService } from '../services/interceptor/interceptor.service';
import { InterceptorMetadataKeys } from '../../shared/enums/enums';
import { CustomHttpParams } from './custom-params.interceptor';
import { MobileOtpAuthService } from '../../shared/services/auth/mobile-otp-auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
    isAuthenticatedFlexiUser = false;
    private authenticatedNoCacheEndpoints = [
        '/workspace/web/flexi/list-slug',
        '/meeting-room/web/list-slug',
        '/workspace/web/flexi/workpsacedetails',
    ];

    private isUrlMeetingRoomSpaceDetails(url: string): boolean {
        // Handle meeting room URLs with workspace slugs
        if (url.startsWith('/meeting-room/web/')) {
            // Exclude the specific list-map endpoint
            if (url === '/meeting-room/web/list-map') {
                return false;
            }

            // Include other meeting room URLs with workspace slugs
            if (url.match(/^\/meeting-room\/web\/[\w-]+$/)) {
                return true;
            }
        }

        return false;
    }

    constructor(
        private transferState: TransferState,
        private interceptorService: InterceptorService,
        private authService: MobileOtpAuthService,
        private destroyRef: DestroyRef
    ) {
        this.authService
            .getUserDataSub()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(data => {
                this.isAuthenticatedFlexiUser = data !== null && data?.isAuthenticated;
            });
    }

    private shouldSkipCache(url: string): boolean {
        // FIX: OD-1336 - temporary fix
        // This is required because we don't have auth user during SSR
        // So we disable cache for certain endpoints where we need to show specific data
        // for logged in users

        if (!this.isAuthenticatedFlexiUser) {
            return false;
        }

        // Check if the URL exactly matches any of our defined endpoints, or
        // Check for MR space details (needs more complex logic for skipping)
        if (
            this.authenticatedNoCacheEndpoints.some(endpoint => url.includes(endpoint)) ||
            this.isUrlMeetingRoomSpaceDetails(url)
        ) {
            return true;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (['GET', 'POST'].indexOf(req.method) === -1) {
            return next.handle(req);
        }
        const params = req.params as CustomHttpParams;

        if (params?.interceptorMetadata?.[InterceptorMetadataKeys.HQ_DISABLE_CACHE]) {
            return next.handle(req);
        }

        if (this.shouldSkipCache(req.url)) {
            return next.handle(req);
        }

        const storedResponse: string = this.transferState.get(
            makeStateKey(this.interceptorService.getCacheUrlKey(req)),
            null
        );
        if (storedResponse) {
            const response = new HttpResponse({ body: storedResponse, status: 200 });
            return of(response);
        }
        return next.handle(req);
    }
}
