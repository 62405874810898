import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { filter } from 'rxjs/operators';
import { flexiUrlRegex, guidesRegex, virtualOfficeUrlRegex } from '../../../core/constants/urls-regex';

import { VirtualOfficeBannerComponent } from './components/virtual-office-banner.component';
import { StorageService } from '../../services/storage/storage.service';
import { PrefixBannerService } from '../../prefix-banner/prefix-banner.service';
import { productKeys } from '../../enums/enums';
import { AsyncPipe } from '@angular/common';

// How does <app-prefix-banner> work?
// 1. It is used to display a banner on the top of the page with dynamic pre-defined content
// 2. It can be sticky or non-sticky
// 3. To accomodate for the sticky case, we need to adjust the `stickySectionDirective` so it gets sticky below the banner
// 4. This is done by computing the height of the banner when it is sticky. This value now can be consumed by `stickySectionDirective` or `appReactiveStickyHeader`

@Component({
    standalone: true,
    selector: 'app-prefix-banner',
    templateUrl: './app-prefix-banner.component.html',
    styleUrls: ['./app-prefix-banner.component.scss'],
    imports: [VirtualOfficeBannerComponent, AsyncPipe],
})
export class AppPrefixBannerComponent {
    protected readonly productKeys = productKeys;
    bannerInstance$ = this.prefixBannerService.getBannerInstance();
    deviceInfo = this.utilsService.getDeviceInfo();
    closeIcon = { url: CommonIconsUrl.CLOSE_WHITE, alt: 'offer-ticket' };

    // updates the height of the banner between change detection cycles automatically
    @ViewChild('appPrefixBanner') set appPrefixBanner(appPrefixBanner: ElementRef) {
        this.prefixBannerService.setHeight(appPrefixBanner.nativeElement.clientHeight);
    }

    constructor(
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private storageService: StorageService,
        private prefixBannerService: PrefixBannerService
    ) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            if (!(event instanceof NavigationEnd)) return;

            const { getRegexPatternMatch, isiOSSafari } = this.utilsService;
            const url = event.url;

            // Handle Flexi banner
            const isFlexiPage = getRegexPatternMatch([flexiUrlRegex.productPage], url) >= 0;
            if (isFlexiPage && !isiOSSafari()) {
                return this.prefixBannerService.setAndShow({ type: productKeys.FLEXI });
            }

            // Handle Virtual Office banner
            const leadSubmitted = this.storageService.getSession('vo-header-lead');
            const isVirtualOfficePage =
                getRegexPatternMatch([virtualOfficeUrlRegex.productPage, guidesRegex.guides], url) >= 0;
            if (isVirtualOfficePage && !leadSubmitted) {
                return this.prefixBannerService.setAndShow({
                    type: productKeys.VIRTUAL_OFFICE,
                    isSticky: this.deviceInfo.isMobile,
                });
            }

            this.prefixBannerService.hide();
        });
    }

    hideBanner() {
        this.prefixBannerService.hide();
    }

    redirectToDownloadApp() {
        this.trackAppDownloadAnalytics();
        window.open(this.utilsService.getAppDownloadLink(), '_blank');
    }

    trackAppDownloadAnalytics() {
        this.analyticsService.trackAppDownloadRedirectAction({
            RedirectType: this.deviceInfo.os,
            position: 'FLEXI_TOP_BANNER',
        });
    }
}
