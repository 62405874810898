import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Observable } from 'rxjs';
import { IWhyChooseMyHq } from '../../interfaces/why-choose-my-hq.interface';
import { WhyChooseMyHQCardItemType, WhyChooseMyHQCardType } from './why-choose-myhq.enum';

/**
 * Deprecated, please use app-grid-card-banner
 */
@Component({
    standalone: true,
    selector: 'app-why-choose-myhq',
    templateUrl: './why-choose-myhq.component.html',
    imports: [DirectivesModule, AsyncPipe, NgClass, NgTemplateOutlet],
    styleUrls: ['./why-choose-myhq.component.scss'],
})
export class WhyChooseMyhqComponent implements OnInit {
    whyMyHQContent$: Observable<IWhyChooseMyHq>;
    containerClass: string;
    itemContainerClass: string;
    @Input('config') set setConfig(data: Observable<IWhyChooseMyHq>) {
        this.whyMyHQContent$ = data;
    }
    @Input() cardType: WhyChooseMyHQCardType = WhyChooseMyHQCardType.DEFAULT;
    @Input() cardTypeConfig?: { columnsCount: number };
    @Input() cardItemType: WhyChooseMyHQCardItemType = WhyChooseMyHQCardItemType.DEFAULT;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() footerTemplate: TemplateRef<any>;
    @Input() class: string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    ngOnInit() {
        switch (this.cardType) {
            case WhyChooseMyHQCardType.DEFAULT:
                this.containerClass = 'd-flx j-cont-space-bet flx-wrp gap-12-mob';
                break;
            case WhyChooseMyHQCardType.GRID:
                // eslint-disable-next-line no-case-declarations
                const columnsCount = this.cardTypeConfig?.columnsCount || 2;
                this.containerClass = 'd-grid ';
                if (columnsCount) {
                    this.containerClass += ` grid-layout-col-${columnsCount}`;
                }
                break;
            case WhyChooseMyHQCardType.ROW_COLUMN:
                this.containerClass = 'd-flx flx-d-clm-row j-cont-space-bet flx-wrp row-col-layout';
                break;
            default:
                this.containerClass = 'd-flx j-cont-space-bet flx-wrp gap-12-mob';
                break;
        }
        this.getCardItemClass();
    }

    getCardItemClass() {
        switch (this.cardItemType) {
            case WhyChooseMyHQCardItemType.DEFAULT:
                this.itemContainerClass = 'd-flx flx-d-row';
                break;
            case WhyChooseMyHQCardItemType.COLUMN:
                this.itemContainerClass = 'd-flx flx-d-row-clm flx-1 a-items-c gap-12 clm-layout';
                break;
            default:
                this.itemContainerClass = 'd-flx flx-d-row';
                break;
        }
    }
}
