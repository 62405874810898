import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadSuccessComponent } from './lead-success.component';

@NgModule({
    declarations: [LeadSuccessComponent],
    imports: [CommonModule],
    exports: [LeadSuccessComponent],
})
export class LeadSuccessModule {}
