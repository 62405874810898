import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalContainerComponent } from '../modal-container/modal-container.component';

@Component({
    standalone: true,
    selector: 'app-modal-form-container',
    templateUrl: './modal-form-container.component.html',
    styleUrls: ['./modal-form-container.component.scss'],
    imports: [ModalContainerComponent],
})
export class ModalFormContainerComponent {
    config?: { closeable: boolean } = { closeable: true };
    @Input('config') set updateConfig(data: { closeable: boolean }) {
        if (!data) {
            this.config = { closeable: true };
        } else {
            this.config = data;
        }
    }
    @Input() containerClass: string;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClose = new EventEmitter();

    handleModalClose() {
        this.onClose.emit();
    }
}
