<div
    class="show-desk-flx gap-16 flx-d-clm j-cont-space-bet bg-color-blue padding-24-desk padding-16-mob border-top-left-radius-4 border-bottom-left-radius-4 height-100 width-max-content"
>
    <div class="d-flx flx-d-clm">
        <h3 class="font-14 font-bold d-flx a-items-c margin-bottom-12">
            <span>{{ config?.heading }}</span>
            <span class="margin-left-4 text-color-primary d-flx a-items-c j-cont-cen" [innerHtml]="starsIcon"></span>
        </h3>
        <div class="bg-color-white-disabled border-radius-2 margin-bottom-32">
            <div class="img-cont position-relative">
                <app-optimized-image
                    cssClass="image-contain"
                    priority
                    fill
                    [image]="config?.clientsLogo"
                    sizes="(max-width: 720px) 90vw, 30vw"
                ></app-optimized-image>
            </div>
        </div>
        <div class="d-flx flx-d-clm gap-12">
            <h3 class="font-14 font-bold d-flx a-items-c">
                <span>{{ config?.featuresTitle }}</span>
                <span class="margin-left-4 text-color-primary d-flx a-items-c j-cont-cen" [innerHtml]="starIcon"></span>
            </h3>

            <div class="features-cont d-flx flx-d-clm gap-4 padding-left-12">
                @for (feature of config?.features; track feature) {
                    <p class="font-12 font-bold">
                        {{ feature }}
                    </p>
                }
            </div>
        </div>
    </div>

    <div [innerHtml]="logoIcon" class="logo-item d-flx a-items-c j-cont-cen"></div>
</div>

<div class="show-mob-tab-flx flx-d-clm gap-12 padding-12 border-radius-4 bg-color-blue width-100">
    <div class="d-flx a-items-c gap-4">
        <h3 class="font-10 font-bold d-flx a-items-c">
            <span>{{ config?.featuresTitle }}</span>
            <span
                class="margin-left-4 text-color-matte-violet d-flx a-items-c j-cont-cen svg-cont star-icon-cont"
                [innerHtml]="starIcon"
            ></span>
        </h3>
        <div class="flx-1 line"></div>
    </div>
    <div class="features-cont d-flx flx-d-clm gap-4 padding-left-12">
        @for (feature of config?.features; track feature) {
            <p class="font-10">
                {{ feature }}
            </p>
        }
    </div>
</div>
