import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { buttonHTMLType, buttonType, fontSizes, fontWeights } from '../../enums/enums';
import { ButtonIconPosition, IButtonConfig } from '../../interfaces/button.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';

@Component({
    selector: 'app-button',
    templateUrl: './buttons.component.html',
    styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
    ImageBaseUrl = ImageBaseUrl;
    buttonHTMLType = buttonHTMLType;
    buttonType = buttonType;
    field: IButtonConfig;
    iconPosition: string = ButtonIconPosition.LEFT;
    @Input('field') set updateConfig(data: IButtonConfig) {
        if (data) {
            this.field = data;
        }
    }
    @Input() fontSize?: string;
    @Input() showLoader?: boolean;
    @Input() form?: UntypedFormGroup;
    @Input() additionalClass?: string;
    @Input() showEllipsis = true;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDisabledClick = new EventEmitter();
    @ViewChild('label') label: ElementRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any = {
        cssClass: null,
    };
    constructor(private renderer: Renderer2) {}

    addClassBasedOnType(field: IButtonConfig) {
        switch (field.type) {
            case buttonType.PRIMARY:
                this.config.cssClass = `btn btn-primary ${field.cssClass ? field.cssClass : ''}`;
                break;
            case buttonType.SECONDARY:
                this.config.cssClass = `btn btn-outline-primary ${field.cssClass ? field.cssClass : ''}`;
                break;
            case buttonType.LINK:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-link` : 'btn btn-link';
                break;
            case buttonType.LIGHT:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-light` : 'btn btn-light';
                break;
            case buttonType.OUTLINE:
                this.config.cssClass = field.cssClass
                    ? `${field.cssClass} btn btn-outline-primary`
                    : 'btn btn-outline-primary';
                break;
            case buttonType.DARK:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-dark` : 'btn btn-dark';
                break;
            case buttonType.MEDIUM_GREY:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-medium-grey` : 'btn btn-medium-grey';
                break;
            case buttonType.GREY:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-grey` : 'btn btn-grey';
                break;
            case buttonType.GREEN:
                this.config.cssClass = field.cssClass ? `${field.cssClass} btn btn-green` : 'btn btn-green';
                break;
            default:
        }
    }

    addClassBasedOnSize(field: IButtonConfig) {
        if (field.size === fontSizes.X_LARGE) {
            this.config.cssClass = `${this.config.cssClass} x-large-btn`;
        } else if (field.size === fontSizes.LARGE) {
            this.config.cssClass = `${this.config.cssClass} large-btn`;
        } else if (field.size === fontSizes.SMALL) {
            this.config.cssClass = `${this.config.cssClass} small-btn`;
        } else if (field.size === fontSizes.MEDIUM_MOB_DESK) {
            this.config.cssClass = `${this.config.cssClass} medium-mob-desk-btn`;
        } else if (field.size === fontSizes.MEDIUM_MOB_LARGE_DESK) {
            this.config.cssClass = `${this.config.cssClass} medium-mob-large-desk-btn`;
        } else {
            this.config.cssClass = `${this.config.cssClass} medium-btn`;
        }
    }

    addClassBasedOnFontWeight(field: IButtonConfig) {
        switch (field.fontWeight) {
            case fontWeights.BOLD:
                this.config.cssClass += ' font-bold';
                break;
            case fontWeights.BOLDER:
                this.config.cssClass += ' font-bolder';
                break;
        }
    }

    ngOnInit() {
        if (!this.field) {
            return;
        }

        this.addClassBasedOnType(this.field);
        this.addClassBasedOnSize(this.field);
        this.addClassBasedOnFontWeight(this.field);
        if (this.field?.iconPosition) {
            this.iconPosition =
                this.field?.iconPosition === 'inline' ? ButtonIconPosition.RIGHT : this.field.iconPosition;
        }
        if (this.fontSize) {
            this.renderer.setStyle(this.label.nativeElement, 'font-size', this.fontSize);
        }
    }

    onClickEmitEvent($event) {
        this.onClick.emit($event);
    }

    @HostListener('click', ['$event'])
    onDisabledClickEmitEvent(event: MouseEvent) {
        if (this.field.disabled) {
            this.onDisabledClick.emit(event);
        }
    }
}
