import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    DropdownMenuItemType,
    IAccordionMenuItem,
    IMenuWithDropdownSubMenu,
} from '../menu-with-dropdown/menu-with-dropdown.interface';
import { CommonModule } from '@angular/common';
import { AccordionModule } from '../../../../ui-components/accordion/accordion.module';
import { AccordionType } from '../../../../ui-components/accordion/accordion.enum';
import { IAccordionConfig } from '../../../../ui-components/accordion/accordion.component';
import { RouterModule } from '@angular/router';

@Component({
    standalone: true,
    selector: 'app-mobile-dropdown-menu',
    templateUrl: './mobile-dropdown-menu.component.html',
    styleUrls: ['./mobile-dropdown-menu.component.scss'],
    imports: [CommonModule, AccordionModule, RouterModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDropdownMenuComponent implements AfterViewInit {
    private cdr = inject(ChangeDetectorRef);
    protected readonly DropdownMenuItemType = DropdownMenuItemType;

    @Input() menu: IMenuWithDropdownSubMenu;
    @Output() menuItemClick = new EventEmitter<void>();

    @ViewChild('accordionMenuItemBodyTemplate') accordionMenuItemBodyTemplate: TemplateRef<unknown>;
    @ViewChild('accordionMenuItemHeaderTemplate') accordionMenuItemHeaderTemplate: TemplateRef<unknown>;

    handleCollapse() {
        this.menu.collapsed = !this.menu.collapsed;
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.menu.menuItems.forEach(menuItem => {
            if ('type' in menuItem && menuItem.type === DropdownMenuItemType.ACCORDION) {
                (menuItem as any).config = this.prepareAccordionMenuItem(menuItem);
            }
        });

        this.cdr.detectChanges();
    }

    prepareAccordionMenuItem(menuItem: IAccordionMenuItem): IAccordionConfig {
        const accordionData = [
            {
                title: '',
                template: this.accordionMenuItemBodyTemplate,
                templateData: {
                    menuItem,
                },
                headerTemplate: this.accordionMenuItemHeaderTemplate,
                headerTemplateData: {
                    menuItem,
                },
            },
        ];

        return {
            isAnimated: true,
            closeOthers: false,
            isFirstOpen: false,
            accordionData,
            type: AccordionType.PRIMARY,
        };
    }

    handleMenuItemClick() {
        this.menuItemClick.emit();
    }
}
