import { afterNextRender, Component, ElementRef, inject, Input, QueryList, ViewChildren } from '@angular/core';
import {
    IFooterCMSCTAConfig,
    IFooterCmsMetaDataConfig,
    IParsedFooterCmsMetaDataConfig,
} from './footer-cms-metadata.interfaces';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { UtilsService } from '../../../core/services/utils/utils.service';

@Component({
    selector: 'app-footer-cms-metadata',
    templateUrl: './footer-cms-metadata.component.html',
    styleUrls: ['./footer-cms-metadata.component.scss'],
})
export class FooterCmsMetadataComponent {
    areaMetaDataList: IParsedFooterCmsMetaDataConfig[] = [];
    deviceInfo = inject(UtilsService).getDeviceInfo();

    @Input('config') set updateConfig(config: IFooterCmsMetaDataConfig[]) {
        this.areaMetaDataList = this.parseMetaData(config);

        if (this.contentContainer) {
            // Using setTimeout to avoid forced reflow
            setTimeout(() => this.checkOverflow());
        }
    }

    @ViewChildren('contentContainer') contentContainer: QueryList<ElementRef>;

    constructor() {
        afterNextRender(() => {
            this.checkOverflow();
        });
    }

    private checkOverflow() {
        this.contentContainer.forEach((item, index) => {
            const element = item.nativeElement.querySelector('.content-container');
            let showBtn = false;
            showBtn = element.scrollHeight > element.clientHeight;

            this.areaMetaDataList = this.areaMetaDataList.map((metaData, idx) => {
                if (idx === index) {
                    return {
                        ...metaData,
                        ctaConfig: {
                            ...metaData.ctaConfig,
                            show: showBtn,
                        },
                    };
                } else {
                    return metaData;
                }
            });
        });
    }

    getCTAConfig(isExpanded: boolean): IFooterCMSCTAConfig {
        return {
            label: isExpanded ? 'View Less' : 'View More',
            icon: isExpanded ? CommonIconsUrl.ARROW_UP_PRIMARY : CommonIconsUrl.ARROW_DOWN_PRIMARY,
        };
    }

    parseMetaData(data: IFooterCmsMetaDataConfig[]): IParsedFooterCmsMetaDataConfig[] {
        return (
            data?.map(metaData => {
                return {
                    ...metaData,
                    isExpanded: false,
                    ctaConfig: {
                        ...this.getCTAConfig(false),
                    },
                };
            }) || []
        );
    }

    toggleViewMore(index: number) {
        this.areaMetaDataList = this.areaMetaDataList.map((item, i) => {
            if (index === i) {
                return {
                    ...item,
                    isExpanded: !item.isExpanded,
                    ctaConfig: {
                        ...this.getCTAConfig(!item.isExpanded),
                        show: item.ctaConfig.show,
                    },
                };
            } else {
                return item;
            }
        });
    }
}
