import { ITooltipConfig } from '../tooltip/tooltip.component';
import { SafeHtml } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';

export interface IFormListItem {
    description: string;
    svgLogo: string;
    tooltipConfig?: ITooltipConfig;
    svgHtml?: SafeHtml;
}

export enum FormHeaderListType {
    VERTICAL_LIST = 'VERTICAL_LIST',
    SEQUENTIAL_LIST = 'SEQUENTIAL_LIST',
    SEQUENTIAL_GREEN_LIST = 'SEQUENTIAL_GREEN_LIST',
}
export interface IFormHeaderConfig {
    heading: string;
    description?: string;
    list?: IFormListItem[];
    listType?: FormHeaderListType;
    cssClasses?: {
        heading?: string;
        description?: string;
    };
    animationOptions?: AnimationOptions;
    isAnimationEnabled?: boolean;
}
