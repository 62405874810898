import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ICity } from '../../interfaces/location.interface';
import { LocationService } from '../../services/location/location.service';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { CommonModule } from '@angular/common';
import { cityChangeSource, products } from '../../enums/enums';
import { ISelectCityModalConfig } from './select-city-modal.interface';
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { DirectivesModule } from '../../directives/directives.module';
import { PipeModule } from '../../pipe/pipe.module';
import { AppModalModule } from '../../ui-components/app-modal/app-modal.module';
import { CommonXhrService } from '../../services/common-xhr/common-xhr.service';
import { WhyChooseMyhqComponent } from '../../standalone-components/why-choose-myhq/why-choose-myhq.component';
// @ts-expect-error: logo
import Logo from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/logo/logo-flat.svg';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { animatedImagePath } from '../../../core/constants/constants';
import { IWhyChooseMyHq } from '../../interfaces/why-choose-my-hq.interface';

@Component({
    standalone: true,
    selector: 'app-select-city-modal',
    imports: [CommonModule, DirectivesModule, PipeModule, AppModalModule, WhyChooseMyhqComponent, LottieComponent],
    templateUrl: './select-city-modal.component.html',
    styleUrls: ['./select-city-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCityModalComponent implements OnInit {
    private locationService = inject(LocationService);
    private selectCityService = inject(SelectCityService);
    private commonXhrService = inject(CommonXhrService);
    private sanitizer = inject(DomSanitizer);

    product$ = new BehaviorSubject<products | null>(null);

    @Input('config') set _config(data: ISelectCityModalConfig) {
        if (data) {
            this.open$.next(data.showModal);
        }
    }
    @Input({ transform: booleanAttribute }) closable = false;
    @Input({ alias: 'product' }) set _product(value: products | null) {
        this.product$.next(value);
    }

    readonly logo = this.sanitizer.bypassSecurityTrustHtml(Logo);

    readonly animationOptions: AnimationOptions = {
        path: animatedImagePath.OFFER_TAG,
        loop: true,
    };

    ngOnInit(): void {
        this.selectedCity$.subscribe(city => {
            if (city) {
                this.toggleSelectCityModal(false);
                return;
            }

            this.toggleSelectCityModal(true);
        });
    }

    whyChooseMyhqContent$: Observable<IWhyChooseMyHq> = this.product$.pipe(
        map(product => {
            switch (product) {
                case products.MEETING_ROOM:
                    return this.commonXhrService.getMeetingRoomsWhyMyHQContent();
                case products.INDIVIDUAL:
                    return this.commonXhrService.getWhyMyHQFlexiContent();
                case products.VIRTUAL_OFFICE:
                    return this.commonXhrService.getWhyMyHQVOContent();
                default:
                    return this.commonXhrService.getWhyMyHQContent();
            }
        }),
        switchMap(content => content),
        map(content => ({ ...content, hideDescriptionsMob: true }) as IWhyChooseMyHq)
    );
    open$ = new BehaviorSubject(false);
    heading$ = this.product$.pipe(
        map(product => {
            switch (product) {
                case products.MEETING_ROOM:
                    return 'Welcome to myHQ Meeting Rooms';
                case products.INDIVIDUAL:
                    return 'Welcome to myHQ Day Pass';
                case products.VIRTUAL_OFFICE:
                    return 'Welcome to myHQ Virtual Office';
                default:
                    return 'Welcome to myHQ';
            }
        })
    );

    subheading$ = this.product$.pipe(
        map(product => {
            switch (product) {
                case products.MEETING_ROOM:
                    return 'Book Meeting Rooms-by-the-hour on the go. Ideal for business meetings, interviews, brainstorming sessions, trainings, and seminars.';
                case products.INDIVIDUAL:
                    return 'Book Desks-by-the-day in coworking spaces. Ideal for Individuals and small teams working in hybrid work model or remotely.';
                case products.VIRTUAL_OFFICE:
                    return 'Company registration & GST solutions for new businesses. No physical office costs or high monthly rents.';
                default:
                    return 'A one-stop workspace solution for all work-needs. Choose between pay-per-use plans or fixed desks for teams and individuals.';
            }
        })
    );

    private getAvailableCitiesResponse$ = this.product$.pipe(
        switchMap(product => this.locationService.getAvailableCity(product)),
        shareReplay(1)
    );

    private availableCities$ = this.getAvailableCitiesResponse$.pipe(
        map(data => {
            return data?.cities?.map((city: any) => ({
                ...city,
                icon: {
                    ...city.icon,
                    alt: `coworking space in ${city.name}`,
                },
            }));
        }),
        shareReplay(1)
    );

    private popularCities$ = this.availableCities$.pipe(
        map(data => {
            return data?.filter((city: any) => city?.isPopular);
        })
    );

    private otherCities$ = this.availableCities$.pipe(
        map(data => {
            return data?.filter((city: any) => !city?.isPopular);
        })
    );

    private promotionText$ = this.getAvailableCitiesResponse$.pipe(
        map(data => {
            return data?.promotion;
        })
    );

    private selectedCity$ = this.selectCityService.getSelectedCitySync().pipe(
        map(({ city }) => city),
        shareReplay(1)
    );

    viewModel$ = combineLatest({
        selectedCity: this.selectedCity$,
        popularCities: this.popularCities$,
        otherCities: this.otherCities$,
        promotionText: this.promotionText$,
    });

    onSelectCity(city: ICity) {
        this.selectCityService.setSelectedCity(city, cityChangeSource.USER_ACTION);
        this.toggleSelectCityModal(false);
    }

    toggleSelectCityModal(open: boolean) {
        this.open$.next(open);
    }
}
