<div (clickOutside)="toggleOptions(false)" appClickOutside>
    <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
    <div class="d-flx gap-4 menu-cont padding-horizontal-16 a-items-c c-pointer" (click)="toggleOptions(true)">
        @if (menu.imageUrl) {
            <div class="bg-color-blue padding-4 d-flx a-items-c j-cont-cen border-radius-2">
                <div class="menu-icon-cont d-flx a-items-c j-cont-cen">
                    <img [src]="menu.imageUrl" alt="i" class="image-contain" />
                </div>
            </div>
        }
        <div class="title-desc-cont d-flx a-items-c">
            <div>
                <div class="font-12 font-bold">{{ menu.title }}</div>
                @if (menu.comingSoon) {
                    <div class="font-12 text-color-light-grey margin-left-2">
                        <i>- Coming soon</i>
                    </div>
                }
            </div>
            <div class="icon-cont d-flx a-items-c">
                <span class="d-flx caret-down"></span>
            </div>
        </div>

        @if (showOptions) {
            <div class="dropdown-cont d-flx gap-24 bg-color-white padding-24 border-radius-4">
                <div class="sub-menus d-flx">
                    @for (subMenu of menu.subMenus; track subMenu) {
                        <div class="sub-menu d-flx flx-d-clm gap-12 margin-right-24">
                            <p class="font-16 font-bold padding-horizontal-12">{{ subMenu.title }}</p>
                            <div class="d-flx flx-d-clm">
                                @for (menuItem of subMenu.menuItems; track menuItem) {
                                    @if (menuItem.type !== DropdownMenuItemType.ACCORDION) {
                                        <ng-container
                                            [ngTemplateOutlet]="menuItemTemplate"
                                            [ngTemplateOutletContext]="{ menuItem }"
                                        />
                                    } @else {
                                        <app-accordion [config]="menuItem.config"></app-accordion>
                                    }
                                }
                            </div>
                        </div>
                    }
                </div>
                @if (menu.calloutType) {
                    @switch (menu.calloutType) {
                        @case (menuCalloutType.DOWNLOAD_APP) {
                            <ng-container
                                [ngTemplateOutlet]="calloutTemplate"
                                [ngTemplateOutletContext]="{ calloutData: calloutData[menuCalloutType.DOWNLOAD_APP] }"
                            ></ng-container>
                        }
                        @case (menuCalloutType.COMMERCIAL_LEASING) {
                            <ng-container
                                [ngTemplateOutlet]="calloutTemplate"
                                [ngTemplateOutletContext]="{
                                    calloutData: calloutData[menuCalloutType.COMMERCIAL_LEASING],
                                }"
                            ></ng-container>
                        }
                    }
                }
            </div>
        }
    </div>
</div>

<ng-template #menuItemTemplate let-menuItem="menuItem">
    @if (!menuItem.isExternalUrl) {
        <a [routerLink]="menuItem.redirectUrl | async" routerLinkActive="selected" #rla="routerLinkActive">
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ menuItem }" />
        </a>
    }
    @if (menuItem.isExternalUrl) {
        <a [href]="menuItem.redirectUrl | async" target="_blank">
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ menuItem }" />
        </a>
    }
</ng-template>

<ng-template #menuItemInnerTemplate let-menuItem="menuItem">
    <div
        class="menu-item padding-left-12 padding-vertical-12 padding-right-10 border-radius-4 d-flx gap-8 c-pointer"
        [class.a-items-c]="menuItem?.showTooltipInsteadOfDescription"
        [title]="menuItem?.showTooltipInsteadOfDescription ? menuItem.description : ''"
    >
        @if (menuItem?.imageUrl) {
            <div class="menu-item__icon-cont padding-4 bg-color-blue border-radius-2">
                <div class="menu-item__icon-cont__inner position-relative">
                    <app-optimized-image
                        cssClass="image-contain svg-cont d-flx"
                        fill
                        [image]="{ url: menuItem?.imageUrl, alt: menuItem?.title }"
                    ></app-optimized-image>
                </div>
            </div>
        }
        <div class="d-flx flx-d-clm gap-4">
            <p class="font-14 font-bold">{{ menuItem.title }}</p>
            @if (menuItem.description && !menuItem?.showTooltipInsteadOfDescription) {
                <p class="font-12 text-color-medium-grey">
                    {{ menuItem.description }}
                </p>
            }
        </div>
    </div>
</ng-template>

<ng-template #calloutTemplate let-item="calloutData">
    <div class="callout-container d-flx flx-d-clm gap-16 bg-color-blue padding-16 border-radius-4">
        <div class="d-flx gap-8">
            <div class="callout-container__icon position-relative">
                <app-optimized-image
                    cssClass="image-contain svg-cont d-flx"
                    fill
                    [image]="item.icon"
                ></app-optimized-image>
            </div>
            <div class="d-flx flx-d-clm gap-4">
                <p class="font-16 font-bold">{{ item.title }}</p>
                <p class="font-12 text-color-medium-grey">{{ item.description }}</p>
            </div>
        </div>
        <app-button [field]="item.buttonConfig" (onClick)="handleCTAClick(item.type)"></app-button>
    </div>
</ng-template>

<ng-template #accordionMenuItemBodyTemplate let-data="item">
    <div class="margin-left-16 margin-top-12">
        <div class="padding-left-16 accordion-body-inner-container">
            @for (menuItem of data.menuItem.items; track menuItem) {
                <ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ menuItem }" />
            }
        </div>
    </div>
</ng-template>

<ng-template #accordionMenuItemHeaderTemplate let-data="item">
    <div class="padding-left-12 d-flx gap-8 c-pointer a-items-c">
        <div class="menu-item__icon-cont padding-4 bg-color-blue border-radius-2">
            <div class="menu-item__icon-cont__inner position-relative">
                <app-optimized-image
                    cssClass="image-contain svg-cont d-flx"
                    fill
                    [image]="{ url: data.menuItem?.imageUrl, alt: data.menuItem?.title }"
                />
            </div>
        </div>
        <div class="d-flx flx-d-clm gap-4">
            <p class="font-14 font-bold">{{ data.menuItem.title }}</p>
        </div>
    </div>
</ng-template>

@defer (when showOptions) {
    @if (clModalFormConfig?.showModal) {
        <app-commercial-leasing-form
            [config]="clModalFormConfig"
            (formModalState)="handleClRequestCallbackCTAClick($event)"
        ></app-commercial-leasing-form>
    }
}
