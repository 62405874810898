export enum menuEnum {
    HOME = 'HOME',
    SEARCH = 'SEARCH',
    HEADER_SEARCH = 'HEADER_SEARCH',
    FLEXI = 'FLEXI',
    DEDICATED = 'DEDICATED',
    MANAGED_OFFICE = 'MANAGED_OFFICE',
    COMMERCIAL_LEASING = 'COMMERCIAL_LEASING',
    CORPORATE_FLEXI = 'CORPORATE_FLEXI',
    VIRTUAL_OFFICE = 'VIRTUAL_OFFICE',
    MEETING_ROOM = 'MEETING_ROOM',
    PRODUCTS = 'PRODUCTS',
    LOCATIONS = 'LOCATIONS',
    MORE = 'MORE',
    LIST_SPACE = 'LIST_SPACE',
    CONTACT_US = 'CONTACT_US',
    OFFICE_SPACE = 'OFFICE_SPACE',
    FREE_TRIAL = 'FREE_TRIAL',
    LOGIN = 'LOGIN',
    SIGNUP = 'SIGNUP',
    REQUEST_CALLBACK = 'REQUEST_CALLBACK',
    BLOG = 'BLOG',
    INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
    ALL_SOLUTIONS = 'ALL_SOLUTIONS',
    MY_SHORTLIST = 'MY_SHORTLIST',
    PROFILE = 'PROFILE',
    LOGOUT = 'LOGOUT',
    LOGIN_SIGNUP = 'LOGIN_SIGNUP',
    LOGIN_SIGNUP_FLEXI = 'LOGIN_SIGNUP_FLEXI',
    LOGOUT_BTN = 'LOGOUT_BTN',
    LOGOUT_BTN_FLEXI = 'LOGOUT_BTN_FLEXI',
    VO_SUBSCRIPTIONS = 'VO_SUBSCRIPTIONS',
    VO_HELP_SUPPORT = 'VO_HELP_SUPPORT',
    TESTIMONIALS = 'TESTIMONIALS',
    COMPANY_NAME_SEARCH = 'COMPANY_NAME_SEARCH',
    COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
    GST_REGISTRATION = 'GST_REGISTRATION',
    VPOB = 'VPOB',
    DOWNLOAD_APP = 'DOWNLOAD_APP',
    CASE_STUDIES = 'CASE_STUDIES',
    GUIDES_STUDIES = 'GUIDES_STUDIES',
    SUCCESS_STORIES = 'SUCCESS_STORIES',
    OD = 'OD',
    RENT = 'RENT',
    MY_SUBSCRIPTION = 'MY_SUBSCRIPTION',
    GUIDE_FOR_OFFICE_SPACE = 'GUIDE_FOR_OFFICE_SPACE',
    OFFICE_SPACE_CALCULATOR = 'OFFICE_SPACE_CALCULATOR',
}

export enum menuType {
    LINK = 'LINK',
    ACCORDION = 'ACCORDION',
    LINK_BTN = 'LINK_BTN',
    CLICKABLE_LINK_BTN = 'CLICKABLE_LINK_BTN',
    DROPDOWN = 'DROPDOWN',
    LOCATION_DROPDOWN = 'LOCATION_DROPDOWN',
    LOGIN_SIGNUP = 'LOGIN_SIGNUP',
    LOGOUT_BTN = 'LOGOUT_BTN',
    LOGOUT_BTN_FLEXI = 'LOGOUT_BTN_FLEXI',
    LOGIN_SIGNUP_FLEXI = 'LOGIN_SIGNUP_FLEXI',
    EXPANDABLE_LINKS_PANEL = 'EXPANDABLE_LINKS_PANEL',
}

export enum LocationWorkspaceSearchSections {
    NEAR_ME = 'NEAR_ME',
    RECENTLY_SEARCHED = 'RECENTLY_SEARCHED',
    POPULAR_LOCATIONS = 'POPULAR_LOCATIONS',
    POPULAR_WORKSPACES = 'POPULAR_WORKSPACES',
    REDIRECTION_CTA = 'REDIRECTION_CTA',
}

export enum menuCalloutType {
    DOWNLOAD_APP = 'DOWNLOAD_APP',
    COMMERCIAL_LEASING = 'COMMERCIAL_LEASING',
}
