import { NgModule } from '@angular/core';
import { AppModalComponent } from './app-modal.component';
import { CommonModule } from '@angular/common';
import { ModalModule as BootstrapModalsModule } from 'ngx-bootstrap/modal';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
    declarations: [AppModalComponent],
    imports: [CommonModule, BootstrapModalsModule.forRoot(), DirectivesModule],
    exports: [AppModalComponent],
})
export class AppModalModule {}
