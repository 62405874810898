import {
    IAmenitiesDetail,
    ICommonAmenities,
    IGroupedWorkHour,
    IListingWorkspaceCTAConfig,
    IParsedWorkspaceConnectivityDetails,
    IWorkHour,
    IWorkHours,
    IWorkspaceContentTag,
    IWorkspaceDetailsNew,
    IWorkspaceDirections,
    IWorkspaceImageGalleryConfig,
    IWorkspaceListAPIPayload,
    IWorkspaceLoc,
    IWorkspaceSocialTag,
    IWorkspaceTag,
    IWorkspaceUnavailableTimeSlot,
} from '../../shared/interfaces/workspace.interface';
import { dateSubStatus, FlexiMarketingTags, formPosition, sizes, WorkspaceStatus } from '../../shared/enums/enums';
import {
    MeetingRoomBookingStatus,
    MeetingRoomEquipmentTagSize,
    MeetingRoomEquipmentVariant,
    MeetingRoomInventoryState,
    MeetingRoomInventorySubState,
    MeetingRoomReservationErrorSubTypes,
    MeetingRoomReservationErrorTypes,
    MeetingRoomSlotStatus,
    MeetingRoomStatus,
} from './meeting-rooms.enums';
import {
    ICancellationPolicyData,
    ICancellationPolicyDataConfig,
    IImage,
    IListingPanel,
    IPaginationParam,
    ISeoData,
} from '../../shared/interfaces/common.interface';
import { ILocality } from '../../shared/interfaces/location.interface';
import {
    IMoreAboutWorkspaceConfig,
    INearbySpaceCardsConfig,
    IOverviewConfig,
    IStickyFooterButton,
    IStickyWorkspaceDetails,
} from '../../shared/interfaces/space-details.interface';
import { IBreadcrumbs } from '../../shared/interfaces/breadcrumbs.interface';
import { ISpaceBriefDetails } from '../../shared/component-modules/space-details/space-brief-details/space-brief-details.component';
import { IWorkspacePlanInventoryCardConfig } from '../../shared/standalone-components/workspace-plan-inventory-card/workspace-plan-inventory-card.interface';
import { IUserReviewSummary } from '../user-reviews/user-reviews.interface';
import { IBrandWorkspace } from '../brand-listing/brand-listing.interface';
import { ElementRef, TemplateRef } from '@angular/core';
import { ICoupon, IPaymentOptionConfig } from '../../shared/component-modules/payments/payments.interface';

export type IMRInventoryPricing = {
    pricePerHour: number;
    rackPricePerHour?: number;
    offerPricePerHour?: number;
    offerMessage?: string;
};

// Core Types
export interface IMeetingRoomWorkspaceCardData {
    _id: string;
    name: string;
    buildingName: string;
    slug: string;
    location: string;
    city: string;
    region: string;
    spaceType: string;
    directions: IWorkspaceDirections;
    distance?: number;
    cta: IListingWorkspaceCTAConfig;
    meetingroomworkspace: IMeetingRoomWorkspace;
    meetingroominventories: IMeetingRoomInventory[];
    meetingroominventorygroup: {
        capacity: number;
        bookingType: MrBookingType;
        nextAvailableDate?: string;
        pricing?: IMRInventoryPricing;
    };
    reviewSummary?: IUserReviewSummary;
}

export interface IMeetingRoomMapWorkspaceCardData extends IMeetingRoomWorkspaceCardData {
    loc: IWorkspaceLoc;
}

export interface IMeetingRoomWorkspace {
    popularityScore: number;
    marketingTags: FlexiMarketingTags[];
    tags?: {
        offer?: IWorkspaceContentTag[];
        social?: IWorkspaceSocialTag[];
        product?: IWorkspaceTag[];
    };
    timings: string;
    unavailable?: Record<string, string>;
    unavailableReason?: string;
    unavailableTimeSlot?: IWorkspaceUnavailableTimeSlot[];
    reviewSummary?: IUserReviewSummary;
}

export interface IMeetingRoomUnavailableSlot {
    startTimeInMinutes: number;
    endTimeInMinutes: number;
    status: string;
}

export interface IMeetingRoomInventory {
    _id: string;
    capacity: string;
    pricePerHour: number;
    rackPricePerHour?: number;
    usecases?: string[];
    images: IImage[];
    equipments: IMeetingRoomEquipment[];
    dateDurationTimeStatus?: { status: dateSubStatus };
    roomName?: string;
    floorNumber?: string;
    state: MeetingRoomInventoryState;
    subState: MeetingRoomInventorySubState;
    spaceImages?: IImage[];
    availability?: {
        status: MeetingRoomStatus;
        unavailableSlots: IMeetingRoomUnavailableSlot[];
    };
}

export interface IMeetingRoomEquipment {
    id: string;
    title: string;
    icon: IImage;
}

export interface IMeetingRoomSpaceDetailWorkspace {
    _id: string;
    status: WorkspaceStatus;
    amenities?: ICommonAmenities;
    workHours: Record<string, IWorkHour>;
    about: string;
    timings: string;
    marketingTags: FlexiMarketingTags[];
    blockReserve: boolean;
    tags?: {
        offer?: IWorkspaceContentTag[];
        social?: IWorkspaceSocialTag[];
        product?: IWorkspaceTag[];
    };
}

export interface IMeetingRoomsWorkspaceSpaceDetail extends IWorkspaceDetailsNew {
    meetingroomworkspace: IMeetingRoomSpaceDetailWorkspace;
    meetingroominventories: IMeetingRoomInventory[];
    meetingroominventorygroup: {
        capacity: number;
        bookingType: MrBookingType;
        pricing?: IMRInventoryPricing;
    };
}

export interface IMeetingRoomBookingWorkspaceData extends IWorkspaceDetailsNew {
    meetingroomworkspace: {
        tags?: {
            social?: IWorkspaceSocialTag[];
        };
        groupedWorkHours: IGroupedWorkHour[];
        workHours: IWorkHours;
    };
    meetingroominventories: IMeetingRoomInventory[];
}

export interface IParsedMeetingRoomsWorkspaceDetail extends IMeetingRoomsWorkspaceSpaceDetail {
    amenitiesDetailConfig: IAmenitiesDetail;
    moreAboutWorkspaceConfig?: IMoreAboutWorkspaceConfig;
    nearbySpaceCardConfig: INearbySpaceCardsConfig;
    imageGalleryConfig?: IWorkspaceImageGalleryConfig;
    overviewConfig?: IOverviewConfig;
    isRecommended?: boolean;
    breadcrumbsConfig: IBreadcrumbs[];
    briefDetailsConfig: IStickyWorkspaceDetails;
    stickyFooterButtonConfig: IStickyFooterButton;
    meetingRoomPlans?: (IWorkspacePlanInventoryCardConfig & {
        state: MeetingRoomInventoryState;
        subState: MeetingRoomInventorySubState;
    })[];
    reviewSummary?: IUserReviewSummary;
    workspaceBrand?: IBrandWorkspace;
}

export interface IMeetingRoomSlotSegment {
    startTime: number;
    endTime: number;
    status: MeetingRoomSlotStatus;
    hoverState?: 'hover' | '';
}

export interface IMeetingRoomSlot {
    startTimeLabel: string;
    endTimeLabel: string;
    startTime: number;
    endTime: number;
    segments: [IMeetingRoomSlotSegment, IMeetingRoomSlotSegment];
}

export interface IMeetingRoomReservationConfig {
    bookable: boolean;
    error?: {
        type: MeetingRoomReservationErrorTypes;
        subType: MeetingRoomReservationErrorSubTypes;
    }; // ONLY FOR TEAM POLICY OR BLOCK_RESERVE ETC. NOT going to be SOLD_OUT.
    minDuration?: number; // duration in minutes
}

// APIs
export interface IMeetingRoomListingAPIResponse {
    workspaces: IMeetingRoomWorkspaceCardData[];
    nearbyWorkspaces: IMeetingRoomWorkspaceCardData[];
    unavailableWorkspaces: IMeetingRoomWorkspaceCardData[];
    paginationParam: IPaginationParam;
    locations: ILocality[];
    seoData: ISeoData;
    panels: IListingPanel[];
}

export interface IMRInventoryGroup {
    capacity: number;
    bookingType: MrBookingType;
}

export interface IMeetingRoomMapListingAPIResponse {
    workspaces: IMeetingRoomMapWorkspaceCardData[];
    nearbyWorkspaces: IMeetingRoomMapWorkspaceCardData[];
    unavailableWorkspaces: IMeetingRoomWorkspaceCardData[];
    locations: ILocality[];
    cityBoundary?: google.maps.LatLngLiteral[];
}

export interface IMeetingRoomCapacityAPIResponse {
    workspace: IMeetingRoomWorkspaceCardData & {
        meetingroominventorygroups: IMRInventoryGroup[];
    };
}

export type IMeetingRoomListingAPIPayload = IWorkspaceListAPIPayload;

export type IMeetingRoomMapListingAPIPayload = Omit<IWorkspaceListAPIPayload, 'pageNo' | 'pageLimit'>;

export interface IMeetingRoomWorkspaceSpaceDetailAPIResponse {
    workspace: IMeetingRoomsWorkspaceSpaceDetail;
    seoData: ISeoData;
}

export interface IMeetingRoomNearbySpacesAPIResponse {
    otherCapacities: IMeetingRoomSpaceSectionResponse;
    nearbySpaces: IMeetingRoomSpaceSectionResponse;
}

export interface IMeetingRoomSpaceSectionResponse {
    label: string;
    spaces: IMeetingRoomWorkspaceCardData[];
}

export interface IMeetingRoomLeadGenerationPayload {
    name: string;
    phone: string;
    email: string;
    date: string;
    citySlug: string;
    workspaceSlug?: string;
    localitySlugs?: string[];
}

export interface IMeetingRoomBookingDataAPIPayload {
    workspaceSlug: string;
    capacity: number;
    selectedDate: string;
}

export interface IDateLevelStatus {
    date: string;
    isAvailable: boolean;
}

export interface IMeetingRoomBookingDataResponse {
    reservationConfig: IMeetingRoomReservationConfig;
    workspace: IMeetingRoomBookingWorkspaceData;
    cancellationPolicy: ICancellationPolicyDataConfig;
    dateLevelStatuses: IDateLevelStatus[];
}

// Components Config
export interface IMeetingRoomEquipmentTagConfig {
    label: string;
    icon: IImage;
    showTooltip?: boolean;
    size?: MeetingRoomEquipmentTagSize;
    variant?: MeetingRoomEquipmentVariant;
}

export interface IMeetingRoomCardConfig {
    workspace: IMeetingRoomWorkspaceCardData;
    isATFContentCard: boolean;
    size?: typeof sizes.LARGE | typeof sizes.DEFAULT | typeof sizes.SMALL;
    isUnavailable?: boolean;
    selectedDate?: string;
    disableImageCarouselSwipe?: boolean;
}

export interface IParsedMeetingRoomWorkspaceCardData extends IMeetingRoomWorkspaceCardData {
    displayName: string;
    seaterLabel: string;
    address: string;
    distanceLabel: string;
    connectivityDetails: IParsedWorkspaceConnectivityDetails;
}

export interface IParsedMeetingRoomMapWorkspaceCardData extends IMeetingRoomMapWorkspaceCardData {
    displayName: string;
    seaterLabel: string;
    credits: number;
    address: string;
    image: IImage;
}

export interface IMeetingRoomCapacityModalConfig {
    workspaceSlug: string;
}

export interface IParsedMeetingRoomWorkspaceCapacityModalData extends IMeetingRoomWorkspaceCardData {
    displayName: string;
    address: string;
    seatingOptions: IParsedMeetingRoomSeatingOption[];
}

export const MR_BOOKING_TYPES = {
    ONLINE_INSTANT: 'ONLINE_INSTANT',
    OFFLINE: 'OFFLINE',
} as const;

export type MrBookingType = keyof typeof MR_BOOKING_TYPES;

export interface IParsedMeetingRoomSeatingOption {
    label: string;
    value: string;
    bookingType: MrBookingType;
}

export interface IMeetingRoomCapacityModalViewStateSubPayload {
    show: boolean;
    workspaceSlug?: string;
}

export interface IMeetingRoomsSpaceBriefDetailsConfig extends ISpaceBriefDetails {
    productTags?: IWorkspaceTag[];
    seaterLabel: string;
    address: string;
    displayName: string;
    reviewSummary?: IUserReviewSummary;
    bookingType: MrBookingType;
}

export interface IMeetingRoomsContactExpertDetailsCardConfig {
    workspace: IParsedMeetingRoomsWorkspaceDetail;
    selectedDate?: string;
    formSource: formPosition;
}

export interface IMeetingRoomsBookingLeadGenFormConfig {
    heading?: string;
    description?: string;
    workspaceSlug?: string;
    localitySlugs?: string[];
    hideSelectDate?: boolean;
    editData?: Record<string, string>;
}

export interface IMeetingRoomSlotCardConfig {
    meetingRoomInventory: IMeetingRoomInventory;
    isSelected?: boolean;
    workHour?: IWorkHour;
    reservationConfig: IMeetingRoomReservationConfig;
    selectedWorkHour: IWorkHour;
}

export interface IMeetingRoomSlotSummaryConfig {
    capacity: number;
    displayName: string;
    spaceImage?: IImage;
    pricePerHour: number;
    rackPricePerHour: number;
    workHours: IWorkHours;
}

export interface IMeetingRoomCancellationPolicyConfig {
    content: ICancellationPolicyData;
    workspaceId: string;
}

export interface IMeetingRoomBookingSummaryConfig {
    pricePerHour: number;
    duration: number;
    capacity: number;
    workspace: IMeetingRoomBookingWorkspaceData;
    bookingMetaData: {
        dateLabel: string;
        timeLabel: string;
    };
    selectedRoom: IMeetingRoomInventory;
    selectedDate: string;
    hideCreditsOnMob?: boolean;
    hideInfoBar?: boolean;
    buttonText?: string;
    isDisabled?: boolean;
    infoBarTemplate?: TemplateRef<ElementRef>;
}

export interface IMeetingRoomInitiateBookingAPIPayload {
    details: {
        inventoryId: string;
        date: string;
        slot: {
            startTimeInMinutes: number;
            endTimeInMinutes: number;
        };
    };
}

export interface IMeetingRoomReservationData {
    error?: {
        type: MeetingRoomReservationErrorTypes;
        subType: MeetingRoomReservationErrorSubTypes;
        message?: string;
    };
    reservation: {
        _id: string;
        bookingStatus: MeetingRoomBookingStatus;
        bookingStep: string;
        meetingroominventory: IMeetingRoomInventory;
        reservationDate: string;
        sequenceNum: string;
        slot: {
            startTimeInMinutes: number;
            endTimeInMinutes: number;
        };
        workspace: IMeetingRoomBookingWorkspaceData;
    };
    pricing: {
        netAmount: number;
        sellingAmount: number;
        pricePerHour: number;
        hours: number;
        discount: number;
        rackPricePerHour?: number;
        listingAmount?: number;
        appliedCoupon?: ICoupon;
    };
}

export interface IMeetingRoomPaymentOptionsData {
    paymentConfigs: IPaymentOptionConfig;
    metaData: {
        creditsRequiredForBooking: number;
    };
}

export interface IMeetingRoomReservationShareDetailsData {
    shareInfo: {
        data: {
            message: string;
        };
    };
}

export type IMeetingRoomCheckoutBreadcrumbsPayload =
    | {
          reservationId: string;
          rechargeCheckoutId?: string;
      }
    | {
          workspaceSlug: string;
          capacity: string;
          rechargeCheckoutId?: string;
      };
