import { ElementRef } from '@angular/core';
import { fontSizes, formPosition } from '../../../enums/enums';
import { VirtualOfficeFormSuccessType, VirtualOfficeFormType } from '../enums/enum';
import { IVOWorkspace } from '../../virtual-office-listing-card/virtual-office-listing-card.interface';
import { IButtonConfig } from '../../../interfaces/button.interface';
import { ICoupon } from '../../../component-modules/payments/payments.interface';

interface ICityDetail {
    _id: string;
    name: string;
    slug: string;
}

export interface IVOFormData {
    id?: string;
    heading?: string;
    description?: string;
    cityDetail?: ICityDetail;
    headingFontSize?: fontSizes;
    ctaText?: string;
    ctaIcon?: string;
    ctaLink?: string;
    voWorkspace?: IVOWorkspace;
    editData?: Record<string, string>;
}

export enum VOPageSource {
    PRODUCT_DETAIL = 'PRODUCT_DETAIL',
    LANDING_PAGE = 'LANDING_PAGE',
    LISTING_PAGE = 'LISTING_PAGE',
    SPACE_DETAIL = 'SPACE_DETAIL',
    COMPARE_WORKSPACES = 'COMPARE_WORKSPACES',
    COMPANY_NAME_SEARCH = 'COMPANY_NAME_SEARCH',
    COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
    CHANGE_COMPANY_ADDRESS = 'CHANGE_COMPANY_ADDRESS',
    CHANGE_GST_ADDRESS = 'CHANGE_GST_ADDRESS',
    GST_FOR_FREELANCERS = 'GST_FOR_FREELANCERS',
    RERA_REGISTRATION = 'RERA_REGISTRATION',
    MSME_REGISTRATION = 'MSME_REGISTRATION',
    GST_REGISTRATION = 'GST_REGISTRATION',
    VPOB = 'VPOB',
    NGO = 'NGO',
}
export interface IVOFormConfig {
    hideFeaturesList?: boolean;
    isHero?: boolean;
    showCityDropdown?: boolean;
    showFloatingButton?: boolean;
    showStickyButton?: boolean;
    formType: VirtualOfficeFormType;
    stickyFormConfig?: {
        showAfterTopRef?: ElementRef;
        topSpacing?: number;
        hideAfterBottomRef?: ElementRef;
        bottomSpacing?: number;
    };
    successFormConfig?: {
        type?: VirtualOfficeFormSuccessType;
        coupons?: ICoupon[];
        heading?: string;
        description?: string;
        hideCTA?: boolean;
        ctaLabel?: string;
        showContactDetails?: boolean;
        ctaLink?: string;
        closeModal?: boolean;
    };
    formSource: formPosition;
    pageSource: VOPageSource;
    formData?: IVOFormData;
    showMobileForm?: boolean;
    buttonConfig?: IButtonConfig;
}
