<accordion [isAnimated]="config?.isAnimated" [closeOthers]="config?.closeOthers" class="accordion-container">
    @for (accordion of config?.accordionData; track accordion; let i = $index) {
        <accordion-group
            [ngClass]="
                config?.type === AccordionType.PRIMARY ? 'accordion-primary-group-cont' : 'accordion-group-container'
            "
            (isOpenChange)="onExpandCollapse(i)"
            [isOpen]="i === 0 && config?.isFirstOpen"
            [isDisabled]="isSelected(i) && config?.oneShowRemainOpen"
        >
            <div accordion-heading class="d-flx a-items-c j-cont-space-bet accordion-heading-container">
                @if (accordion?.headerTemplateData) {
                    <ng-container
                        [ngTemplateOutlet]="accordion?.headerTemplate"
                        [ngTemplateOutletContext]="{ item: accordion?.headerTemplateData }"
                    ></ng-container>
                } @else {
                    <div class="d-flx gap-8 a-items-c flx-1">
                        @if (accordion?.icon) {
                            <div class="title-icon-container d-flx">
                                <img
                                    appImageShimmerLazyLoad
                                    [imageSrc]="accordion?.icon?.url"
                                    [alt]="accordion?.icon?.alt"
                                />
                            </div>
                        }
                        <div class="title-container" [ngClass]="config?.titleClass">
                            {{ accordion.title }}
                        </div>
                    </div>
                }
                <div class="arrow-container d-flx a-items-c j-cont-cen">
                    @if (isSelected(i)) {
                        <img appImageShimmerLazyLoad [imageSrc]="CommonIconsUrl.ARROW_UP_DARK" alt="i" />
                    }
                    @if (!isSelected(i)) {
                        <img appImageShimmerLazyLoad [imageSrc]="CommonIconsUrl.ARROW_DOWN_DARK" alt="i" />
                    }
                </div>
            </div>
            @if (accordion.template) {
                <ng-container
                    [ngTemplateOutlet]="accordion.template"
                    [ngTemplateOutletContext]="{ item: accordion.templateData }"
                ></ng-container>
            }
            @if (config.insertAsHTML && accordion.description) {
                <div class="desc injected-content" [innerHTML]="accordion.description"></div>
            }
            @if (!config.insertAsHTML && accordion.description) {
                <div class="desc">{{ accordion.description }}</div>
            }
        </accordion-group>
    }
</accordion>
