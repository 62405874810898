import { ElementRef } from '@angular/core';
import { IFormsConfig as IFormFieldsConfig } from '../../../ui-components/forms/forms.component';
import { IDedicatedBaseFormConfig } from '../dedicated-form/dedicated-form.interface';

export enum EnterprisesFormType {
    STICKY_BUTTON = 'STICKY_BUTTON',
    FLOATING_BUTTON = 'FLOATING_BUTTON',
    MODAL = 'MODAL',
    STICKY_FORM = 'STICKY_FORM',
    DEFAULT = 'DEFAULT',
    HERO_FORM = 'HERO_FORM',
}
export interface IEnterprisesFormConfig {
    mwebClose: IDedicatedBaseFormConfig;
    mwebOpen: IDedicatedBaseFormConfig;
    desk: IDedicatedBaseFormConfig;
    defaultCampaign?: string;
    city?: {
        slug?: string;
        name?: string;
    };
    brand?: {
        name: string;
    };
    formType: EnterprisesFormType;
    stickyFormConfig?: {
        showAfterTopRef: ElementRef;
        topSpacing: number;
        hideAfterBottomRef: ElementRef;
        bottomSpacing: number;
    };
    secondaryFormConfig?: {
        heading: string;
        description: string;
    };
    submitButtonLabel?: string;
    showSuccessWithCTA?: boolean;
    formSource: string;
    formFieldsConfig?: IFormFieldsConfig;
    skipFormStep2?: boolean;
    showFormOnMobile?: boolean;
    successRedirectUrl?: string;
    showCityDropdownField?: boolean;
}
