import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ContactDetails } from '../../../core/constants/constants';
import { formSuccessActionType } from '../../enums/enums';

export interface ILeadSuccessConfig {
    heading: string;
    description: string;
    showContactDetails: boolean;
}

@Component({
    selector: 'app-lead-success',
    templateUrl: './lead-success.component.html',
    styleUrls: ['./lead-success.component.scss'],
})
export class LeadSuccessComponent implements OnInit {
    @Input() config: ILeadSuccessConfig;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() template?: TemplateRef<any>;
    @Output() action = new EventEmitter();
    CommonIconsUrl = CommonIconsUrl;
    ContactDetails = ContactDetails;
    formSuccessActionType = formSuccessActionType;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    successAction(actionType: string) {
        this.action.emit(actionType);
    }
}
