import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { IImage } from '../../interfaces/common.interface';
import { AccordionType } from './accordion.enum';

export interface IAccordionConfig {
    isAnimated: boolean;
    closeOthers: boolean;
    isFirstOpen: boolean;
    oneShowRemainOpen?: boolean;
    accordionData: {
        title: string;
        description?: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        template?: TemplateRef<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        templateData?: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        headerTemplate?: TemplateRef<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        headerTemplateData?: any;
        icon?: IImage;
    }[];
    insertAsHTML?: boolean;
    titleClass?: string;
    type?: AccordionType;
}

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
    CommonIconsUrl = CommonIconsUrl;
    AccordionType = AccordionType;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @Input() config: IAccordionConfig;
    selectedAccordionIndexes = [];

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    onExpandCollapse(index: number) {
        if (this.selectedAccordionIndexes.includes(index)) {
            this.selectedAccordionIndexes = this.selectedAccordionIndexes.filter(ind => ind !== index);
        } else {
            this.selectedAccordionIndexes = [...this.selectedAccordionIndexes, index];
        }
    }

    isSelected(index: number) {
        return this.selectedAccordionIndexes.includes(index);
    }
}
