import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { IFieldConfig } from '../../interfaces/field.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IButtonConfig } from '../../interfaces/button.interface';
import { FormStatus } from './forms.enum';
import { UtilsService } from '../../../core/services/utils/utils.service';

export enum IFormsType {
    INLINE_FORM = 'INLINE_FORM',
    DEFAULT = 'DEFAULT',
}

export interface IFormsConfig {
    formType?: IFormsType;
    fields: IFieldConfig[];
    ctas: IButtonConfig[];
    ctaContainerClass?: string;
    optionalFields?: {
        show: boolean;
        headingOpen: string;
        fields: IFieldConfig[];
        hideOptionalFieldsCTAConfig?: {
            show: boolean;
            label: string;
        };
        showOptionalFieldsCTAConfig: {
            show: boolean;
            label: string;
        };
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editData?: any;
}

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
    protected readonly Array = Array;
    deviceInfo = this.utilsService.getDeviceInfo();
    config: IFormsConfig;
    ctaContainerClass = 'margin-top-auto';

    @Input('config') set updateConfig(data: IFormsConfig) {
        if (data) {
            this.config = data;
            // TODO: NEEDS PREDICTABILITY OF EXECUTION BETWEEN NGONINIT AND @INPUT
            if (this.config?.editData) {
                this.populateEditData(this.config?.editData);
            }

            this.ctaContainerClass = data.ctaContainerClass;
        }
    }
    @Input() className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() mobCtaTemplate?: TemplateRef<any>;
    // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onFormDataChange: EventEmitter<any> = new EventEmitter<any>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClickExpandOptionalFormCTA: EventEmitter<void> = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClickHideOptionalFormCTA: EventEmitter<void> = new EventEmitter<void>();
    form: UntypedFormGroup;
    IFormsType = IFormsType;

    cssConfig = {
        [IFormsType.INLINE_FORM]: 'inline-form-container a-items-c',
        [IFormsType.DEFAULT]: 'flx-d-clm',
    };
    constructor(
        private formBuilder: UntypedFormBuilder,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.form = this.createControl();
        if (!this.config?.formType) {
            this.config.formType = IFormsType.DEFAULT;
        }
        if (this.config?.editData) {
            this.populateEditData(this.config?.editData);
        }
        // TODO: CREATE FORM CONTROL GROUP FROM PARENT USING A SERVICE AND PASS IT AS INPUT @ANIKET AND @MUKUL
        this.form.valueChanges.subscribe(data => {
            this.onFormDataChange.emit(data);
        });
    }

    // TODO fix this - currently it seems to built where you can pass config for multiple cta but all of them would do the same thing only - trigger submit method
    onSubmit(event) {
        event?.stopPropagation();
        if (this.form.status === FormStatus.INVALID || this.config.ctas?.[0]?.disabled) {
            return;
        }
        this.submit.emit({ formData: this.getFormData(this.form) });
    }

    createControl() {
        const group = this.formBuilder.group({});
        this.config?.fields.forEach(fieldGroup => {
            if (Array.isArray(fieldGroup)) {
                fieldGroup.forEach(field => {
                    const control = this.formBuilder.control(
                        { value: field.value },
                        this.bindValidations(field.validations || [])
                    );
                    group.addControl(field.name, control);
                });
            } else {
                const control = this.formBuilder.control(
                    { value: fieldGroup.value },
                    this.bindValidations(fieldGroup.validations || [])
                );
                group.addControl(fieldGroup.name, control);
            }
        });
        return group;
    }

    appendOptionalFieldsControl() {
        const form = this.form;
        const group = this.formBuilder.group({});
        this.config?.optionalFields.fields.forEach(field => {
            const control = this.formBuilder.control(field.value, this.bindValidations(field.validations || []));
            group.addControl(field.name, control);
        });
        form.addControl('optionalFields', group);
        return form;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bindValidations(validations: any) {
        if (!validations.length) {
            return null;
        }

        const validatorList = [];
        validations.forEach(valid => {
            validatorList.push(valid.validator);
        });

        return Validators.compose(validatorList);
    }

    openOptionalFields() {
        // Append optional fields control to form if optional fields is enabled
        if (this.config.optionalFields) {
            this.form = this.appendOptionalFieldsControl();
        }
        this.onClickExpandOptionalFormCTA.emit();
    }

    hideOptionalFields() {
        this.form.removeControl('optionalFields');
        this.onClickHideOptionalFormCTA.emit();
    }

    getFormData(form: UntypedFormGroup) {
        if (this.config.optionalFields?.show) {
            return form.value;
        } else {
            const formData = {
                ...form.value,
            };
            // remove optional fields from the final payload if optional fields are removed from UI
            delete formData.optionalFields;
            return formData;
        }
    }

    populateEditData(editData) {
        const { optionalFields } = editData;
        setTimeout(() => {
            if (optionalFields) {
                this.openOptionalFields();
            }
            this.form.patchValue(editData);
        }, 200);
    }
}
