<app-modal [template]="selectCityModalTemplate" [open]="open$ | async" />

<ng-template #selectCityModalTemplate>
    <ng-container *ngTemplateOutlet="selectCity" />
</ng-template>

<ng-template #selectCity>
    <div class="city-container">
        <div class="margin-bottom-12 bg-color-white">
            <div class="app-container padding-vertical-12-desk padding-vertical-16 d-flx a-items-c">
                <div [innerHTML]="logo" class="logo svg-cont"></div>
                @if (closable) {
                    <span class="icon-cont c-pointer d-flx margin-left-auto" (click)="toggleSelectCityModal(false)">
                        <span class="close-icon"></span>
                    </span>
                }
            </div>
        </div>
        <div class="app-container">
            <div class="margin-bottom-48-desk margin-bottom-24">
                <div class="font-30-desk font-18 font-bold margin-bottom-8">
                    {{ heading$ | async }}
                </div>
                <div class="font-14-desk font-12">
                    {{ subheading$ | async }}
                </div>
            </div>

            <h4 role="label" class="margin-bottom-32-desk margin-bottom-16 font-24-desk font-16 font-bold">
                Select City
            </h4>
            @if (viewModel$ | async; as vm) {
                <div class="text-align-center">
                    @if (vm.popularCities?.length) {
                        <div class="d-flx flx-d-clm popular-city-container width-100" role="listbox">
                            <h5 class="section-header-text font-bold text-color-success-medium text-align-left">
                                POPULAR CITIES
                            </h5>
                            <div class="d-grid popular-city-list width-100" role="listbox">
                                @for (city of vm.popularCities | orderBy: 'rank'; track city) {
                                    <div
                                        class="popular-city c-pointer d-flx flx-d-clm a-items-c border-radius-2"
                                        (click)="onSelectCity(city)"
                                        role="option"
                                        [ngClass]="{ active: vm.selectedCity?.slug === city.slug }"
                                    >
                                        @if (city?.icon?.url) {
                                            <div class="image-cont d-flx a-items-c j-cont-cen">
                                                <img
                                                    class="height-100 width-100 object-fit-contain"
                                                    appImageShimmerLazyLoad
                                                    [isATFContent]="true"
                                                    [imageSrc]="city.icon.url"
                                                    [alt]="city.icon.alt"
                                                />
                                            </div>
                                        }
                                        <p
                                            class="font-14 font-normal text-ellipsis text-color-dark width-100 popular-city-name"
                                        >
                                            {{ city.name }}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div class="d-flx flx-d-clm other-city-container width-100">
                        @if (vm.popularCities?.length && vm.otherCities?.length) {
                            <h5 class="section-header-text font-bold text-color-success-medium text-align-left">
                                Other cities
                            </h5>
                        }
                        <div class="d-grid other-city-ctr width-100">
                            @for (city of vm.otherCities; track city) {
                                <div
                                    class="other-city c-pointer border-radius-2"
                                    (click)="onSelectCity(city)"
                                    [ngClass]="{ active: vm.selectedCity?.slug === city.slug }"
                                >
                                    <p class="font-14 font-normal text-color-dark other-city-name">{{ city.name }}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                @if (vm?.promotionText) {
                    <div class="margin-top-48-desk margin-top-24">
                        <ng-container
                            [ngTemplateOutlet]="promotionTemplate"
                            [ngTemplateOutletContext]="{ promotionText: vm.promotionText }"
                        />
                    </div>
                }
                <div class="margin-top-24">
                    <app-why-choose-myhq [config]="whyChooseMyhqContent$" />
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #promotionTemplate let-text="promotionText">
    <div
        class="bg-color-light-blue padding-horizontal-20 d-flx a-items-start a-items-c-desk gap-8 text-color-success-medium position-relative"
    >
        <div [style.min-width.px]="32" [style.height.px]="44">
            <div class="lottie-cont">
                <ng-lottie [options]="animationOptions" [styles]="{ width: '40px' }" />
            </div>
        </div>
        <div class="d-flx flx-d-clm-row a-items-c-desk gap-4 gap-20-desk padding-vertical-12">
            <div class="font-16 font-24-desk font-bolder">Limited Period Offer!</div>
            <div class="font-12 font-18-desk font-bold">{{ text }}</div>
        </div>
    </div>
</ng-template>
