<form [formGroup]="form" [ngClass]="className" (submit)="onSubmit($event)">
    <div class="d-flx height-100" [ngClass]="cssConfig[config.formType || IFormsType.DEFAULT]">
        @for (fieldGroup of config.fields; track fieldGroup) {
            <div class="form-item-container" [ngClass]="Array.isArray(fieldGroup) && 'd-flx flx-d-clm-row gap-16-desk'">
                @if (Array.isArray(fieldGroup)) {
                    @for (fields of fieldGroup; track fields) {
                        <div class="flx-1">
                            <ng-container
                                [ngTemplateOutlet]="formItemTemplate"
                                [ngTemplateOutletContext]="{ fieldGroup: fields }"
                            ></ng-container>
                        </div>
                    }
                } @else {
                    <ng-container
                        [ngTemplateOutlet]="formItemTemplate"
                        [ngTemplateOutletContext]="{ fieldGroup: fieldGroup }"
                    ></ng-container>
                }
            </div>
        }
        @if (config.optionalFields) {
            @if (!config.optionalFields.show) {
                <div class="heading-container">
                    @if (config.optionalFields?.showOptionalFieldsCTAConfig?.show) {
                        <div class="close-text c-pointer" (click)="openOptionalFields()">
                            {{ config.optionalFields.showOptionalFieldsCTAConfig.label }}
                        </div>
                    }
                </div>
            }
            @if (config.optionalFields.show) {
                <div>
                    <div class="d-flx j-cont-space-bet width-100">
                        <div class="heading-container">
                            <div class="open-text">{{ config.optionalFields.headingOpen }}</div>
                        </div>
                        @if (config.optionalFields?.hideOptionalFieldsCTAConfig?.show) {
                            <p (click)="hideOptionalFields()" class="hide-optional-fields-cta c-pointer">
                                {{ config.optionalFields.hideOptionalFieldsCTAConfig?.label }}
                            </p>
                        }
                    </div>
                </div>
            }
            @if (config.optionalFields.show) {
                <div formGroupName="optionalFields">
                    @for (field of config.optionalFields.fields; track field) {
                        <div>
                            <div formField [field]="field" [form]="form"></div>
                        </div>
                    }
                </div>
            }
        }
        <ng-content select="[postFormFields]"></ng-content>
        <ng-content></ng-content>
        @if (mobCtaTemplate && deviceInfo.isMobile) {
            <ng-container
                [ngTemplateOutlet]="mobCtaTemplate"
                [ngTemplateOutletContext]="{ buttonsTemplate }"
            ></ng-container>
        }
        @if (!mobCtaTemplate || !deviceInfo.isMobile) {
            <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
        }
    </div>
</form>

<ng-template #buttonsTemplate>
    @for (button of config.ctas; track button; let i = $index) {
        <div class="inline-form-button" [ngClass]="config.ctaContainerClass">
            @if (!button.hidden) {
                <div formButton [button]="button" [form]="form"></div>
            }
        </div>
    }
</ng-template>

<ng-template #formItemTemplate let-fieldGroup="fieldGroup">
    @if (fieldGroup?.title) {
        <p class="font-14 font-bold margin-bottom-8">{{ fieldGroup.title }}</p>
    }
    @if (fieldGroup?.length > 0) {
        <div class="d-flx fields-cont">
            @for (field of fieldGroup; track field) {
                <div class="field-cont">
                    <div formField [field]="field" [form]="form"></div>
                </div>
            }
        </div>
    }
    @if (!fieldGroup?.length > 0) {
        <div formField [field]="fieldGroup" [form]="form"></div>
    }
</ng-template>
