export enum WhyChooseMyHQCardType {
    DEFAULT = 'DEFAULT',
    GRID = 'GRID',
    ROW_COLUMN = 'ROW_COLUMN',
}

export enum WhyChooseMyHQCardItemType {
    COLUMN = 'COLUMN',
    DEFAULT = 'DEFAULT',
}
