import { catchError, map, tap } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../../core/services/error/error.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { Injectable } from '@angular/core';
import { ImageBaseUrl } from '../../../core/constants/constants';
import { VirtualOfficeService } from '../virtual-office.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { citySlug, products, productsMap } from '../../enums/enums';
import { GeolocationService, IGeoLocationDataResp } from '../../../core/services/geolocation/geolocation.service';
import {
    ICheckCityMetroConnectivityAPIResponse,
    ICity,
    ICoordinates,
    IGetLocationByCoordinatesResponse,
    IGetLocationByCoordinatesSubscriptionData,
} from '../../interfaces/location.interface';
import { IGetActiveStatesForProductRequestPayload, IGetActiveStatesForProductResponse } from './location.service.types';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private geolocationService: GeolocationService,
        private analyticsService: AnalyticsService,
        private virtualOfficeService: VirtualOfficeService
    ) {}

    ImageBaseUrl = ImageBaseUrl;
    availableCities;
    popularLocationData = {};
    productBackendKey;
    private locationCoordinates$: BehaviorSubject<IGetLocationByCoordinatesSubscriptionData> =
        new BehaviorSubject<IGetLocationByCoordinatesSubscriptionData>(null);

    getAllCities() {
        const url = `${environment.baseUrl}/locations/city/all`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(url).pipe(
            map(res => {
                return res.data || [];
            }),
            catchError(this.errorService.handleError)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPopularLocations(cityId, productBackendKey?: string): Observable<any[]> {
        return new Observable(observer => {
            this.geolocationService
                .getGeolocationIfAllowed()
                .subscribe(({ success, geolocation }: IGeoLocationDataResp) => {
                    if (!this.popularLocationData[cityId] || this.productBackendKey !== productBackendKey) {
                        const url = `${environment.baseUrl}/locations/city/${cityId}/popular-localities`;
                        const queryParams = new HttpParams()
                            .set('product', productBackendKey || '')
                            .set('latitude', success ? `${geolocation?.latitude}` : '')
                            .set('longitude', success ? `${geolocation?.longitude}` : '');
                        this.productBackendKey = productBackendKey;

                        this.http
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .get<any>(url, { params: queryParams })
                            .pipe(
                                map(res => {
                                    if (res?.success && res?.data.localities) {
                                        this.popularLocationData[cityId] = res.data.localities;
                                        return this.popularLocationData[cityId];
                                    } else {
                                        console.warn('something went wrong in popular location API', cityId);
                                        return [];
                                    }
                                }),
                                catchError(this.errorService.handleError)
                            )
                            .subscribe(result => observer.next(result));
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        new Observable<any[]>(subscriber => {
                            subscriber.next(this.popularLocationData[cityId]);
                        }).subscribe(result => observer.next(result));
                    }
                });
        });
    }

    // TODO add interface defintion here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAvailableCity(productBackendKey?: string): Observable<any> {
        if (this.availableCities) {
            return new Observable(observer => {
                observer.next(this.availableCities);
            });
        } else {
            let url = `${environment.baseUrl}/locations/city`;
            if (productBackendKey) {
                url = `${environment.baseUrl}/product/web/${productBackendKey}/active-city-list`;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.http.get<any>(url).pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: IHttpResponse<any>) => {
                    if (res && res.success) {
                        this.availableCities = res.data;
                        return res.data;
                    } else {
                        console.warn('Something went wrong in get available cities API');
                        return null;
                    }
                }),
                catchError(this.errorService.handleError)
            );
        }
    }

    // TODO add interface defintion here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getActiveCityListForGivenProduct(productBackendKey: string): Observable<any> {
        const url = `${environment.baseUrl}/product/web/${productBackendKey}/active-city-list`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(url).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((res: IHttpResponse<any>) => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in get active city list by Product API', productBackendKey);
                    return null;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCityNameFromSlug(payload): Observable<any> {
        // TODO :: move this function in selectcity and use data from server not hard-coded
        // return this.http.post<any>(`${this.serviceUrl}/distinctValues/web`, payload);
        let cityName;
        switch (payload.citySlug) {
            case citySlug.BANGALORE:
                cityName = 'Bangalore';
                break;
            case citySlug.NEW_DELHI:
                cityName = 'New Delhi';
                break;
            case citySlug.NOIDA:
                cityName = 'Noida';
                break;
            case citySlug.GURUGRAM:
                cityName = 'Gurugram';
                break;
            case citySlug.HYDERABAD:
                cityName = 'Hyderabad';
                break;
            case citySlug.FARIDABAD:
                cityName = 'Faridabad';
                break;
            case citySlug.MUMBAI:
                cityName = 'Mumbai';
                break;
            case citySlug.KOLKATA:
                cityName = 'Kolkata';
                break;
            case citySlug.PUNE:
                cityName = 'Pune';
                break;
            case citySlug.AHMEDABAD:
                cityName = 'Ahmedabad';
                break;
            case citySlug.JAIPUR:
                cityName = 'Jaipur';
                break;
            case citySlug.CHENNAI:
                cityName = 'Chennai';
                break;
            case citySlug.GHAZIABAD:
                cityName = 'Ghaziabad';
                break;
            case citySlug.VISHAKHAPATNAM:
                cityName = 'Vishakhapatnam';
                break;
            case citySlug.BHOPAL:
                cityName = 'Bhopal';
                break;
            case citySlug.INDORE:
                cityName = 'Indore';
                break;
        }

        return new Observable(subscriber => {
            subscriber.next(cityName);
            subscriber.complete();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPopularCitiesFromProduct(productNameBackend: string): Observable<any> {
        const url = `${environment.baseUrl}/product/${productNameBackend}/popular-city-list`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(url).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((res: IHttpResponse<any>) => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.error('Something went wrong in popular cities API of', productNameBackend);
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getActiveStatesForProduct(payload: IGetActiveStatesForProductRequestPayload) {
        const { product, category } = payload;
        const url = `${environment.baseUrl}/product/${product}/active-states`;
        let params = {};
        if (!!category) params['license'] = category;
        return this.http.get<IHttpResponse<IGetActiveStatesForProductResponse>>(url, { params });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPopularCityOptionsFromProduct(productBackendKey): Observable<any> {
        const url = `${environment.baseUrl}/product/${productBackendKey}/popular-city-options`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<IHttpResponse<any>>(url).pipe(
            map(({ success, data }) => {
                if (success) {
                    return data.cities.map(city => {
                        return {
                            ...city,
                            linkUrl: this.virtualOfficeService.getVirtualOfficeCityLink(city.slug),
                        };
                    });
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getLocationAutocomplete(searchString, cityId): Observable<any> {
        return new Observable(observer => {
            this.geolocationService
                .getGeolocationIfAllowed()
                .subscribe(({ success, geolocation }: IGeoLocationDataResp) => {
                    const queryParams = new HttpParams()
                        .set('cityId', cityId)
                        .set('latitude', success ? `${geolocation?.latitude}` : '')
                        .set('longitude', success ? `${geolocation?.longitude}` : '')
                        .set('searchString', searchString);

                    const url = `${environment.baseUrl}/locations/autocomplete/`;
                    this.http
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .get<any>(url, { params: queryParams })
                        .pipe(
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            tap(res => this.analyticsService.trackAutoSuggestLocationSearch({ searchString, cityId })),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            map((res: IHttpResponse<any>) => {
                                if (res && res.success) {
                                    return res.data;
                                } else {
                                    console.warn(
                                        'Something went wrong in get location autocomplete API',
                                        url,
                                        queryParams
                                    );
                                }
                            }),
                            catchError(this.errorService.handleError)
                        )
                        .subscribe(result => observer.next(result));
                });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCityFromIp(ipAddress): Observable<any> {
        const url = `${environment.baseUrl}/locations/iplocation/${ipAddress}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(url).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((res: IHttpResponse<any>) => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in ip address API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAvailableProductOptions(cityId: string): Observable<any> {
        const url = `${environment.baseUrl}/locations/city/${cityId}/products`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<IHttpResponse<any>>(url).pipe(
            map(({ success, data }) => {
                if (success) {
                    return data;
                } else {
                    console.warn('Something went wrong in get available products API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getCityObjFromSlug(citySlug: string): Observable<ICity> {
        return this.getAvailableCity().pipe(
            map(data => {
                let cityObj: ICity;
                if (data) {
                    cityObj = data.cities.find((city: ICity) => {
                        return city.slug === citySlug;
                    });
                }
                if (cityObj) {
                    return cityObj;
                }
            })
        );
    }

    getLocationFromCoordinates(
        coordinates: ICoordinates,
        productBackendKey: string
    ): Observable<IGetLocationByCoordinatesResponse> {
        const url = `${environment.baseUrl}/locations/web/city/coordinates/locality`;
        return this.http
            .get(
                `${url}?latitude=${coordinates.latitude}&longitude=${coordinates.longitude}&product=${productBackendKey}`
            )
            .pipe(
                map((res: IHttpResponse<IGetLocationByCoordinatesResponse>) => {
                    if (res?.success) {
                        return res.data;
                    } else {
                        console.warn('Something went wrong in getLocationFromCoordinates API');
                    }
                }),
                catchError(this.errorService.handleError)
            );
    }

    setLocationCoordinatesSubData(data: IGetLocationByCoordinatesSubscriptionData) {
        this.locationCoordinates$.next(data);
    }

    getLocationCoordinatesSub(): Observable<IGetLocationByCoordinatesSubscriptionData> {
        return this.locationCoordinates$;
    }

    clearLocationCoordinatesSubData() {
        this.locationCoordinates$.next(null);
    }

    checkCityMetroConnectivity(slug: string, pathname: string): Observable<ICheckCityMetroConnectivityAPIResponse> {
        const url = `${environment.baseUrl}/locations/city/${slug}/connectivityDetails?pageUrl=${pathname}`;
        return this.http.get<IHttpResponse<ICheckCityMetroConnectivityAPIResponse>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in check city metro connectivity API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }
}
