import { AmConstants, AMCoverageAndSupply, ImageBaseUrl, PromotionConstants } from '../../../core/constants/constants';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { Injectable } from '@angular/core';
import { ErrorService } from '../../../core/services/error/error.service';
import { IWhyChooseMyHq } from '../../interfaces/why-choose-my-hq.interface';
import { buttonType, fontSizes, fontWeights } from '../../enums/enums';
import { IButtonConfig } from '../../interfaces/button.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { MR_BOOKING_TYPES, MrBookingType } from '../../../modules/meeting-rooms/meeting-rooms.interface';

@Injectable({
    providedIn: 'root',
})
export class CommonXhrService {
    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendAppLink(payload: any): Observable<IHttpResponse<any>> {
        const url = `${environment.baseUrl}/services/downloadapplink/web`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.post<any>(url, payload).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tap((res: IHttpResponse<any>) => res),
            catchError(this.errorService.handleError)
        );
    }

    subscribeNewsletter(payload) {
        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .post<IHttpResponse<any>>(`${environment.baseUrl}/campaigns/subscribeNewsLetter`, payload)
                .pipe(catchError(this.errorService.handleError))
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getmyHQExpertCTAConfig(): Observable<any> {
        const ctaConfig = {
            heading: 'Didn’t find what you are looking for? Share custom requirements with our team',
            btn: {
                text: 'Request Callback',
                showArrow: true,
                key: 'chat',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getmyHQExpertVOListingCTAConfig(): Observable<any> {
        const ctaConfig = {
            heading: `Didn’t find what you are looking for? Share custom requirements with our team`,
            btn: {
                text: 'Request Callback',
                showArrow: false,
                key: 'callback',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    getMyHQExpertMeetingRoomsListingCTAConfig(): Observable<{
        heading: string;
        description: string;
        button: IButtonConfig;
    }> {
        const ctaConfig = {
            heading: 'Looking for a meeting room?',
            description: 'We’ve got you covered. Connect with our experts now.',
            button: {
                type: buttonType.OUTLINE,
                label: 'Request Callback',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    getWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience.  Your one-stop platform and the industry's largest online player.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero brokerage fee',
                    description: 'We charge zero brokerage fees for our office space rentals',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `An expansive network of ${PromotionConstants.WORKSPACE_COUNT}+ coworking spaces, you’re sure to find what you’re looking for`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: 'Experience swift and effective solutions with us',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Office Spaces Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
            observer.complete();
        });
    }

    getWhyMyHQContentOfficeSpaceV2(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience.  Your one-stop platform and the industry's largest online player.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero brokerage fee',
                    description: 'We charge zero brokerage fees for our office space rentals',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `Pan India coverage with 5000+ landlords, coworking operators`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: `Experience swift and effective solutions with us`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Workspace Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getWhyMyHQFlexiContent(): Observable<IWhyChooseMyHq> {
        const whymyHQFlexiContent: IWhyChooseMyHq = {
            heading: `Why book Desks with myHQ?`,
            hideDescriptionsMob: true,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/unique-spaces-flat.svg`,
                        alt: `Unique Spaces`,
                    },
                    heading: 'Unique Spaces',
                    description: 'Spaces - from artistic cafes to hidden rooftops and beyond',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/economical-pricing-flat.svg`,
                        alt: `Economical Pricing`,
                    },
                    heading: 'Economical Pricing',
                    description: 'Pay by the day without worrying about monthly rents',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/hassle-free-booking-flat.svg`,
                        alt: `Hassle free bookings`,
                    },
                    heading: 'Hassle-free Bookings',
                    description: 'Book a workspace with the click of a button',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/productive-work-day-flat.svg`,
                        alt: `Productive Workday`,
                    },
                    heading: 'Productive Workday',
                    description: 'Get all work amenities like high speed wifi, power backup, printing and more',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQFlexiContent);
        });
    }

    getWhyMyHQVOContent(iconColor?: 'white' | 'black'): Observable<IWhyChooseMyHq> {
        const whyMyhqVO: IWhyChooseMyHq = {
            heading: 'What does myHQ guarantee in your Virtual Office?',
            benefits: [
                {
                    image: {
                        url:
                            iconColor === 'black'
                                ? CommonIconsUrl.TICK_MARK_BLACK_ICON
                                : `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/lowest-price-flat.svg`,
                        alt: `tick mark black`,
                    },
                    heading: 'Lowest Price Guarantee',
                },
                {
                    image: {
                        url:
                            iconColor === 'black'
                                ? CommonIconsUrl.TICK_MARK_BLACK_ICON
                                : `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                        alt: `tick mark black`,
                    },
                    heading: 'Money Back Guarantee',
                },
                {
                    image: {
                        url:
                            iconColor === 'black'
                                ? CommonIconsUrl.TICK_MARK_BLACK_ICON
                                : `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/kyc.svg`,
                        alt: `tick mark black`,
                    },
                    heading: 'Fastest Document Turnaround Time',
                },
                {
                    image: {
                        url:
                            iconColor === 'black'
                                ? CommonIconsUrl.TICK_MARK_BLACK_ICON
                                : `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/money-back-guarentee.svg`,
                        alt: `tick mark black`,
                    },
                    heading: 'Digital KYC and Agreement',
                },
            ],
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.IMAGE_CONFIG_BASE_FQ}/web/ssr/pages/virtual-office/listing/why-myhq-vo`,
                alt: 'Managed Office',
            },
            buttonConfig: {
                label: 'Request Callback',
                size: fontSizes.MEDIUM,
                type: buttonType.OUTLINE,
                fontWeight: fontWeights.BOLD,
            },
        };

        return new Observable(observer => {
            observer.next(whyMyhqVO);
        });
    }

    getWhyMyHQDedicatedVisitSuccessContent(): Observable<IWhyChooseMyHq> {
        const whyMyHQDedicatedSuccess: IWhyChooseMyHq = {
            heading: 'Why choose myHQ?',
            description: ``,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/largest-coverage-flat.svg`,
                        alt: `Largest coverage`,
                    },
                    heading: 'Largest coverage',
                    description: `An expansive network of ${PromotionConstants.WORKSPACE_COUNT}+ coworking spaces and managed offices to choose from`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/unique-spaces.svg`,
                        alt: `Workspaces for everyone`,
                    },
                    heading: 'Workspaces for everyone',
                    description:
                        'From startups to large enterprises, our workspaces are designed for teams & businesses of all sizes.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/no-brokerage-fee.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero brokerage fee',
                    description: `We charge zero brokerage fees for office space rentals.`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/transparency.svg`,
                        alt: `Transparent & Ethical`,
                    },
                    heading: 'Transparent & Ethical',
                    description: 'Our pricing is transparent with no hidden charges and honest space reviews.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/dedicated-experts.svg`,
                        alt: `Dedicated Workspace Experts`,
                    },
                    heading: 'Dedicated Workspace Experts',
                    description:
                        'From consulting, shortlisting to documentation, our workspaces experts with help you in finding the perfect workspace.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/hassle-free-transparent.svg`,
                        alt: `Hassle Free Experience`,
                    },
                    heading: 'Hassle Free Experience',
                    description: '100% digital process with no running around for compliances or unnecessary calls',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whyMyHQDedicatedSuccess);
        });
    }

    getDedicatedWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience. With an in depth knowledge, let our workspace solution experts find what you’re looking for.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero brokerage fee`,
                    },
                    heading: 'Zero brokerage fee',
                    description:
                        'Direct access to the best office spaces and property owners with transparent pricing and zero brokerage fee.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick turnaround time & move-in support',
                    description:
                        'Streamlined process and simplified decision-making for efficient office setup and swift move-in.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest network of Coworking Spaces',
                    description: `India's largest network of verified office spaces, spanning ${AMCoverageAndSupply.CITIES.title} cities, ${AMCoverageAndSupply.PROPERTIES.title} properties and ${AMCoverageAndSupply.AREA.title} square feet in area.`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Your own office consultant`,
                    },
                    heading: 'Your own office consultant',
                    description:
                        'One-on-one support for finding your new office seamlessly—from shortlisting the right properties to finalizing terms and ensuring smooth onboarding. ',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getManagedOfficeWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero brokerage fee`,
                    },
                    heading: 'Zero brokerage fee',
                    description:
                        'Direct access to the best office spaces and property owners with transparent pricing and zero brokerage fee.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/layout.svg`,
                        alt: `Design & layout support`,
                    },
                    heading: 'Design & layout support',
                    description:
                        'Expert consultation to optimize workspace layouts, ensuring functionality, productivity and employee satisfaction.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest network of offices',
                    description: `India's largest network of verified office spaces, spanning ${AMCoverageAndSupply.CITIES.title} cities, ${AMCoverageAndSupply.PROPERTIES.title} properties and ${AMCoverageAndSupply.AREA.title} square feet in area.`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Your own office consultant`,
                    },
                    heading: 'Your own office consultant',
                    description:
                        'One-on-one support for finding your new office seamlessly—from shortlisting the right properties to finalizing terms and ensuring smooth onboarding. ',
                },
            ],
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/managed-office.png`,
                alt: 'Managed Office',
            },
            buttonConfig: {
                label: 'Get Free Consultation',
                size: fontSizes.MEDIUM,
                type: buttonType.OUTLINE,
                fontWeight: fontWeights.BOLD,
            },
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getCommercialLeasingWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero brokerage fee`,
                    },
                    heading: 'Zero brokerage fee',
                    description:
                        'Direct access to the best office spaces and property owners with transparent pricing and zero brokerage fee.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/partnership.svg`,
                        alt: `Ethical practices (backed by ANAROCK)`,
                    },
                    heading: 'Ethical practices (backed by ANAROCK)',
                    description:
                        "Honest office space feedback and transparent communication. Powered by ANAROCK Group – India's leading real estate giant.",
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Network of 100% Verified Properties',
                    description: `India's largest network of verified office spaces, spanning ${AMCoverageAndSupply.CITIES.title} cities, ${AMCoverageAndSupply.PROPERTIES.title} properties and ${AMCoverageAndSupply.AREA.title} square feet in area.`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Your own office consultant`,
                    },
                    heading: 'Your own office consultant',
                    description:
                        'One-on-one support for finding your new office seamlessly—from shortlisting the right properties to finalizing terms and ensuring smooth onboarding. ',
                },
            ],
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/commercial-leasing.png`,
                alt: 'Managed Office',
            },
            buttonConfig: {
                label: 'Get Free Consultation',
                size: fontSizes.MEDIUM,
                type: buttonType.OUTLINE,
                fontWeight: fontWeights.BOLD,
            },
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }
    getEnterprisesMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why businesses choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience. With an in depth knowledge, let our office space solution experts find what you’re looking for.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero brokerage fee`,
                    },
                    heading: 'Zero brokerage fee',
                    description: 'We charge zero brokerage fees for office space rentals.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `Pan India coverage with 5000+ landlords & properties.`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: "Quick and Efficient- That's How We Roll!",
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Office Spaces Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/ethical-flat.svg`,
                        alt: `Transparent & Ethical`,
                    },
                    heading: 'Transparent & Ethical',
                    description: 'Honest office space feedbacks and transparent communication.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/partnership.svg`,
                        alt: `Part of the ANAROCK Group`,
                    },
                    heading: 'Part of the ANAROCK Group',
                    description: "Powered by ANAROCK Group – India's Leading Real Estate Giant.",
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getWorkspaceBrandWhyMyHQContent(brandName?: string): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose ${brandName ? brandName + ' with ' : ''}myHQ${!brandName ? ' by ANAROCK' : ''}?`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero brokerage fee',
                    description:
                        'Direct access to the best office spaces and property owners with transparent pricing and zero brokerage fee.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick turnaround time & move-in support',
                    description:
                        'Streamlined process and simplified decision-making for efficient office setup and swift move-in.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest network',
                    description: `India's largest network of verified office spaces, spanning ${AMCoverageAndSupply.CITIES.title} cities, ${AMCoverageAndSupply.PROPERTIES.title} properties and ${AMCoverageAndSupply.AREA.title} square feet in area.`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Your own office consultant`,
                    },
                    heading: 'Your own office consultant',
                    description:
                        'One-on-one support for finding your new office seamlessly—from shortlisting the right properties to finalizing terms and ensuring smooth onboarding. ',
                },
                // {
                //     image: {
                //         url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/partnership.svg`,
                //         alt: `Ethical practices (backed by ANAROCK)`,
                //     },
                //     heading: 'Ethical practices (backed by ANAROCK)',
                //     description:
                //         "Honest office space feedback and transparent communication. Powered by ANAROCK Group – India's leading real estate giant.",
                // },
                // {
                //     image: {
                //         url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/layout.svg`,
                //         alt: `Design & layout support`,
                //     },
                //     heading: 'Design & layout support',
                //     description:
                //         'Expert consultation to optimize workspace layouts, ensuring functionality, productivity and employee satisfaction.',
                // },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getMeetingRoomsWhyMyHQContent(
        bookingType: MrBookingType = MR_BOOKING_TYPES.ONLINE_INSTANT
    ): Observable<IWhyChooseMyHq> {
        const bookingTypeService =
            bookingType === MR_BOOKING_TYPES.OFFLINE
                ? {
                      image: {
                          url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                          alt: `Instant Confirmation`,
                      },
                      heading: 'Expert Assistance',
                      description: 'Get expert assistance from our sales team',
                  }
                : {
                      image: {
                          url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                          alt: `Instant Confirmation`,
                      },
                      heading: 'Instant Confirmation',
                      description: 'Secure a meeting room on the go with guaranteed reservation',
                  };

        const whyMyhqMRContent: IWhyChooseMyHq = {
            heading: 'Why book Meeting Rooms with myHQ?',
            description: '',
            benefits: [
                bookingTypeService,
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/currency-white.svg`,
                        alt: `Lowest Price`,
                    },
                    heading: 'Lowest Prices',
                    description: 'Enjoy Lowest rates and attractive discounts',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/meeting-rooms/why-my-hq/premium-offerings.svg`,
                        alt: 'Fully Equipped & Verified Listings',
                    },
                    heading: 'Fully Equipped',
                    description: 'Projectors, VC facilities with a pleasing ambiance',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/meeting-rooms/why-my-hq/receipt-white.svg`,
                        alt: `Instant Invoice Generation`,
                    },
                    heading: 'Instant Invoice Generation',
                    description: 'For easier corporate reimbursements',
                },
            ],
            hideDescriptionsMob: true,
        };
        return new Observable(observer => {
            observer.next(whyMyhqMRContent);
        });
    }

    getWhyMeetingRoomsContent(alias?: boolean) {
        function createItem(imagePath: string, alt: string, description: string) {
            return {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}${imagePath}`,
                    alt: alt,
                },
                heading: '',
                description: description,
                cssClasses: {
                    container: 'flx-d-clm text-align-center flx-1 a-items-c flx-gap-16',
                },
            };
        }

        const dedicatedExpertAssistance = createItem(
            '/web/ssr/common/whymyhq/dedicated-flat.svg',
            'Dedicated Office Spaces Experts',
            'Dedicated expert assistance for specialised requirements'
        );

        if (alias) {
            const instantInvoiceGeneration = createItem(
                '/web/ssr/pages/virtual-office/why-myhq/flexible-plan-v2-flat.svg',
                'Instant invoice generation',
                'Get instant invoice generation'
            );

            const payByHourOrDay = createItem(
                '/web/ssr/common/whymyhq/fastest-tat.svg',
                'Pay by hour or day',
                'Pay by the hour or by the day'
            );

            const fullyEquipped = createItem(
                '/web/ssr/common/whymyhq/workspace-flat.svg',
                'Fully equipped',
                'Premium fully-equipped spaces'
            );
            return [fullyEquipped, payByHourOrDay, dedicatedExpertAssistance, instantInvoiceGeneration];
        }

        const spacePartners = createItem(
            '/web/ssr/common/whymyhq/map-flat.svg',
            'Space partners',
            '250+ meeting spaces offered by our partner brands'
        );

        const instantConfirmation = createItem(
            '/web/ssr/common/whymyhq/fastest-tat.svg',
            'Instant confirmation',
            'Instant & verified confirmation on bookings'
        );

        const flexiblePlans = createItem(
            '/web/ssr/pages/virtual-office/why-myhq/flexible-plan-v2-flat.svg',
            'Dedicated Office Spaces Experts',
            'Book for multiple guests and multiple days in one go'
        );

        return [spacePartners, instantConfirmation, flexiblePlans, dedicatedExpertAssistance];
    }
}
