<div class="d-flx flx-d-clm">
    <div class="padding-vertical-12 padding-horizontal-16 d-flx a-items-c j-cont-space-bet" (click)="handleCollapse()">
        <div class="d-flx gap-8">
            <img [src]="menu.iconUrl" [alt]="menu.title" width="16" height="16" />
            <div class="d-flx flx-d-clm gap-4">
                <div class="font-12 font-bold">{{ menu.title }}</div>
                @if (menu.description) {
                    <div class="font-10 text-color-x-lighter-grey">{{ menu.description }}</div>
                }
            </div>
        </div>
        <div class="arrow margin-right-8" [class.arrow-down]="menu.collapsed" [class.arrow-up]="!menu.collapsed"></div>
    </div>
    @if (!menu.collapsed) {
        <div class="d-flx flx-d-clm">
            @for (menuItem of menu.menuItems; track menuItem.id) {
                @if (menuItem.type !== DropdownMenuItemType.ACCORDION) {
                    <ng-container
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            menuItem,
                            additionalClass: 'padding-left-40 padding-vertical-12 padding-right-16',
                        }"
                    />
                } @else {
                    <div class="padding-left-40 padding-right-16">
                        <app-accordion [config]="menuItem.config" />
                    </div>
                }
            }
        </div>
    }
</div>

<!-- Template for menu items with proper anchor tags -->
<ng-template #menuItemTemplate let-menuItem="menuItem" let-additionalClass="additionalClass">
    @if (menuItem.isExternalUrl) {
        <a
            [href]="menuItem.redirectUrl | async"
            (click)="handleMenuItemClick()"
            class="{{ additionalClass }}"
            target="_blank"
        >
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ menuItem }" />
        </a>
    } @else {
        <a
            [routerLink]="menuItem.redirectUrl | async"
            (click)="handleMenuItemClick()"
            class="{{ additionalClass }}"
            routerLinkActive="bg-color-grey"
            [routerLinkActiveOptions]="{ exact: true }"
        >
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ menuItem }" />
        </a>
    }
</ng-template>

<!-- Template for menu item inner content -->
<ng-template #menuItemInnerTemplate let-menuItem="menuItem">
    <div class="d-flx flx-d-clm gap-4">
        <div class="font-12">
            <span>{{ menuItem.title }} </span>
            @if (menuItem.tag) {
                <span class="tag">{{ menuItem.tag }}</span>
            }
            @if (menuItem.comingSoon) {
                <span class="tag-grey"><i>- Coming soon</i></span>
            }
        </div>
        <div class="font-10 text-color-medium-grey">{{ menuItem.description }}</div>
    </div>
</ng-template>

<!-- Accordion header template -->
<ng-template #accordionMenuItemHeaderTemplate let-data="item">
    <div class="font-12">{{ data.menuItem.title }}</div>
</ng-template>

<!-- Accordion body template that reuses the menuItemTemplate -->
<ng-template #accordionMenuItemBodyTemplate let-data="item">
    <div class="margin-top-12">
        <div class="padding-left-16 accordion-body-inner-container">
            @for (subMenuItem of data.menuItem.items; track subMenuItem) {
                <div class="padding-vertical-12">
                    <ng-container
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            menuItem: subMenuItem,
                        }"
                    />
                </div>
            }
        </div>
    </div>
</ng-template>
