// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractKeys<T extends Record<any, any>> = T[keyof T];

export const ElementType = {
    TEXT: 'TEXT',
    PARAGRAPH: 'PARAGRAPH',
    HEADING: 'HEADING',
    ITALIC: 'ITALIC',
    BOLD: 'BOLD',
    LIST: 'LIST',
    LINK: 'LINK',
    IMAGE: 'IMAGE',
    SECTION: 'SECTION',
    SPACE: 'SPACE',
    ICON: 'ICON',
} as const;

export type ElementType = ExtractKeys<typeof ElementType>;

export enum ListElementStyle {
    ORDERED = 'ORDERED',
    UNORDERED = 'UNORDERED',
}

export enum HeadingElementStyle {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    SUBTITLE = 'SUBTITLE',
    CAPTION = 'CAPTION',
}

export type TextElement = { type: typeof ElementType.TEXT; data: { text: string } };

export type ParagraphElement<T extends Record<string, any> = {}> = {
    type: typeof ElementType.PARAGRAPH;
    data: {
        children: IStructuredContent<T>;
    };
};

export type HeadingElement = {
    type: typeof ElementType.HEADING;
    data: { style?: HeadingElementStyle; id?: string; text: string };
};

export type ItalicElement = {
    type: typeof ElementType.ITALIC;
    data: { text: string };
};

export type BoldElement = {
    type: typeof ElementType.BOLD;
    data: { text: string };
};

export type ListElement<T extends Record<string, any> = {}> = {
    type: typeof ElementType.LIST;
    data: {
        style: ListElementStyle;
        text?: string;
        items: (ParagraphElement<T> | BoldElement | ItalicElement | LinkElement | TextElement)[];
    };
};

export type LinkElement = {
    type: typeof ElementType.LINK;
    data:
        | {
              text: string;
              url: string;
              isExternal?: boolean;
          }
        | { text: string; id: string };
};

export type ImageElement = {
    type: typeof ElementType.IMAGE;
    data: {
        url: string;
        alt: string;
    };
};

export type SectionElement<T extends Record<string, any> = {}> = {
    type: typeof ElementType.SECTION;
    data: {
        children: IStructuredContent<T>;
    };
};

export type SpaceElement<T extends Record<string, any> = {}> = {
    type: typeof ElementType.SPACE;
    data: {
        align?: 'center' | 'start' | 'end';
        inline?: boolean;
        direction?: 'horizontal' | 'vertical';
        size?: number;
        children: IStructuredContent<T>;
    };
};

export type IconElement = {
    type: typeof ElementType.ICON;
    data: {
        size?: number;
        src: string;
    };
};

export type Element<T extends Record<string, any> = {}> =
    | ParagraphElement<T>
    | HeadingElement
    | ItalicElement
    | BoldElement
    | ListElement<T>
    | LinkElement
    | ImageElement
    | SectionElement<T>
    | TextElement
    | SpaceElement<T>
    | IconElement

export type IStructuredContent<CustomMap extends Record<string, any> = {}> = Array<Element<CustomMap>>;


