import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
    HeaderSearchVariant,
    LocationWorkspaceSearchSuggestionType,
    PrefixIcon,
    products,
    productsMap,
    TypeaheadTypes,
} from '../../../enums/enums';
import { menuEnum } from '../enums/enums';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../../../../core/services/error/error.service';
import { ImageBaseUrl } from '../../../../core/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IHttpResponse } from '../../../interfaces/HTTPResponse.interface';
import {
    authPagesRegex,
    brandUrlRegex,
    caseStudyRegex,
    checkoutHeaderUrls,
    checkoutWithCityUrls,
    checkoutWithLogoClickableUrl,
    commercialLeasingUrlRegex,
    corporateFlexiUrlRegex,
    dedicatedUrlRegex,
    flexiUrlRegex,
    guidesRegex,
    knowledgeCenterRegex,
    landingPagesRegex,
    managedOfficeUrlRegex,
    meetingRoomsRegex,
    nexusAuthPagesRegex,
    referAndEarnRegex,
    referralHeaderUrls,
    spaceVisitRegex,
    staticPagesURLRegex,
    testimonialsRegex,
    urlRegex,
    userReviewRegex,
    virtualOfficeUrlRegex,
} from '../../../../core/constants/urls-regex';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import {
    LocationWorkspaceSearchConfig,
    LocationWorkspaceType,
} from '../../location/location-workspace-search/location-workspace-search.types';
import { ICity } from '../../../interfaces/location.interface';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private utilsService: UtilsService
    ) {}

    ImageBaseUrl = ImageBaseUrl;
    deviceInfo = this.utilsService.getDeviceInfo();

    locationWorkspaceSearchConfig: LocationWorkspaceSearchConfig = {
        type: LocationWorkspaceType.COMMON_HEADER_SEARCH,
        typeaheadConfig: {
            type: TypeaheadTypes.SMALL,
            prefixIcon: PrefixIcon.SEARCH_ICON,
            placeholder: 'Search location or workspace',
            scrollTypeaheadDropDown: false,
        },
        typeaheadContainerClass: 'common-header-search-typeahead-cont',
        product: null,
        showSearchModal: false,
        searchSuggestionType: LocationWorkspaceSearchSuggestionType.LOCATION_AND_WORKSPACE,
    };

    // TODO: @salah: showCityChange / showLpContact should be part of menuConfig array and not a seperate key in the config object?
    // TODO: Left side right side menus should come from config
    // TODO: Move this file to Regex. Regex in url-regex
    config = {
        home: {
            productObj: null,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [
                menuEnum.PRODUCTS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.DOWNLOAD_APP,
                menuEnum.CONTACT_US,
            ],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: true,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: true,
                showDesktopSearchInStickyOnly: true,
                showProductSwitch: true,
                headerSearchVariant: HeaderSearchVariant.DETAILED,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        flexi: {
            productObj: productsMap.FLEXI,
            menuConfig: [menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.FREE_TRIAL],
            menuConfigMobile: [
                menuEnum.FREE_TRIAL,
                menuEnum.PRODUCTS,
                menuEnum.MORE,
                menuEnum.CONTACT_US,
                menuEnum.DOWNLOAD_APP,
            ],
            hideWhenCityNotSupportProduct: [menuEnum.LOCATIONS, menuEnum.FREE_TRIAL],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        dedicated: {
            productObj: productsMap.DEDICATED,
            menuConfig: [menuEnum.PRODUCTS, menuEnum.LOCATIONS, menuEnum.MORE, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.LOCATIONS, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        managedOffice: {
            productObj: productsMap.MANAGED_OFFICE,
            menuConfig: [menuEnum.LOCATIONS, menuEnum.MORE, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        commercialLeasing: {
            productObj: productsMap.COMMERCIAL_LEASING,
            menuConfig: [menuEnum.LOCATIONS, menuEnum.MORE, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.HOME, menuEnum.SEARCH, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        corporateFlexi: {
            productObj: productsMap.CORPORATE_FLEXI,
            menuConfig: [menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US, menuEnum.SIGNUP],
            menuConfigMobile: [menuEnum.LOGIN_SIGNUP_FLEXI, menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        guides: {
            productObj: productsMap.VIRTUAL_OFFICE,
            menuConfig: [menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.CONTACT_US],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            hideWhenCityNotSupportProduct: [],
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        caseStudy: {
            productObj: productsMap.DEDICATED,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            hideWhenCityNotSupportProduct: [],
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        knowledgeCenter: {
            productObj: productsMap.DEDICATED,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [menuEnum.GUIDE_FOR_OFFICE_SPACE, menuEnum.LIST_SPACE, menuEnum.CONTACT_US],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            hideWhenCityNotSupportProduct: [],
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        referAndEarn: {
            productObj: productsMap.DEDICATED,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            hideWhenCityNotSupportProduct: [],
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        testimonials: {
            productObj: productsMap.DEDICATED,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.MORE,
                menuEnum.LIST_SPACE,
                menuEnum.CONTACT_US,
            ],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            hideWhenCityNotSupportProduct: [],
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        virtualOffice: {
            productObj: productsMap.VIRTUAL_OFFICE,
            menuConfig: [menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP],
            menuConfigMobile: [menuEnum.LOGIN_SIGNUP, menuEnum.CONTACT_US, menuEnum.LOGOUT_BTN],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.getVOLocationWorkspaceSearchConfig(),
        },
        brand: {
            productObj: null,
            menuConfig: [menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.REQUEST_CALLBACK, menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: true,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: true,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        virtualOfficeProductPage: {
            productObj: productsMap.VIRTUAL_OFFICE,
            menuConfig: [menuEnum.PRODUCTS, menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP],
            menuConfigMobile: [menuEnum.LOGIN_SIGNUP, menuEnum.PRODUCTS, menuEnum.CONTACT_US, menuEnum.LOGOUT_BTN],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: true,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.getVOLocationWorkspaceSearchConfig(),
        },
        enterprisesProductPage: {
            productObj: null,
            menuConfig: [
                menuEnum.ALL_SOLUTIONS,
                menuEnum.OFFICE_SPACE,
                menuEnum.LOCATIONS,
                menuEnum.MORE,
                menuEnum.CONTACT_US,
            ],
            menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.OFFICE_SPACE, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: true,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        meetingRoom: {
            productObj: productsMap.MEETING_ROOM,
            menuConfig: [menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP_FLEXI],
            menuConfigMobile: [
                menuEnum.LOGIN_SIGNUP_FLEXI,
                menuEnum.CONTACT_US,
                menuEnum.DOWNLOAD_APP,
                menuEnum.LOGOUT_BTN_FLEXI,
            ],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: true,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        landingPages: {
            productObj: null,
            menuConfig: [],
            menuConfigMobile: [],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: false,
            isAnimated: true,
            showMenuIconOnMobile: false,
            showLpContact: true,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        checkoutWithCity: {
            productObj: null,
            menuConfig: [],
            menuConfigMobile: [],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: false,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        checkoutWithLogoClickable: {
            productObj: null,
            menuConfig: [],
            menuConfigMobile: [],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: false,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        checkout: {
            productObj: null,
            menuConfig: [],
            menuConfigMobile: [],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: false,
            isAnimated: false,
            showMenuIconOnMobile: false,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        referral: {
            productObj: null,
            menuConfig: [menuEnum.ALL_SOLUTIONS, menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        rateFreeTrial: {
            productObj: null,
            menuConfig: [menuEnum.ALL_SOLUTIONS, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: true,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        login: {
            productObj: null,
            menuConfig: [],
            menuConfigMobile: [],
            hideWhenCityNotSupportProduct: [],
            showCityChange: false,
            isLogoClickable: true,
            isAnimated: false,
            showMenuIconOnMobile: false,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: false,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: false,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
        default: {
            productObj: null,
            menuConfig: [menuEnum.ALL_SOLUTIONS, menuEnum.MORE, menuEnum.LIST_SPACE, menuEnum.CONTACT_US],
            menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.MORE, menuEnum.LIST_SPACE, menuEnum.CONTACT_US],
            hideWhenCityNotSupportProduct: [],
            showCityChange: true,
            isLogoClickable: true,
            isAnimated: true,
            showMenuIconOnMobile: true,
            showLpContact: false,
            headerSearchConfig: {
                showSearch: true,
                showDesktopSearchInStickyOnly: false,
                showProductSwitch: true,
                headerSearchVariant: HeaderSearchVariant.DEFAULT,
            },
            locationWorkspaceSearchConfig: this.locationWorkspaceSearchConfig,
        },
    };

    private popularWorkspacesByCity = {
        product: '',
        cityId: '',
        data: '',
    };

    private menuSubject = new Subject<menuEnum>();

    getHeaderConfig(url, city?: ICity) {
        // TODO :: add regex check
        // TODO :: we have to thought find a sol so that we can segrigate path params from url in this funciton
        if (!url) {
            return;
        }
        if (this.utilsService.getRegexPatternMatch([testimonialsRegex.testimonials], url) >= 0) {
            return this.config.testimonials;
        }
        if (this.utilsService.getRegexPatternMatch([guidesRegex.guides], url) >= 0) {
            return this.config.guides;
        }
        if (this.utilsService.getRegexPatternMatch([caseStudyRegex.allUrls], url) >= 0) {
            return this.config.caseStudy;
        }
        if (this.utilsService.getRegexPatternMatch([knowledgeCenterRegex.allUrls], url) >= 0) {
            return this.config.knowledgeCenter;
        }
        if (this.utilsService.getRegexPatternMatch([referAndEarnRegex.allUrls], url) >= 0) {
            return this.config.referAndEarn;
        }
        if (this.utilsService.getRegexPatternMatch(checkoutHeaderUrls, url) >= 0) {
            return this.config.checkout;
        }
        if (this.utilsService.getRegexPatternMatch(checkoutWithLogoClickableUrl, url) >= 0) {
            return this.config.checkoutWithLogoClickable;
        }
        if (this.utilsService.getRegexPatternMatch(checkoutWithCityUrls, url) >= 0) {
            return this.config.checkoutWithCity;
        } else if (this.utilsService.getRegexPatternMatch([userReviewRegex.rateYourExperience], url) >= 0) {
            return this.config.rateFreeTrial;
        } else if (this.utilsService.getRegexPatternMatch([landingPagesRegex.voPanIndia], url) >= 0) {
            return this.config.landingPages;
        }
        if (url === '/office-space-for-enterprises') {
            return { ...this.config.enterprisesProductPage };
        }
        if (url === '/office-space-for-enterprises') {
            return { ...this.config.enterprisesProductPage };
        } else if (this.utilsService.getRegexPatternMatch([authPagesRegex.login], url) >= 0) {
            return this.config.login;
        } else if (this.utilsService.getRegexPatternMatch([nexusAuthPagesRegex.allUrls], url) >= 0) {
            return this.config.login;
        } else if (this.utilsService.getRegexPatternMatch([spaceVisitRegex.myShortlist], url) >= 0) {
            return {
                ...this.config.default,
                headerSearchConfig: {
                    ...this.config.default.headerSearchConfig,
                    showProductSwitch: false,
                },
                menuConfig: [menuEnum.CONTACT_US],
                menuConfigMobile: [menuEnum.CONTACT_US],
                showCityChange: false,
            };
        } else if (this.utilsService.getRegexPatternMatch([spaceVisitRegex.scheduleVisit], url) >= 0) {
            return {
                ...this.config.default,
                headerSearchConfig: {
                    ...this.config.default.headerSearchConfig,
                    showProductSwitch: false,
                },
                menuConfig: [menuEnum.MY_SHORTLIST, menuEnum.CONTACT_US],
                menuConfigMobile: [menuEnum.MY_SHORTLIST, menuEnum.CONTACT_US],
                showCityChange: false,
            };
        } else if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.bookingPages], url) >= 0) {
            return {
                ...this.config.flexi,
                showCityChange: false,
                headerSearchConfig: {
                    showSearch: false,
                },
                menuConfig: [menuEnum.LOGIN_SIGNUP_FLEXI],
                menuConfigMobile: [
                    menuEnum.LOGIN_SIGNUP_FLEXI,
                    menuEnum.CONTACT_US,
                    menuEnum.DOWNLOAD_APP,
                    menuEnum.LOGOUT_BTN_FLEXI,
                ],
            };
        } else if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.allUrls], url) >= 0) {
            if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.listingPage], url) >= 0) {
                // here we are checking listing page URLs (/flexi/city-slug/coworking-in-area-slug)
                return {
                    ...this.config.flexi,
                    menuConfig: [menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP_FLEXI],
                    menuConfigMobile: [
                        menuEnum.LOGIN_SIGNUP_FLEXI,
                        menuEnum.CONTACT_US,
                        menuEnum.DOWNLOAD_APP,
                        menuEnum.LOGOUT_BTN_FLEXI,
                    ],
                    headerSearchConfig: {
                        showSearch: !this.deviceInfo.isMobile,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                    },
                    showCityChange: false,
                    locationWorkspaceSearchConfig: this.getLocationWorkspaceSearchConfig(
                        products.INDIVIDUAL,
                        city,
                        LocationWorkspaceSearchSuggestionType.WORKSPACE
                    ),
                };
            } else if (this.utilsService.getRegexPatternMatch(referralHeaderUrls, url) >= 0) {
                return this.config.referral;
            } else if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.spaceDetailPage], url) >= 0) {
                return {
                    ...this.config.flexi,
                    menuConfig: [menuEnum.PRODUCTS, menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP_FLEXI],
                    menuConfigMobile: [
                        menuEnum.LOGIN_SIGNUP_FLEXI,
                        menuEnum.PRODUCTS,
                        menuEnum.CONTACT_US,
                        menuEnum.DOWNLOAD_APP,
                        menuEnum.LOGOUT_BTN_FLEXI,
                    ],
                    showCityChange: false,
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                        showMobileSearch: true,
                        headerSearchVariant: HeaderSearchVariant.DEFAULT,
                    },
                };
            } else if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.productPage], url) >= 0) {
                return {
                    ...this.config.flexi,
                    isAnimated: true,
                    showCityChange: true,
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: true,
                        showProductSwitch: false,
                        headerSearchVariant: HeaderSearchVariant.DEFAULT,
                    },
                };
            }
            return this.config.flexi;
        } else if (this.utilsService.getRegexPatternMatch([corporateFlexiUrlRegex.allUrls], url) >= 0) {
            return this.config.corporateFlexi;
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.listingPage], url) >= 0) {
            return {
                ...this.config.virtualOffice,
                menuConfig: [menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP],
                headerSearchConfig: {
                    showSearch: true,
                    showDesktopSearchInStickyOnly: false,
                    showProductSwitch: false,
                },
                showCityChange: false,
                locationWorkspaceSearchConfig: this.getVOLocationWorkspaceSearchConfig(city),
            };
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.paymentsPage], url) >= 0) {
            return {
                ...this.config.virtualOffice,
                showCityChange: false,
                menuConfig: [],
                menuConfigMobile: [menuEnum.REQUEST_CALLBACK],
            };
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.compareWorkspacePage], url) >= 0) {
            return {
                ...this.config.virtualOffice,
                showCityChange: false,
                menuConfig: [menuEnum.CONTACT_US],
                menuConfigMobile: [menuEnum.REQUEST_CALLBACK, menuEnum.CONTACT_US],
            };
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.spaceDetailPage], url) >= 0) {
            return {
                ...this.config.virtualOffice,
                menuConfig: [menuEnum.PRODUCTS, menuEnum.CONTACT_US, menuEnum.LOGIN_SIGNUP],
                menuConfigMobile: [menuEnum.LOGIN_SIGNUP, menuEnum.PRODUCTS, menuEnum.CONTACT_US, menuEnum.LOGOUT_BTN],
                headerSearchConfig: {
                    showSearch: true,
                    showDesktopSearchInStickyOnly: false,
                    showProductSwitch: false,
                },
                showCityChange: false,
            };
        } else if (this.utilsService.getRegexPatternMatch([brandUrlRegex.listingPage], url) >= 0) {
            return { ...this.config.brand };
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.productPage], url) >= 0) {
            return this.config.virtualOfficeProductPage;
        } else if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.allUrls], url) >= 0) {
            return this.config.virtualOffice;
        } else if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.paymentsPage], url) >= 0) {
            return {
                ...this.config.meetingRoom,
                headerSearchConfig: {
                    showSearch: false,
                },
                isAnimated: false,
                showCityChange: false,
                menuConfig: [menuEnum.LOGIN_SIGNUP_FLEXI],
            };
        } else if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.spaceDetailPage], url) >= 0) {
            return {
                ...this.config.meetingRoom,
                menuConfigMobile: [
                    menuEnum.LOGIN_SIGNUP_FLEXI,
                    menuEnum.PRODUCTS,
                    menuEnum.CONTACT_US,
                    menuEnum.DOWNLOAD_APP,
                    menuEnum.LOGOUT_BTN_FLEXI,
                ],
            };
        } else if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.listingPage], url) >= 0) {
            return {
                ...this.config.meetingRoom,
                headerSearchConfig: {
                    showSearch: !this.deviceInfo.isMobile,
                },
                locationWorkspaceSearchConfig: this.getLocationWorkspaceSearchConfig(
                    products.MEETING_ROOM,
                    city,
                    LocationWorkspaceSearchSuggestionType.WORKSPACE
                ),
            };
        } else if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.allUrls], url) >= 0) {
            return this.config.meetingRoom;
        } else if (this.utilsService.getRegexPatternMatch([dedicatedUrlRegex.allUrls], url) >= 0) {
            if (this.utilsService.getRegexPatternMatch([dedicatedUrlRegex.listingPage], url) >= 0) {
                return {
                    ...this.config.dedicated,
                    menuConfig: [menuEnum.CONTACT_US, menuEnum.MY_SHORTLIST],
                    menuConfigMobile: [menuEnum.MY_SHORTLIST, menuEnum.CONTACT_US],
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                    },
                    showCityChange: false,
                    locationWorkspaceSearchConfig: this.getLocationWorkspaceSearchConfig(products.DEDICATED, city),
                };
            } else if (this.utilsService.getRegexPatternMatch([dedicatedUrlRegex.spaceDetailPage], url) >= 0) {
                return {
                    ...this.config.dedicated,
                    showCityChange: false,
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                    },
                    menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.CONTACT_US],
                };
            } else if (this.utilsService.getRegexPatternMatch([dedicatedUrlRegex.productPage], url) >= 0) {
                return {
                    ...this.config.dedicated,
                    isAnimated: true,
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: true,
                        showProductSwitch: false,
                        headerSearchVariant: HeaderSearchVariant.DEFAULT,
                    },
                };
            }
            return this.config.dedicated;
        } else if (this.utilsService.getRegexPatternMatch([managedOfficeUrlRegex.allUrls], url) >= 0) {
            if (this.utilsService.getRegexPatternMatch([managedOfficeUrlRegex.listingPage], url) >= 0) {
                return {
                    ...this.config.managedOffice,
                    menuConfig: [menuEnum.CONTACT_US],
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                    },
                    showCityChange: false,
                    locationWorkspaceSearchConfig: this.getLocationWorkspaceSearchConfig(products.DEDICATED, city),
                };
            } else if (this.utilsService.getRegexPatternMatch([managedOfficeUrlRegex.spaceDetailPage], url) >= 0) {
                return {
                    ...this.config.managedOffice,
                    isAnimated: true,
                    showCityChange: false,
                    headerSearchConfig: {
                        showSearch: false,
                        showDesktopSearchInStickyOnly: true,
                        showProductSwitch: true,
                        headerSearchVariant: HeaderSearchVariant.DEFAULT,
                    },
                    menuConfigMobile: [menuEnum.PRODUCTS, menuEnum.CONTACT_US],
                };
            }
        } else if (this.utilsService.getRegexPatternMatch([commercialLeasingUrlRegex.allUrls], url) >= 0) {
            if (this.utilsService.getRegexPatternMatch([commercialLeasingUrlRegex.listingPage], url) >= 0) {
                return {
                    ...this.config.commercialLeasing,
                    menuConfig: [menuEnum.CONTACT_US],
                    menuConfigMobile: [...this.config.commercialLeasing.menuConfigMobile],
                    headerSearchConfig: {
                        showSearch: true,
                        showDesktopSearchInStickyOnly: false,
                        showProductSwitch: false,
                    },
                    showCityChange: false,
                    locationWorkspaceSearchConfig: this.getLocationWorkspaceSearchConfig(products.DEDICATED, city),
                };
            } else if (this.utilsService.getRegexPatternMatch([commercialLeasingUrlRegex.spaceDetailPage], url) >= 0) {
                return {
                    ...this.config.commercialLeasing,
                    isAnimated: true,
                    showCityChange: false,
                    headerSearchConfig: {
                        showSearch: false,
                        showDesktopSearchInStickyOnly: true,
                        showProductSwitch: false,
                        headerSearchVariant: HeaderSearchVariant.DEFAULT,
                    },
                };
            }
        } else if (url.startsWith('/lp')) {
            return this.config.landingPages;
        } else if (this.utilsService.getRegexPatternMatch(staticPagesURLRegex, url) >= 0) {
            return {
                ...this.config.default,
                showCityChange: false,
                headerSearchConfig: {
                    showSearch: false,
                    showDesktopSearchInStickyOnly: false,
                    showProductSwitch: false,
                },
            };
        } else if (this.utilsService.getRegexPatternMatch([urlRegex.homePageWithCitySlug], url) >= 0) {
            return this.config.home;
        } else {
            if (url.includes('/coworking-space/')) {
                return { ...this.config.default, isAnimated: false, showCityChange: false };
            }
            return this.config.default;
        }
    }

    getMenuData() {
        return this.menuSubject.asObservable();
    }
    setMenuData(menuData) {
        this.menuSubject.next(menuData);
    }

    getPopularWorkspacesForCity(cityId, product) {
        if (cityId !== this.popularWorkspacesByCity.cityId || product !== this.popularWorkspacesByCity.product) {
            let url = `${environment.baseUrl}/locations/city/${cityId}/popular-area/popular-workspace`;
            if (product) {
                url += `?products=${product.productNameBackend}`;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.http.get<IHttpResponse<any>>(url).pipe(
                map(res => {
                    if (res && res.success && res.data) {
                        this.popularWorkspacesByCity.cityId = cityId;
                        this.popularWorkspacesByCity.product = product;
                        if (!res.data.onlyWorkspaces) {
                            res.data.localities = res.data.localities.map((locality, index) => {
                                return { ...locality, isSelected: index === 0 };
                            });
                        }
                        this.popularWorkspacesByCity.data = res.data;
                        return this.popularWorkspacesByCity.data;
                    } else {
                        console.warn('something went wrong in popular location API', cityId);
                        return [];
                    }
                }),
                catchError(this.errorService.handleError)
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new Observable<any>(subscriber => {
                subscriber.next(this.popularWorkspacesByCity.data);
            });
        }
    }

    getLocationWorkspaceSearchConfig(
        product: products,
        city?: ICity,
        searchType: LocationWorkspaceSearchSuggestionType = LocationWorkspaceSearchSuggestionType.LOCATION_AND_WORKSPACE
    ): LocationWorkspaceSearchConfig {
        if (city) {
            let placeholder = '';
            switch (searchType) {
                case LocationWorkspaceSearchSuggestionType.LOCATION: {
                    placeholder = `Search location in ${city.name}`;
                    break;
                }
                case LocationWorkspaceSearchSuggestionType.WORKSPACE: {
                    if (product === products.MEETING_ROOM) {
                        placeholder = `Search for meeting rooms in ${city.name}`;
                    } else {
                        placeholder = `Search for workspaces in ${city.name}`;
                    }
                    break;
                }
                case LocationWorkspaceSearchSuggestionType.LOCATION_AND_WORKSPACE: {
                    placeholder = `Search location or workspaces in ${city.name}`;
                    break;
                }
            }
            return {
                ...this.locationWorkspaceSearchConfig,
                searchSuggestionType: searchType,
                typeaheadConfig: {
                    ...this.locationWorkspaceSearchConfig.typeaheadConfig,
                    placeholder,
                },
            };
        }
        return this.locationWorkspaceSearchConfig;
    }

    getVOLocationWorkspaceSearchConfig(city?: ICity): LocationWorkspaceSearchConfig {
        if (city) {
            return {
                ...this.locationWorkspaceSearchConfig,
                typeaheadConfig: {
                    ...this.locationWorkspaceSearchConfig.typeaheadConfig,
                    placeholder: `Search workspaces in ${city.name}`,
                },
                searchSuggestionType: LocationWorkspaceSearchSuggestionType.WORKSPACE,
            };
        }
        return {
            ...this.locationWorkspaceSearchConfig,
            typeaheadConfig: {
                ...this.locationWorkspaceSearchConfig.typeaheadConfig,
                placeholder: 'Search workspaces',
            },
            searchSuggestionType: LocationWorkspaceSearchSuggestionType.WORKSPACE,
        };
    }

    renderVoAuthModal(url) {
        if (!url) {
            return false;
        }
        return this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.allUrls], url) >= 0;
    }

    renderMobileAuthModal(url) {
        if (!url) {
            return false;
        }
        return this.utilsService.getRegexPatternMatch([meetingRoomsRegex.allUrls, flexiUrlRegex.allUrls], url) >= 0;
    }
}
