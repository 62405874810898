<div class="lead-success">
    <div class="image-cont d-flx">
        <img class="object-fit-contain" [src]="CommonIconsUrl.SUCCESS" alt="i" />
    </div>
    <div class="heading-cont d-flx">
        <h1>{{ config?.heading }}</h1>
    </div>
    @if (config?.description) {
        <div class="desc-cont d-flx">
            <p [innerHTML]="config?.description"></p>
        </div>
    }
    @if (config?.showContactDetails) {
        <div class="contact d-flx">
            <p>
                Meanwhile, if you have any questions, please write to us at
                <a [href]="ContactDetails.EMAIL.href" (click)="successAction(formSuccessActionType.EMAIL)">{{
                    ContactDetails.EMAIL.label
                }}</a>
                or call us on
                <a [href]="ContactDetails.PHONE_NUMBER.href" (click)="successAction(formSuccessActionType.CALL)">{{
                    ContactDetails.PHONE_NUMBER.label
                }}</a>
            </p>
        </div>
    }
    @if (template) {
        <div class="template-cont">
            <ng-container *ngTemplateOutlet="template"></ng-container>
        </div>
    }
</div>
