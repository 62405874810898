import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { productKeys, products, productsMap } from '../../../shared/enums/enums';

@Injectable({
    providedIn: 'root',
})
export class UrlUtilsService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private queryParams: Record<string, any>;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.queryParams.subscribe(paramsData => {
            this.queryParams = paramsData;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateUrl(url?, queryParamsObj?, options: any = {}) {
        if (url && queryParamsObj) {
            this.router.navigate([url], { queryParams: queryParamsObj, ...options });
        } else if (url) {
            this.router.navigate([url], { ...options });
        } else if (queryParamsObj) {
            this.router.navigate([], { queryParams: queryParamsObj, ...options });
        }
    }

    public getAbsoluteUrl(redirectUrl: string) {
        return `${environment.SERVER_URL}${redirectUrl}`;
    }

    interpolateParams(str, params) {
        return str.replace(/%{(\w+)}/g, (match, paramKey) => {
            if (Object.keys(params).includes(paramKey)) {
                return params[paramKey];
            } else {
                console.warn(`params object ${JSON.stringify(params)} do not contain expected keys for string: ${str}`);
                return `%{${paramKey}}`;
            }
        });
    }

    getUrlWithUpdateCity(product, citySlug) {
        let updatedUrl = '';
        switch(product) {
            case productKeys.FLEXI: 
            case products.INDIVIDUAL: {
                updatedUrl = `/${citySlug}/${productsMap.FLEXI.productBaseUrl}/coworking-space-in-${citySlug}`;
                break;
            }
            case productKeys.MEETING_ROOM: {
                updatedUrl = `/${citySlug}/${productsMap.MEETING_ROOM.productBaseUrl}/${citySlug}`;
                break;
            }
            case productKeys.DEDICATED: {
                updatedUrl = `/${citySlug}/${productsMap.DEDICATED.productBaseUrl}/coworking-space-in-${citySlug}`;
                break;
            }
            case productKeys.VIRTUAL_OFFICE: {
                updatedUrl = `/${citySlug}/${productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-${citySlug}`;
                break;
            }
            case productKeys.MANAGED_OFFICE: {
                updatedUrl = `/${citySlug}/managed-office/${citySlug}`;
                break;
            }
            case productKeys.COMMERCIAL_LEASING: {
                updatedUrl = `/${citySlug}/office-space-for-rent/${citySlug}`;
                break;
            }
        }
        return updatedUrl
    }

    public getCurrentUrl() {
        return `${environment.SERVER_URL}${this.router.url}`;
    }

    public getCurrentURLQueryParams() {
        return this.queryParams;
    }
}
