import {
    DropdownMenuItemType,
    IMenuWithDropdownConfig,
} from './components/menu-with-dropdown/menu-with-dropdown.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';
import { of } from 'rxjs';
import { menuCalloutType, menuEnum, menuType } from './enums/enums';
import {
    getMasterCategories,
    getSubCategories,
} from '../../../modules/knowledge-center/utils/get-knowledge-center-categories';
import { IExpandableLinks } from './common-header.interface';
import { knowledgeCenterData } from '../../../modules/knowledge-center/knowledge-center-data';

export const moreMenuItem: IMenuWithDropdownConfig = {
    id: menuEnum.MORE,
    title: 'More',
    type: menuType.DROPDOWN,
    show: false,
    calloutType: menuCalloutType.DOWNLOAD_APP,
    collapsed: false,
    subMenus: [
        {
            id: menuEnum.GUIDES_STUDIES,
            title: 'Guides & Toolkits',
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/action_key.svg`,
            collapsed: true,
            menuItems: [
                {
                    id: menuEnum.GUIDE_FOR_OFFICE_SPACE,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/checklist.svg`,
                    title: 'Guide for Office Space',
                    redirectUrl: of(`/resources/office-space-guide/office-space-planning`),
                    isExternalUrl: false,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    id: menuEnum.OFFICE_SPACE_CALCULATOR,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/calculator.svg`,
                    title: 'Office Space Calculator',
                    redirectUrl: of(`/office-space-calculator`),
                    isExternalUrl: false,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    id: menuEnum.COMPANY_NAME_SEARCH,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/action_key.svg`,
                    title: 'Company Name Search',
                    redirectUrl: of('guides/company-name-search'),
                    isExternalUrl: true,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    type: DropdownMenuItemType.ACCORDION,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/bookmark-guide.svg`,
                    title: 'Virtual Office Guides',
                    items: [
                        {
                            title: 'Company Registration Guide',
                            redirectUrl: of(`guides/company-registration-in-india`),
                        },
                        {
                            title: 'GST Registration Guide',
                            redirectUrl: of('guides/gst-registration-in-india'),
                        },
                        {
                            title: 'Virtual Place of Business (VPOB)',
                            redirectUrl: of('guides/virtual-place-of-business-registration'),
                        },
                        {
                            title: 'NGO/NPO Registration Guide',
                            redirectUrl: of('guides/ngo-registration-guide-virtual-office'),
                        },
                        {
                            title: 'Change in address for Company Registration',
                            redirectUrl: of('guides/change-company-address'),
                        },
                        {
                            title: 'Change in address for GST Registration',
                            redirectUrl: of('guides/change-gst-address'),
                        },
                        {
                            title: 'GST for Freelancers',
                            redirectUrl: of('guides/gst-for-freelancers'),
                        },
                        {
                            title: 'RERA Registration Guide',
                            redirectUrl: of('guides/rera-registration'),
                        },
                        {
                            title: 'MSME Registration Guide',
                            redirectUrl: of('guides/msme-registration'),
                        },
                    ],
                },
            ],
        },
        {
            id: menuEnum.SUCCESS_STORIES,
            title: 'Insights & Success Stories',
            iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/reviews.svg`,
            collapsed: true,
            menuItems: [
                {
                    id: menuEnum.TESTIMONIALS,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/reviews.svg`,
                    title: 'Testimonials',
                    description: 'Hear from our clients',
                    redirectUrl: of(`/testimonials`),
                    isExternalUrl: false,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    id: menuEnum.CASE_STUDIES,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/case_studies.svg`,
                    title: 'Case Studies',
                    description: 'Client success stories of real impact',
                    redirectUrl: of(`/case-studies`),
                    isExternalUrl: false,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    id: menuEnum.INDUSTRY_REPORTS,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/monitoring.svg`,
                    title: 'Industry Reports',
                    description: 'Discover insights, analysis, and trends',
                    redirectUrl: of('/industry-reports'),
                    isExternalUrl: false,
                    showTooltipInsteadOfDescription: true,
                },
                {
                    id: menuEnum.BLOG,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/contract_edit.svg`,
                    title: 'myHQ Digest',
                    description: 'Visit our blog',
                    redirectUrl: of(`https://digest.myhq.in/`),
                    isExternalUrl: true,
                    showTooltipInsteadOfDescription: true,
                },
            ],
        },
    ],
};

function generateKnowledgeCenterExpandableLinks() {
    const masterCategories = getMasterCategories();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const knowledgeCenter: any = knowledgeCenterData;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const expandableLinks: IExpandableLinks[] = masterCategories.map((masterCategory, index) => {
        const subCategories = getSubCategories(masterCategory);
        const items = subCategories.map(subCategory => ({
            id: subCategory,
            type: menuType.LINK,
            label: knowledgeCenter[masterCategory][subCategory]?.title || subCategory,
            show: true,
            link: `/resources/office-space-guide/${masterCategory}/${subCategory}`,
        }));

        return {
            id: masterCategory,
            type: menuType.ACCORDION,
            label: knowledgeCenter[masterCategory]?.title || masterCategory,
            show: true,
            isOpen: false,
            link: `/resources/office-space-guide/${masterCategory}`,
            items,
        } as IExpandableLinks;
    });

    return expandableLinks;
}

export const guideForOfficeSpace: IMenuWithDropdownConfig = {
    id: menuEnum.GUIDE_FOR_OFFICE_SPACE,
    type: menuType.EXPANDABLE_LINKS_PANEL,
    title: 'Guide for Office Space',
    description: 'Plan, Find & Own Office Space',
    iconUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/checklist.svg`,
    show: false,
    expandableLinks: generateKnowledgeCenterExpandableLinks(),
};
