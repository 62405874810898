import { Component, Input, TemplateRef, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { UtilsService } from '../../../core/services/utils/utils.service';

@Component({
    selector: 'app-modal',
    template: ``,
})
export class AppModalComponent implements OnDestroy {
    modalRef: BsModalRef;
    @Input() cssClass: string;
    @Input() template: TemplateRef<ElementRef>;
    @Input() config: ModalOptions;
    @Input('open') set updateState(isOpen: boolean) {
        if (isOpen) {
            this.openModal();
        } else {
            this.closeModal();
        }
    }

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onHidden = new EventEmitter();

    constructor(
        private modalService: BsModalService,
        private utilsService: UtilsService
    ) {}

    ngOnDestroy() {
        this.closeModal();
    }

    openModal() {
        if (this.utilsService.isBrowser) {
            this.modalRef = this.modalService.show(this.template, {
                class: this.cssClass,
                animated: false,
                ...this.config,
            });

            this.modalRef.onHidden.pipe(take(1)).subscribe(() => this.onHidden.emit());
        }
    }

    closeModal() {
        if (this.utilsService.isBrowser) {
            this.modalRef?.hide();
        }
    }
}
