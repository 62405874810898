import { Component, Input } from '@angular/core';
import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { IImage } from '../../interfaces/common.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';
import { environment } from '../../../../environments/environment';

@Component({
    standalone: true,
    selector: 'app-optimized-image',
    templateUrl: './optimized-image.component.html',
    styleUrls: ['./optimized-image.component.scss'],
    imports: [CommonModule, NgOptimizedImage],
    providers: [
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                if (environment.disableCloudinary) {
                    // we are serving local image from here
                    return '/assets/web/ssr/common/assets/qa-image.jpeg';
                }
                const { src, width } = config;
                const finalWidth = width || 1920;

                if (src && src.startsWith(ImageBaseUrl.CLOUDFRONT_URL)) {
                    return `${src}?width=${finalWidth}`;
                }

                if (src && src.startsWith(ImageBaseUrl.IMAGE_URL_BASE)) {
                    const indexOfBaseUrlEnd = ImageBaseUrl.IMAGE_URL_BASE.length;
                    const imageUrlWithoutBase = src.slice(indexOfBaseUrlEnd);
                    return width
                        ? `${ImageBaseUrl.IMAGE_URL_BASE}/w_${finalWidth}/f_auto${imageUrlWithoutBase}`
                        : `${ImageBaseUrl.IMAGE_URL_BASE}/q_auto/w_${finalWidth}/f_auto${imageUrlWithoutBase}`;
                }
                if (!src.startsWith('http')) {
                    // attempt to provide a cloudinary loader in case of relative URL
                    return width
                        ? `${ImageBaseUrl.CLOUDINARY_URL}w_${finalWidth}/f_auto/${src}`
                        : `${ImageBaseUrl.CLOUDINARY_URL}q_auto/w_${finalWidth}/f_auto/${src}`;
                }
                return src;
            },
        },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 100,
                breakpoints: [24, 48, 128, 384, 750, 1080, 1920],
            },
        },
    ],
})
export class OptimizedImageComponent {
    @Input({ required: true }) image: IImage;
    @Input() priority: boolean | undefined;
    @Input() fill: boolean | undefined;
    @Input() height: number | undefined;
    @Input() width: number | undefined;
    @Input() sizes: string | undefined;
    @Input() cssClass: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() styles: Record<string, any> | undefined;
    @Input() placeholder: string | boolean;
}
