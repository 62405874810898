import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RegexMatcherService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexEmail: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexName: any = /^[a-zA-Z\s]{4,}$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexPhone: any = /^[0]?[6-9][0-9]{9}$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexInternationalPhone: any = /^\d{6,14}$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexOTPItem: any = /^[0-9]{1}$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexGst: any = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regexPassword: any = /.{4,}/;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    getRegexEmail() {
        return this.regexEmail;
    }

    getRegexName() {
        return this.regexName;
    }

    getRegexPhone() {
        return this.regexPhone;
    }
    getInternationalRegexPhone() {
        return this.regexInternationalPhone;
    }

    getRegexGst() {
        return this.regexGst;
    }
    getRegexOTPItem() {
        return this.regexOTPItem;
    }

    getRegexPassword() {
        return this.regexPassword;
    }
}
