import { Directive, ElementRef, Renderer2, OnInit, inject } from '@angular/core';
import { EMPTY } from 'rxjs';
import { map, pairwise, distinctUntilChanged } from 'rxjs/operators';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { PrefixBannerService } from '../../prefix-banner/prefix-banner.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { effect } from '@angular/core';
enum Direction {
    Up = 'Up',
    Down = 'Down',
}

const SCROLL_DOWN_OFFSET_PX = -80;

@Directive({
    standalone: true,
    selector: '[appReactiveStickyHeader]',
})
export class ReactiveStickyHeaderDirective implements OnInit {
    private readonly el: ElementRef<HTMLElement> = inject(ElementRef);
    private readonly renderer = inject(Renderer2);
    private readonly utilsService = inject(UtilsService);
    private readonly prefixBannerService = inject(PrefixBannerService);

    private deviceInfo = this.utilsService.getDeviceInfo();

    constructor() {
        effect(() => {
            this.updateHeaderPosition(this.scrollDirection(), this.latestBannerHeight());
        });
    }

    ngOnInit(): void {
        if (!this.deviceInfo.isBrowser) return;

        this.renderer.setStyle(this.el.nativeElement, 'position', 'sticky');
        this.renderer.setStyle(this.el.nativeElement, 'transition', 'top 0.3s ease-in-out');
        this.renderer.setStyle(this.el.nativeElement, 'top', '0px');
    }

    private latestBannerHeight = toSignal(this.prefixBannerService.getStickyPrefixBannerHeight());
    private scrollDirection = toSignal(
        this.deviceInfo.isBrowser
            ? this.utilsService.getScroll().throttledScroll$.pipe(
                  map(y => y ?? 0), // Ensure we have a number, default to 0 if undefined/null
                  pairwise(), // Compare previous and current scroll position
                  map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
                  distinctUntilChanged() // Only emit when the direction changes
              )
            : EMPTY
    );

    private updateHeaderPosition(direction: Direction, bannerHeight: number): void {
        let topOffset = 0;

        switch (direction) {
            case Direction.Up:
                topOffset = bannerHeight;
                break;
            case Direction.Down:
                topOffset = SCROLL_DOWN_OFFSET_PX;
                break;
        }

        // Apply the style using Renderer2
        this.renderer.setStyle(this.el.nativeElement, 'top', `${topOffset}px`);
    }
}
