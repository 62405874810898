import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ICity, ISelectCity } from '../../interfaces/location.interface';
import { LocationService } from '../location/location.service';
import { StorageService } from '../storage/storage.service';
import { cityChangeSource } from '../../enums/enums';
import { map, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class SelectCityService {
    cityChangeSource: cityChangeSource;
    selectedCity: BehaviorSubject<ICity> = new BehaviorSubject<ICity>(null);
    selectCitySync: BehaviorSubject<ISelectCity> = new BehaviorSubject<ISelectCity>({ city: null, source: null });

    citySlugCookieKey = 'selectedCitySlug';
    setCityOperationStatus: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    setCityOperationBegin() {
        this.setCityOperationStatus.next(this.setCityOperationStatus.value + 1);
    }
    setCityOperationEnd() {
        this.setCityOperationStatus.next(this.setCityOperationStatus.value - 1);
    }

    setCityChangeSource(source: cityChangeSource) {
        this.cityChangeSource = source;
    }

    constructor(
        private locationService: LocationService,
        private storageService: StorageService,
        private analyticsService: AnalyticsService
    ) {
        combineLatest([this.selectedCity, this.setCityOperationStatus]).subscribe(([selectCityObj, setCityStatus]) => {
            if (setCityStatus === 0) {
                this.selectCitySync.next({ city: selectCityObj, source: this.cityChangeSource });
            } else {
                this.selectCitySync.next({ city: null, source: this.cityChangeSource });
            }
        });
    }

    // TODO:: Move this aquire lock functinality to decorator
    setSelectedCity(city?: ICity, source?: cityChangeSource) {
        this.setCityOperationBegin();
        this.setCityChangeSource(source);
        this.selectedCity.next(city);
        this.storageService.setCookie(this.citySlugCookieKey, city.slug, null, '/');
        this.setCityOperationEnd();
    }

    getSelectedCitySync(): Observable<ISelectCity> {
        return this.selectCitySync.asObservable();
    }

    private getCityObjFromSlug(citySlug: string): Observable<ICity> {
        return this.locationService.getAvailableCity().pipe(
            take(1),
            map(data => {
                let cityObj: ICity;
                if (data) {
                    cityObj = data.cities.find((city: ICity) => {
                        return city.slug === citySlug;
                    });
                }
                if (cityObj) {
                    return cityObj;
                }
            })
        );
    }

    async setCityFromSlug(citySlug: string, source?: cityChangeSource): Promise<boolean> {
        this.setCityOperationBegin();

        return new Promise<boolean>(resolve => {
            this.getCityObjFromSlug(citySlug).subscribe(cityObj => {
                const cityFound = !!cityObj;

                if (cityFound) {
                    this.setSelectedCity(cityObj, source);
                    this.analyticsService.trackCityOfUser(cityObj);
                }

                this.setCityOperationEnd();
                resolve(cityFound);
            });
        });
    }

    setCityFromId(cityId: string) {
        // TODO :: please move this to getcityFrom slug and ask to change in backend
        this.locationService.getAvailableCity().subscribe(data => {
            let cityObj;
            if (data) {
                cityObj = data.cities.find((city: ICity) => {
                    return city._id === cityId;
                });
            }
            if (cityObj) {
                this.setSelectedCity(cityObj);
            }
        });
    }

    // Can Remove on 31st November, 2020
    /**
     * @deprecated_after_2020_11_31 migration code
     */
    private migrationCityObjToSlugInCookies() {
        const cityCookieKey = 'selectedCityObj';
        const cityObj: ICity = this.storageService.getCookie(cityCookieKey);
        this.storageService.removeCookie(cityCookieKey);
        if (cityObj) {
            return cityObj.slug;
        }
    }

    setInitCity() {
        let citySlug = this.storageService.getCookie(this.citySlugCookieKey);

        // Can Remove on 31st November, 2020
        if (!citySlug) {
            citySlug = this.migrationCityObjToSlugInCookies();
        }

        if (citySlug) {
            this.setCityFromSlug(citySlug, cityChangeSource.COOKIE);
        }
    }
}
