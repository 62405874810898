import {
    AfterContentChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    buttonType,
    CommonFilters,
    fontSizes,
    fontWeights,
    formFieldType,
    formPosition,
    formStages,
    formSuccessActionType,
    productsMap,
} from '../../enums/enums';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { FormfieldService } from '../../../core/services/formFields/formfield.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { VirtualOfficeFormService } from '../../services/virtual-office-form/virtual-office-form.service';
import { VirtualOfficeService } from '../../services/virtual-office.service';
import { LocationService } from '../../services/location/location.service';
import { HeaderService } from '../../component-modules/common-header/services/header.service';
import { BehaviorSubject } from 'rxjs';
import { IProductMap } from '../../interfaces/product-interface';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { IFormsConfig, IFormsType } from '../../ui-components/forms/forms.component';
import { IVOFormConfig, VOPageSource } from './interfaces/interface';
import { VirtualOfficeFormSuccessType, VirtualOfficeFormType } from './enums/enum';
import { IFormHeaderConfig } from '../../ui-components/form-header/form-header.interface';
import { ICity } from '../../interfaces/location.interface';
import {
    CompanyNameSearchEvents,
    CompanyRegistrationEvents,
    GstRegistrationEvents,
    VirtualOfficeEvents,
    VirtualOfficeLFormFunnelStages,
    CompareWorkspaceEvents,
    VOSpaceDetailEvents,
    VOSpaceListingEvents,
    VPOBEvents,
    NGOEvents,
    ChangeCompanyAddressEvents,
    ChangeGstAddressEvents,
    GstForFreelancersEvents,
    ReraRegistrationEvents,
    MsmeRegistrationEvents,
} from '../../../core/services/analytics/enums';
import { Router } from '@angular/router';
import { IVOWorkspace } from '../virtual-office-listing-card/virtual-office-listing-card.interface';
import { UrlUtilsService } from '../../../core/services/utils/url-utils.service';
import { IFieldConfig, ISingleSelectDropdownFieldConfig } from '../../interfaces/field.interface';
import { StorageService } from '../../services/storage/storage.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../snackbar/services/snackbar.service';
import { SnackbarType } from '../snackbar/snackbar.enum';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '../../ui-components/buttons/buttons.module';
import { AppModalModule } from '../../ui-components/app-modal/app-modal.module';
import { LinkWithIconComponent } from '../../ui-components/link-with-icon/link-with-icon.component';
import { FormsModule } from '../../ui-components/forms/forms.module';
import { ModalFormContainerComponent } from '../../ui-components/app-modal/components/modal-form-container/modal-form-container.component';
import { LeadSuccessModule } from '../lead-success/lead-success.module';
import { LeadFormSideBannerComponent } from '../../app-components/form/lead-form-side-banner/lead-form-side-banner.component';
import { DirectivesModule } from '../../directives/directives.module';
import { ILeadFormSideBannerConfig } from '../../app-components/form/lead-form-side-banner/lead-form-side-banner.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';

@Component({
    standalone: true,
    selector: 'app-virtual-office-form',
    templateUrl: './virtual-office-form.component.html',
    styleUrls: ['./virtual-office-form.component.scss'],
    imports: [
        CommonModule,
        ButtonsModule,
        AppModalModule,
        LinkWithIconComponent,
        FormsModule,
        ModalFormContainerComponent,
        LeadSuccessModule,
        LeadFormSideBannerComponent,
        DirectivesModule,
    ],
    providers: [VirtualOfficeFormService],
})
export class VirtualOfficeFormComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
    @Input('open') set setIsOpen(isOpen: boolean) {
        this.formModal = {
            ...this.formModal,
            show: isOpen,
        };

        if (this.config) {
            if (isOpen) {
                this.analyticsService.trackVirtualOfficeFormOpen(this.getFormOpenEventName(), {
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                });
            }
        }
    }

    @Input('city') set setCity(updatedCity: ICity) {
        this.city = updatedCity;
        this.cityObj = updatedCity; // TODO : known issue - navigate back will update the actual selection that was made last time
    }
    @Input('formFields') set setFormFields(fields: IFieldConfig[]) {
        this.formConfig = {
            ...this.formConfig,
            fields: [...fields],
        };
        this.stickyFooterFormConfig = {
            ...this.stickyFooterFormConfig,
            fields: [...fields],
        };
        this.formConfig = this.addCityOptionsAndDefaultValue(this.formConfig, this.availableCities);
        this.stickyFooterFormConfig = this.addCityOptionsAndDefaultValue(
            this.stickyFooterFormConfig,
            this.availableCities
        );
    }
    constructor(
        private virtualOfficeFormService: VirtualOfficeFormService,
        private formfieldService: FormfieldService,
        private virtualOfficeService: VirtualOfficeService,
        private analyticsService: AnalyticsService,
        private locationService: LocationService,
        private headerService: HeaderService,
        private utilsService: UtilsService,
        private router: Router,
        private urlUtilsService: UrlUtilsService,
        private storageService: StorageService,
        private clipboard: Clipboard,
        private snackbarService: SnackbarService
    ) {}
    @Input('config') set updateConfig(updatedConfig: IVOFormConfig) {
        this.config = { ...updatedConfig };

        //  Show feature list on desktop based on config
        // No show on mobile
        if (this.config && this.config.formSource === formPosition.BOTTOM) {
            this.updateFormHeaderConfigWithFeaturesList();
        }

        if (updatedConfig.formData) {
            const { formData } = updatedConfig;

            if (formData.cityDetail) {
                this.cityObj = formData.cityDetail;
                this.updateButtonConfig(this.cityObj);
            }
            if (formData.voWorkspace) {
                this.voWorkspace = formData.voWorkspace;
                if (formData.voWorkspace.redirectUrl) {
                    this.buttonConfig.linkUrl = formData.voWorkspace.redirectUrl;
                }
            }
            if (this.deviceInfo.isMobile) {
                this.formHeaderConfig = {
                    ...this.formHeaderConfig,
                    heading: "Let's get in touch?",
                    description: formData.heading,
                };
            } else {
                this.formHeaderConfig = {
                    ...this.formHeaderConfig,
                    heading: formData.heading ?? this.formHeaderConfig.heading,
                    description: formData.description ?? this.formHeaderConfig.description,
                };
            }

            if (formData?.editData) {
                this.formConfig = {
                    ...this.formConfig,
                    editData: formData?.editData,
                };
            }

            if (formData?.ctaText || formData?.ctaIcon) {
                this.formConfig = {
                    ...this.formConfig,
                    ctas: [
                        {
                            ...this.formConfig.ctas[0],
                            label: formData?.ctaText ?? 'Request Callback',
                            icon: formData?.ctaIcon ?? '',
                            iconPosition: 'left',
                        },
                    ],
                };
            }
        }

        if (updatedConfig?.successFormConfig) {
            this.formSuccessConfig = {
                ...this.formSuccessConfig,
                ...updatedConfig?.successFormConfig,
                heading: updatedConfig.successFormConfig.heading ?? this.initialSuccessConfig.heading,
                description: updatedConfig.successFormConfig.description ?? this.initialSuccessConfig.description,
                showContactDetails:
                    updatedConfig.successFormConfig.showContactDetails ?? this.initialSuccessConfig.showContactDetails,
            };
            this.buttonConfig = {
                ...this.buttonConfig,
                linkUrl: updatedConfig.successFormConfig.hideCTA ? null : this.buttonConfig.linkUrl,
                label: updatedConfig.successFormConfig.ctaLabel ?? this.buttonConfig.label,
            };
        } else {
            this.formSuccessConfig = {
                ...this.initialSuccessConfig,
            };
        }
    }
    deviceInfo = this.utilsService.getDeviceInfo();
    product: IProductMap = productsMap.VIRTUAL_OFFICE;
    voWorkspace: IVOWorkspace;
    city: ICity;
    formConfig: IFormsConfig = {
        fields: [],
        ctas: [{ ...this.formfieldService.getBtnConfig(), cssClass: '' }],
    };
    stickyFooterFormConfig: IFormsConfig = {
        fields: [],
        ctas: [{ ...this.formfieldService.getBtnConfig(), cssClass: '' }],
        formType: IFormsType.INLINE_FORM,
    };
    open: boolean;
    leadFormSideBannerConfig: ILeadFormSideBannerConfig = {
        heading: 'Trusted by 10,000+ Virtual Office Clients',
        featuresTitle: 'India’s Leading Virtual Office Provider',
        clientsLogo: {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/banners/v2-modal.png`,
            alt: 'banner',
        },
        features: [
            'Lowest Price Guarantee',
            '1 hr Average Response Time',
            'Fastest Document Turnaround Time',
            'Digital KYC and Agreement',
            'Money Back Guarantee',
        ],
    };

    @Input() className?: string;

    @Output() formModalState = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCopyCode = new EventEmitter();

    config: IVOFormConfig;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('primaryInfoTemplate') primaryInfoTemplate: TemplateRef<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('successTemplate') successTemplate: TemplateRef<any>;
    showStickyForm$ = new BehaviorSubject<boolean>(false);
    scrollSubscription;
    virtualOfficeFormType = VirtualOfficeFormType;
    // TODO not super clear how this cityObj is different from the @Input city (i.e city with which the page is open vs city chosen in form)
    cityObj;
    popularCitySub;
    availableCities = [];
    formComplete$ = new BehaviorSubject(false);
    form: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        template: TemplateRef<any>;
    };
    buttonTypeEnum = buttonType;
    fontSizesEnum = fontSizes;
    fontWeightsEnum = fontWeights;
    formSuccessActionType = formSuccessActionType;
    CommonIconsUrl = CommonIconsUrl;

    buttonConfig = {
        label: `View options`,
        linkUrl: `https://virtualoffice.myhq.in/collections/virtual-office-in-india`,
        desc: `Meanwhile, you can explore Virtual Office options in India`,
    };

    formHeaderConfig: IFormHeaderConfig = {
        heading: "Let's get in touch?",
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formModal: any = {
        show: false,
        cssClass: 'full-screen',
    };

    formError = { visible: false, content: 'An Error has Occurred. Please Try Again' };

    initialSuccessConfig: IVOFormConfig['successFormConfig'] = {
        type: VirtualOfficeFormSuccessType.DEFAULT,
        heading: 'Great!',
        description: 'We’ve got all your information. One of our experts will get in touch with you within 24 hours.',
        showContactDetails: false,
    };
    formSuccessConfig: IVOFormConfig['successFormConfig'] = this.initialSuccessConfig;

    menuEventSub = this.headerService.getMenuData().subscribe(data => {
        if (data === 'REQUEST_CALLBACK') {
            this.toggleModal(true);
        }
    });

    protected readonly VirtualOfficeFormSuccessType = VirtualOfficeFormSuccessType;

    ngOnInit() {
        if (this.config && this.config.showCityDropdown) {
            this.popularCitySub = this.locationService
                .getPopularCitiesFromProduct(productsMap.VIRTUAL_OFFICE.productNameBackend)
                .subscribe(cities => {
                    this.availableCities = cities.cities;
                    this.formConfig = this.addCityOptionsAndDefaultValue(this.formConfig, this.availableCities);
                    this.stickyFooterFormConfig = this.addCityOptionsAndDefaultValue(
                        this.stickyFooterFormConfig,
                        this.availableCities
                    );
                });
        }
    }

    ngAfterViewInit() {
        // If its sticky form subscribe to the scroll event for display classes
        if (this.config?.formType === VirtualOfficeFormType.STICKY_FORM) {
            this.subscribeToScrollEvent();
        }
    }

    ngAfterContentChecked() {
        this.virtualOfficeFormService.formStageSubject.subscribe(stage => {
            switch (stage) {
                case formStages.STAGE_1:
                    this.form = {
                        template: this.primaryInfoTemplate,
                    };
                    break;
                case formStages.STAGE_2:
                    this.formComplete$.next(true);
                    this.form = {
                        template: this.successTemplate,
                    };
                    break;
                default:
            }
        });
    }

    updateFormHeaderConfigWithFeaturesList() {
        this.formHeaderConfig = {
            ...this.formHeaderConfig,
            list: [
                {
                    description: 'Lowest Price Guarantee',
                    svgLogo: CommonIconsUrl.TICK_MARK_ICON,
                },
                {
                    description: '1 hr Average Response Time',
                    svgLogo: CommonIconsUrl.TICK_MARK_ICON,
                },
            ],
        };
    }

    addCityOptionsAndDefaultValue(formConfig: IFormsConfig, cities: ICity[]) {
        const newFormConfig = { ...formConfig };
        const newFields = [...newFormConfig.fields];
        const formFieldIndex = newFields.findIndex(field => field.fieldType === formFieldType.SINGLE_SELECT_DROPDOWN);
        if (formFieldIndex !== -1) {
            newFields[formFieldIndex] = {
                ...newFields[formFieldIndex],
                options: cities?.map(option => ({
                    label: `${option.name}, ${option.state.name}`,
                    value: option._id,
                })),
                default: this.config?.formData?.cityDetail?._id,
            } as ISingleSelectDropdownFieldConfig;
        }
        return { ...newFormConfig, fields: newFields };
    }

    createLead(response) {
        let payload;
        if (!this.config || (this.config && !this.config.showCityDropdown)) {
            payload = { ...response.formData, cityId: this.cityObj._id };
        } else {
            payload = { ...response.formData };
            this.availableCities.forEach(city => {
                if (city._id === response.formData.cityId) {
                    this.cityObj = city;
                }
            });
            this.updateButtonConfig(this.cityObj);
        }
        if (this.config.pageSource === VOPageSource.LISTING_PAGE) {
            const { selectedPlan, workspaceSlug } = this.getFormSubmitEventPayload(payload, true);
            payload = { ...payload, selectedPlan, workspaceSlug, localitySlug: this.voWorkspace?.location };
        }
        let success;
        if (response.formData) {
            this.formConfig.ctas[0].showLoader = true;
            payload = { ...payload, formSource: this.config?.formSource };
            this.virtualOfficeFormService.createLead(payload).subscribe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                res => {
                    success = true;
                    this.formConfig.ctas[0].showLoader = false;
                    this.virtualOfficeFormService.setFormStage(formStages.STAGE_2);
                    if (this.config?.formData?.ctaLink) {
                        window.open(this.config.formData.ctaLink);
                    }
                    this.analyticsService.trackVirtualOfficeFormSubmit(
                        this.getFormSubmitEventName(),
                        this.getFormSubmitEventPayload(payload, success)
                    );
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error => {
                    success = false;
                    this.formConfig.ctas[0].showLoader = false;
                    this.formError.visible = true;
                    this.formError.content = 'An error occurred. Please try again';
                }
            );
        }
    }

    onStickyFormSubmit(event) {
        // Call the lead form ot carry on the flow
        this.createLead(event);
        // Start showing modal, hide sticky footer, and cancel the subscription
        this.toggleModal(true);
        this.showStickyForm$.next(false);
        this.scrollSubscription.unsubscribe();
    }

    onSuccessModalClose() {
        this.trackFormRedirectAnalytics(this.formSuccessActionType.CLOSE);
        this.toggleModal(false);
    }

    handleSuccessButtonClick() {
        if (this.config?.successFormConfig?.closeModal) {
            this.onSuccessModalClose();
        } else if (this.config?.successFormConfig?.ctaLink) {
            window.open(this.config.successFormConfig.ctaLink);
        } else {
            if (this.cityObj) {
                this.router.navigate([`/${this.cityObj.slug}/virtual-office/virtual-office-in-${this.cityObj.slug}`]);
            } else {
                this.router.navigate([`/virtual-office/virtual-office-in-`]);
            }
        }

        if (this.config.formSource === formPosition.HEADER) {
            // Store in session that lead has come from header
            this.storageService.setSession('vo-header-lead', true);
        }
        this.toggleModal(false);
    }

    updateButtonConfig(city) {
        if (city) {
            this.buttonConfig.label = `View options in ${city.name}`;
            this.buttonConfig.desc = `Meanwhile, you can explore Virtual Office options in ${city.name}`;
            this.buttonConfig.linkUrl = this.virtualOfficeService.getVirtualOfficeCityLink(city.slug);
        }
    }

    toggleModal(toggle: boolean) {
        // eslint-disable-next-line no-empty
        if (toggle) {
        } else {
            this.analyticsService.trackVirtualOfficeFormClose(
                this.getFormCloseEventName(),
                this.getFormCloseEventPayload()
            );
        }
        this.formModal.show = toggle;
        this.formModalState.next(this.formModal.show);
    }

    getFormOpenEventName() {
        switch (this.config.pageSource) {
            case VOPageSource.PRODUCT_DETAIL:
                return VirtualOfficeEvents.VO_PD_FORM_OPEN;
            case VOPageSource.LISTING_PAGE:
                // SPACE_LISTING_QUOTE_FORM_OPEN: Basically means the form is opened from the listing page
                return VOSpaceListingEvents.SL_QUOTE_FORM_OPEN;
            case VOPageSource.SPACE_DETAIL:
                return VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_OPEN;
            case VOPageSource.COMPARE_WORKSPACES:
                return CompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_OPEN;
            case VOPageSource.COMPANY_NAME_SEARCH:
                return CompanyNameSearchEvents.VO_COMPANY_NAME_LEAD_FORM_OPEN;
            case VOPageSource.COMPANY_REGISTRATION:
                return CompanyRegistrationEvents.VO_COMPANY_REGISTRATION_LEAD_FORM_OPEN;
            case VOPageSource.GST_REGISTRATION:
                return GstRegistrationEvents.VO_GST_REGISTRATION_LEAD_FORM_OPEN;
            case VOPageSource.VPOB:
                return VPOBEvents.VO_VPOB_LEAD_FORM_OPEN;
            case VOPageSource.NGO:
                return NGOEvents.VO_NGO_LEAD_FORM_OPEN;
            case VOPageSource.CHANGE_COMPANY_ADDRESS:
                return ChangeCompanyAddressEvents.VO_CHANGE_COMPANY_ADDRESS_LEAD_FORM_OPEN;
            case VOPageSource.CHANGE_GST_ADDRESS:
                return ChangeGstAddressEvents.VO_CHANGE_GST_ADDRESS_LEAD_FORM_OPEN;
            case VOPageSource.GST_FOR_FREELANCERS:
                return GstForFreelancersEvents.VO_GST_FOR_FREELANCERS_LEAD_FORM_OPEN;
            case VOPageSource.RERA_REGISTRATION:
                return ReraRegistrationEvents.VO_RERA_REGISTRATION_LEAD_FORM_OPEN;
            case VOPageSource.MSME_REGISTRATION:
                return MsmeRegistrationEvents.VO_MSME_REGISTRATION_LEAD_FORM_OPEN;
            default:
                return VirtualOfficeLFormFunnelStages.FORM_OPEN;
        }
    }

    getFormCloseEventName() {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
                return VirtualOfficeLFormFunnelStages.FORM_CLOSE;
            case VOPageSource.PRODUCT_DETAIL:
                return VirtualOfficeEvents.VO_PD_FORM_CLOSE;
            case VOPageSource.LISTING_PAGE:
                return VOSpaceListingEvents.SL_QUOTE_FORM_CLOSE;
            case VOPageSource.SPACE_DETAIL:
                return VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_CLOSE;
            case VOPageSource.COMPARE_WORKSPACES:
                return CompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_CLOSE;
            case VOPageSource.COMPANY_NAME_SEARCH:
                return CompanyNameSearchEvents.VO_COMPANY_NAME_LEAD_FORM_CLOSE;
            case VOPageSource.COMPANY_REGISTRATION:
                return CompanyRegistrationEvents.VO_COMPANY_REGISTRATION_LEAD_FORM_CLOSE;
            case VOPageSource.GST_REGISTRATION:
                return GstRegistrationEvents.VO_GST_REGISTRATION_LEAD_FORM_CLOSE;
            case VOPageSource.VPOB:
                return VPOBEvents.VO_VPOB_LEAD_FORM_CLOSE;
            case VOPageSource.NGO:
                return NGOEvents.VO_NGO_LEAD_FORM_CLOSE;
            case VOPageSource.CHANGE_COMPANY_ADDRESS:
                return ChangeCompanyAddressEvents.VO_CHANGE_COMPANY_ADDRESS_LEAD_FORM_CLOSE;
            case VOPageSource.CHANGE_GST_ADDRESS:
                return ChangeGstAddressEvents.VO_CHANGE_GST_ADDRESS_LEAD_FORM_CLOSE;
            case VOPageSource.GST_FOR_FREELANCERS:
                return GstForFreelancersEvents.VO_GST_FOR_FREELANCERS_LEAD_FORM_CLOSE;
            case VOPageSource.RERA_REGISTRATION:
                return ReraRegistrationEvents.VO_RERA_REGISTRATION_LEAD_FORM_CLOSE;
            case VOPageSource.MSME_REGISTRATION:
                return MsmeRegistrationEvents.VO_MSME_REGISTRATION_LEAD_FORM_CLOSE;
            default:
                return VirtualOfficeLFormFunnelStages.FORM_CLOSE;
        }
    }

    getFormSubmitEventName() {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
                return VirtualOfficeLFormFunnelStages.FORM_STEP1_SUBMIT;
            case VOPageSource.PRODUCT_DETAIL:
                return VirtualOfficeEvents.VO_PD_FORM_SUBMIT;
            case VOPageSource.LISTING_PAGE:
                return VOSpaceListingEvents.SL_QUOTE_FORM_SUBMIT;
            case VOPageSource.SPACE_DETAIL:
                return VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_SUBMIT;
            case VOPageSource.COMPARE_WORKSPACES:
                return CompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_SUBMIT;
            case VOPageSource.COMPANY_NAME_SEARCH:
                return CompanyNameSearchEvents.VO_COMPANY_NAME_LEAD_FORM_SUBMIT;
            case VOPageSource.COMPANY_REGISTRATION:
                return CompanyRegistrationEvents.VO_COMPANY_REGISTRATION_LEAD_FORM_SUBMIT;
            case VOPageSource.GST_REGISTRATION:
                return GstRegistrationEvents.VO_GST_REGISTRATION_LEAD_FORM_SUBMIT;
            case VOPageSource.VPOB:
                return VPOBEvents.VO_VPOB_LEAD_FORM_SUBMIT;
            case VOPageSource.CHANGE_COMPANY_ADDRESS:
                return ChangeCompanyAddressEvents.VO_CHANGE_COMPANY_ADDRESS_LEAD_FORM_SUBMIT;
            case VOPageSource.CHANGE_GST_ADDRESS:
                return ChangeGstAddressEvents.VO_CHANGE_GST_ADDRESS_LEAD_FORM_SUBMIT;
            case VOPageSource.GST_FOR_FREELANCERS:
                return GstForFreelancersEvents.VO_GST_FOR_FREELANCERS_LEAD_FORM_SUBMIT;
            case VOPageSource.RERA_REGISTRATION:
                return ReraRegistrationEvents.VO_RERA_REGISTRATION_LEAD_FORM_SUBMIT;
            case VOPageSource.MSME_REGISTRATION:
                return MsmeRegistrationEvents.VO_MSME_REGISTRATION_LEAD_FORM_SUBMIT;
            case VOPageSource.NGO:
                return NGOEvents.VO_NGO_LEAD_FORM_SUBMIT;
            default:
                return VirtualOfficeLFormFunnelStages.FORM_STEP1_SUBMIT;
        }
    }

    getFormCloseEventPayload() {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
            case VOPageSource.PRODUCT_DETAIL:
            case VOPageSource.SPACE_DETAIL:
                return {
                    productType: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
            case VOPageSource.LISTING_PAGE:
                return {
                    productType: this.product.productNameBackend,
                    spaceSlug: this.voWorkspace?.slug,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
            case VOPageSource.COMPARE_WORKSPACES:
                return {
                    productType: this.product.productNameBackend,
                    product: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
            default:
                return {
                    productType: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
        }
    }

    getFormRedirectEventName() {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
                return VirtualOfficeLFormFunnelStages.FORM_REDIRECT;
            case VOPageSource.PRODUCT_DETAIL:
                return VirtualOfficeEvents.VO_PD_FORM_SUCCESS;
            case VOPageSource.LISTING_PAGE:
                return VOSpaceListingEvents.SL_QUOTE_FORM_REDIRECT;
            default:
                return VirtualOfficeLFormFunnelStages.FORM_STEP1_SUBMIT;
        }
    }

    getFormRedirectEventPayload(data) {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
            case VOPageSource.PRODUCT_DETAIL:
            case VOPageSource.COMPARE_WORKSPACES:
                return { type: data, citySlug: this.city.slug };
            case VOPageSource.LISTING_PAGE:
                return {
                    productType: this.product.productNameBackend,
                    spaceSlug: this.voWorkspace?.slug,
                };
            default:
                return {};
        }
    }

    getFormSubmitEventPayload(payload, success) {
        switch (this.config.pageSource) {
            case VOPageSource.LANDING_PAGE:
            case VOPageSource.PRODUCT_DETAIL:
            case VOPageSource.COMPARE_WORKSPACES:
            case VOPageSource.SPACE_DETAIL:
            case VOPageSource.COMPANY_REGISTRATION:
            case VOPageSource.GST_REGISTRATION:
            case VOPageSource.VPOB:
                return {
                    ...payload,
                    success,
                    selectedCity: this.cityObj?.slug,
                    citySlug: this.city?.slug ?? null,
                    productType: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
            case VOPageSource.COMPANY_NAME_SEARCH:
                return {
                    ...payload,
                    success,
                    selectedCity: this.cityObj?.slug,
                    citySlug: this.city?.slug ?? null,
                    productType: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
            case VOPageSource.LISTING_PAGE:
                // eslint-disable-next-line no-case-declarations
                const queryParams = this.urlUtilsService.getCurrentURLQueryParams();
                // eslint-disable-next-line no-case-declarations
                const filters = {
                    ...(queryParams?.filters ? JSON.parse(queryParams.filters) : {}),
                };
                // eslint-disable-next-line no-case-declarations
                let plan: string;
                if (filters[CommonFilters.SOLUTION]) {
                    plan = filters[CommonFilters.SOLUTION];
                }
                return {
                    name: payload.name,
                    mobileNo: payload.phone,
                    email: payload.email,
                    selectedPlan: plan,
                    workspaceSlug: this.voWorkspace?.slug,
                    productType: this.product.productNameBackend,
                    source: this.config?.pageSource,
                    formSource: this.config?.formSource,
                    formType: this.config?.formType,
                };
        }
    }

    subscribeToScrollEvent() {
        this.scrollSubscription = this.utilsService.getScroll().throttledScroll$.subscribe(ypos => {
            this.updateStickyFormDisplay(ypos);
        });
    }

    updateStickyFormDisplay(yPos) {
        if (
            yPos < this.config?.stickyFormConfig?.showAfterTopRef?.nativeElement.offsetHeight ||
            yPos >
                this.config?.stickyFormConfig?.hideAfterBottomRef?.nativeElement.offsetTop -
                    this.config?.stickyFormConfig?.bottomSpacing
        ) {
            this.showStickyForm$.next(false);
        } else {
            this.showStickyForm$.next(true);
        }
    }
    trackFormRedirectAnalytics(data) {
        this.analyticsService.trackVirtualOfficeFormRedirectToListingPage(
            this.getFormRedirectEventName(),
            this.getFormRedirectEventPayload(data)
        );
    }

    ngOnDestroy() {
        if (this.popularCitySub) {
            this.popularCitySub.unsubscribe();
        }
        if (this.menuEventSub) {
            this.menuEventSub.unsubscribe();
        }
    }

    handleCopyCode(code: string) {
        this.clipboard.copy(code);
        this.snackbarService.open({
            type: SnackbarType.SUCCESS,
            title: 'Coupon Code Copied',
            description: `Coupon Code ${code} is copied successfully!`,
        });
        this.onCopyCode.emit(code);
    }
}
