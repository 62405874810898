<div class="bg-color-greyish-purple padding-vertical-8">
    <div class="app-container banner-cont">
        <div class="show-tab-desk">
            <div class="d-flx a-items-c gap-16">
                <div class="flx-1 d-flx a-items-c j-cont-cen gap-24">
                    <div class="d-flx a-items-c gap-8">
                        <div class="image-cont d-flx a-items-c j-cont-cen">
                            <img
                                class="image-cover"
                                [src]="partyIcon.url"
                                [alt]="partyIcon.url"
                                [width]="20"
                                [height]="20"
                            />
                        </div>

                        <p class="font-12 font-bold">
                            Unlock exclusive {{ currentMonth }} discounts! Enjoy no-cost EMI and limited-time offers on
                            your first purchase.
                        </p>
                    </div>
                    <app-button [field]="contactCta" (onClick)="toggleLeadFormState(true)"></app-button>
                </div>

                <div
                    class="d-flx a-items-c gap-4"
                    role="button"
                    (click)="hideBanner()"
                    (keypress)="hideBanner()"
                    tabindex="0"
                >
                    <div class="close-icon-cont d-flx a-items-c j-cont-cen c-pointer position-relative">
                        <img
                            class="image-cover"
                            [src]="closeIcon.url"
                            [alt]="closeIcon.alt"
                            [width]="13"
                            [height]="13"
                        />
                    </div>
                    <span class="font-12 font-bold">Close</span>
                </div>
            </div>
        </div>

        <div class="show-mob">
            <div class="d-flx a-items-c gap-8">
                <div class="flx-1 d-flx a-items-c gap-8">
                    <div
                        class="close-icon-cont d-flx a-items-c j-cont-cen position-relative c-pointer"
                        role="button"
                        (click)="hideBanner()"
                        (keypress)="hideBanner()"
                        tabindex="0"
                    >
                        <img
                            cssClass="image-cover"
                            [src]="closeIcon.url"
                            [alt]="closeIcon.alt"
                            [width]="13"
                            [height]="13"
                        />
                    </div>

                    <div class="flx-1 d-flx a-items-c gap-4 padding-left-8 border-left-primary">
                        <div class="image-cont d-flx a-items-c j-cont-cen position-relative">
                            <img
                                cssClass="image-cover"
                                [src]="partyIcon.url"
                                [alt]="partyIcon.alt"
                                [width]="20"
                                [height]="20"
                            />
                        </div>
                        <div class="d-flx flx-d-clm gap-2">
                            <p class="font-12 font-bolder">Get access to {{ currentMonth }} offers!</p>
                            <p class="font-10">Offers & discounts just for you.</p>
                        </div>
                    </div>
                </div>

                <app-button [field]="contactCta" (onClick)="toggleLeadFormState(true)"></app-button>
            </div>
        </div>
    </div>
</div>

@if (virtualOfficeModalConfig) {
    <app-virtual-office-form
        [open]="showLeadForm"
        [config]="virtualOfficeModalConfig"
        [formFields]="formFields"
        [city]="selectedCity"
        (formModalState)="toggleLeadFormState($event)"
    ></app-virtual-office-form>
}
