import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonsModule } from '../../../ui-components/buttons/buttons.module';
import { VirtualOfficeFormModule } from '../../virtual-office-form/virtual-office-form.module';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';
import { IButtonConfig } from '../../../interfaces/button.interface';
import { buttonType, fontSizes, formPosition } from '../../../enums/enums';
import { ICity } from '../../../interfaces/location.interface';
import { IVOFormConfig, VOPageSource } from '../../virtual-office-form/interfaces/interface';
import { VirtualOfficeFormType } from '../../virtual-office-form/enums/enum';
import { IFieldConfig } from '../../../interfaces/field.interface';
import { FormfieldService } from '../../../../core/services/formFields/formfield.service';
import { SelectCityService } from '../../../services/select-city/select-city.service';
import { guidesRegex, virtualOfficeUrlRegex } from '../../../../core/constants/urls-regex';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { Router } from '@angular/router';

const pageSourceUrlRegexMap = {
    [VOPageSource.LISTING_PAGE]: [virtualOfficeUrlRegex.listingPage],
    [VOPageSource.SPACE_DETAIL]: [virtualOfficeUrlRegex.spaceDetailPage],
    [VOPageSource.PRODUCT_DETAIL]: [virtualOfficeUrlRegex.productPage],
    [VOPageSource.COMPANY_NAME_SEARCH]: [guidesRegex.companyNameSearch],
    [VOPageSource.COMPANY_REGISTRATION]: [guidesRegex.companyRegistration],
    [VOPageSource.GST_REGISTRATION]: [guidesRegex.gstRegistration],
    [VOPageSource.VPOB]: [guidesRegex.vpob],
    [VOPageSource.NGO]: [guidesRegex.ngoRegistration],
    [VOPageSource.CHANGE_COMPANY_ADDRESS]: [guidesRegex.changeCompanyAddress],
    [VOPageSource.CHANGE_GST_ADDRESS]: [guidesRegex.changeGstAddress],
    [VOPageSource.RERA_REGISTRATION]: [guidesRegex.reraRegistration],
    [VOPageSource.MSME_REGISTRATION]: [guidesRegex.msmeRegistration],
    [VOPageSource.GST_FOR_FREELANCERS]: [guidesRegex.gstForFreelancers],
};

@Component({
    standalone: true,
    selector: 'app-virtual-office-banner',
    templateUrl: './virtual-office-banner.component.html',
    styleUrls: ['./virtual-office-banner.component.scss'],
    imports: [ButtonsModule, VirtualOfficeFormModule],
})
export class VirtualOfficeBannerComponent implements OnInit {
    showLeadForm = false;
    currentMonth = this.utilsService.getMonthName();
    partyIcon = { url: CommonIconsUrl.PARTY_FACE_EMOJI, alt: 'party-face' };
    closeIcon = { url: CommonIconsUrl.CLOSE_CIRCLE_DARK, alt: 'offer-ticket' };
    contactCta: IButtonConfig = {
        label: 'Unlock Now',
        type: buttonType.OUTLINE,
        size: fontSizes.SMALL,
    };

    selectedCity: ICity;
    virtualOfficeModalConfig: IVOFormConfig = null;
    formFields: IFieldConfig[] = [
        this.formfieldService.getNameFieldConfig(),
        this.formfieldService.getPhoneFieldConfig(),
        this.formfieldService.getEmailFieldConfig(),
    ];

    @Output() onHideBanner = new EventEmitter();

    constructor(
        private formfieldService: FormfieldService,
        private utilsService: UtilsService,
        private selectCityService: SelectCityService,
        private router: Router
    ) {}

    ngOnInit() {
        this.virtualOfficeModalConfig = {
            formSource: formPosition.HEADER,
            pageSource: VOPageSource.LISTING_PAGE,
            formType: VirtualOfficeFormType.MODAL,
        };

        const url = this.router.url;

        const pageSource = Object.keys(pageSourceUrlRegexMap).find(
            key => this.utilsService.getRegexPatternMatch(pageSourceUrlRegexMap[key], url) >= 0
        );

        if (pageSource) {
            this.virtualOfficeModalConfig = {
                ...this.virtualOfficeModalConfig,
                pageSource: pageSource as VOPageSource,
            };
        }

        this.selectCityService.getSelectedCitySync().subscribe(selectedCityObj => {
            this.selectedCity = selectedCityObj.city;
        });
    }

    toggleLeadFormState(state?: boolean) {
        this.showLeadForm = state;
        if (!state) {
            this.hideBanner();
        }
    }

    hideBanner() {
        this.onHideBanner.emit();
    }
}
