import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { buttonType, citySlug, fontSizes, fontWeights, formFieldType, productKeys } from '../../../shared/enums/enums';
import { RegexMatcherService } from '../regexMatcher/regex-matcher.service';
import { IButtonConfig } from '../../../shared/interfaces/button.interface';

@Injectable({
    providedIn: 'root',
})
export class FormfieldService {
    constructor(private regexMatcherService: RegexMatcherService) {}

    name = {
        label: 'Name',
        type: 'text',
        fieldType: formFieldType.INPUT,
        name: 'name',
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexName()),
                message: 'Enter a valid name',
            },
            {
                name: 'required',
                validator: Validators.required,
                message: 'Name is required',
            },
        ],
    };
    phone = {
        label: 'Mobile number',
        type: 'tel',
        fieldType: formFieldType.INPUT,
        name: 'phone',
        maxLength: 10,
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexPhone()),
                message: 'Enter a valid number',
            },
            {
                name: 'required',
                validator: Validators.required,
                message: 'Phone is required',
            },
        ],
    };
    internationalPhone = {
        label: 'Mobile number',
        type: 'tel',
        fieldType: formFieldType.PHONE_NUMBER,
        name: 'phone',
        maxLength: 16,
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getInternationalRegexPhone()),
                message: 'Enter a valid number',
            },
            {
                name: 'required',
                validator: Validators.required,
                message: 'Phone is required',
            },
        ],
    };
    otp = {
        fieldType: formFieldType.OTP,
        name: 'otp',
        type: 'number',
        focus: true,
        maxLength: 4,
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexOTPItem()),
                message: 'Only numerical values are allowed',
            },
            {
                name: 'required',
                validator: Validators.required,
                message: 'OTP is required',
            },
        ],
    };

    email = {
        label: 'Email',
        type: 'email',
        fieldType: formFieldType.INPUT,
        name: 'email',
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexEmail()),
                message: 'Enter a valid email',
            },
            {
                name: 'required',
                validator: Validators.required,
                message: 'Email is required',
            },
        ],
    };
    companyName = {
        label: 'Company Name',
        type: 'text',
        fieldType: formFieldType.INPUT,
        name: 'company',
        validations: [],
    };
    designation = {
        label: 'Designation',
        type: 'text',
        fieldType: formFieldType.INPUT,
        name: 'designation',
        validations: [],
    };
    gst = {
        label: 'GST No.',
        type: 'text',
        fieldType: formFieldType.INPUT,
        name: 'gst',
        validations: [
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexGst()),
                message: 'Enter a valid GST number',
            },
        ],
    };
    btn: IButtonConfig = {
        type: buttonType.PRIMARY,
        label: 'Request Callback',
        cssClass: 'fixed',
        showLoader: false,
        size: fontSizes.LARGE,
        fontWeight: fontWeights.BOLD,
    };

    address = {
        label: 'Address',
        type: 'text',
        fieldType: formFieldType.INPUT,
        name: 'address',
        validations: [
            {
                name: 'required',
                validator: Validators.required,
                message: 'Address is required',
            },
        ],
    };

    password = {
        label: 'Password',
        type: 'password',
        fieldType: formFieldType.PASSWORD,
        name: 'password',
        validations: [
            {
                name: 'required',
                validator: Validators.required,
                message: 'Password is required',
            },
            {
                name: 'pattern',
                validator: Validators.pattern(this.regexMatcherService.getRegexPassword()),
                message: 'Please enter a password with at least 4 characters',
            },
        ],
    };

    date = {
        label: 'Select Date',
        type: 'text',
        fieldType: formFieldType.DATE,
        name: 'date',
        minDate: new Date(),
        validations: [
            {
                name: 'required',
                validator: Validators.required,
                message: 'Date is required',
            },
        ],
    };

    timeDuration = {
        label: 'Select Duration',
        type: 'text',
        fieldType: formFieldType.TIME_DURATION,
        name: 'duration',
        validations: [
            {
                name: 'required',
                validator: Validators.required,
                message: 'Duration is required',
            },
        ],
    };

    cityId = {
        label: 'Select city',
        type: 'text',
        fieldType: formFieldType.SINGLE_SELECT_DROPDOWN,
        name: 'cityId',
        options: [],
        searchConfig: {
            placeholder: 'Search for City / State',
        },
        validations: [this.getBlankFieldValidator('City')],
    };

    citySlug = {
        label: 'Select City',
        fieldType: formFieldType.SINGLE_SELECT_DROPDOWN,
        name: 'citySlug',
        options: [
            { label: 'Ahmedabad', value: citySlug.AHMEDABAD },
            { label: 'Bangalore', value: citySlug.BANGALORE },
            { label: 'Chennai', value: citySlug.CHENNAI },
            { label: 'Gurgaon', value: citySlug.GURUGRAM },
            { label: 'Hyderabad', value: citySlug.HYDERABAD },
            { label: 'Mumbai', value: citySlug.MUMBAI },
            { label: 'New Delhi', value: citySlug.NEW_DELHI },
            { label: 'Noida', value: citySlug.NOIDA },
            { label: 'Pune', value: citySlug.PUNE },
        ],
        validations: [this.getBlankFieldValidator('City')],
    };

    products = {
        label: 'Select Solution',
        type: 'text',
        fieldType: formFieldType.DROPDOWN,
        name: 'productType',
        options: [
            {
                label: 'Team Plan',
                value: productKeys.CORPORATE_FLEXI,
                // description: 'Flexible day pass for remote & hybrid teams managed by one account',
            },
            {
                label: 'Meeting Rooms',
                value: productKeys.MEETING_ROOM,
                // description: 'Meeting, conference and training rooms for teams',
            },
            {
                label: 'Coworking Space',
                value: productKeys.DEDICATED,
                // description: 'Rent dedicated seats and cabins in fully-equipped coworking spaces',
            },
            {
                label: 'Office/Commercial',
                value: productKeys.COMMERCIAL_LEASING,
            },
            {
                label: 'Managed Office',
                value: productKeys.MANAGED_OFFICE,
            },
            {
                label: 'Virtual Office',
                value: productKeys.VIRTUAL_OFFICE,
                // description: 'Registration & GST solutions for new businesses',
            },
        ],
        validations: [this.getBlankFieldValidator('Solutions')],
    };

    requirement = {
        label: 'Requirement',
        type: 'text',
        fieldType: formFieldType.SINGLE_SELECT_DROPDOWN,
        name: 'product',
        options: [
            {
                label: 'Team Plan',
                value: productKeys.CORPORATE_FLEXI,
                description: 'Flexible day pass for remote & hybrid teams managed by one account',
            },

            {
                label: 'Coworking Space',
                value: productKeys.DEDICATED,
                description: 'Rent dedicated seats and cabins in fully-equipped coworking spaces',
            },
            {
                label: 'Managed Office',
                value: productKeys.MANAGED_OFFICE,
                description: 'Fully furnished customized office for big teams',
            },
        ],
        validations: [
            {
                name: 'required',
                validator: Validators.required,
                message: 'Requirement is required',
            },
        ],
    };

    profession = {
        label: 'What best describes your profession?',
        fieldType: formFieldType.DROPDOWN,
        name: 'designation',
        options: [
            {
                label: 'Founder',
                value: 'Founder',
            },
            {
                label: 'HR & Admin',
                value: 'HR & Admin',
            },
            {
                label: 'Marketer',
                value: 'Marketer',
            },
            {
                label: 'Real Estate Consultant',
                value: 'Real Estate Consultant',
            },
            {
                label: 'Others',
                value: 'Others',
            },
        ],
        validations: [this.getBlankFieldValidator('profession')],
    };

    numberOfEmployees = {
        label: 'No. of employees',
        fieldType: formFieldType.INPUT,
        name: 'numberOfEmployees',
        type: 'number',
        focus: true,
        hideIcon: true,
    };
    carpetArea = {
        label: 'Carpet area',
        fieldType: formFieldType.INPUT,
        name: 'carpetArea',
        type: 'number',
        focus: true,
        hideIcon: true,
    };
    getBlankFieldValidator(name: string) {
        return {
            name: 'required',
            validator: Validators.required,
            message: `${name} is required.`,
        };
    }
    getNameFieldConfig() {
        return this.name;
    }
    getPhoneFieldConfig() {
        return this.phone;
    }
    getInternationalPhoneFieldConfig() {
        return this.internationalPhone;
    }

    getCityIdFieldConfig() {
        return this.cityId;
    }
    getCitySlugFieldConfig() {
        return this.citySlug;
    }
    getEmailFieldConfig() {
        return this.email;
    }
    getCompanyNameFieldConfig() {
        return this.companyName;
    }
    getDesignationFieldConfig() {
        return this.designation;
    }
    getGSTnumberFieldConfig() {
        return this.gst;
    }
    getAddressFieldConfig() {
        return this.address;
    }
    getOTPFieldConfig() {
        return this.otp;
    }
    getBtnConfig() {
        return this.btn;
    }
    getPasswordFieldConfig() {
        return this.password;
    }
    getDateFieldConfig() {
        return this.date;
    }

    getTimeDurationFieldConfig() {
        return this.timeDuration;
    }

    getProductsFieldConfig() {
        return this.products;
    }
    getRequirementFieldConfig() {
        return this.requirement;
    }

    getProfessionFieldConfig() {
        return this.profession;
    }

    getNumberOfEmployeesFieldConfig() {
        return this.numberOfEmployees;
    }
    getCarpetAreaFieldConfig() {
        return this.carpetArea;
    }
}
