import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../../shared/interfaces/HTTPResponse.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../../core/services/error/error.service';
import { formStages } from './../../../shared/enums/enums';
import { CampaignTrackingService } from '../../../core/services/campaign-tracking/campaign-tracking.service';

@Injectable({ providedIn: 'root' })
export class VirtualOfficeFormService {
    formStageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(formStages.STAGE_1);

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private campaignTrackingService: CampaignTrackingService
    ) {}

    createLead(payload) {
        const payloadWithTrackingData = this.campaignTrackingService.updatePayloadForTracking(payload);
        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .post<IHttpResponse<any>>(`${environment.baseUrl}/campaigns/virtual-office`, payloadWithTrackingData)
                .pipe(catchError(this.errorService.handleError))
        );
    }

    setFormStage(stage) {
        this.formStageSubject.next(stage);
    }
}
