@if (config?.formType === virtualOfficeFormType.DEFAULT) {
    <div>
        <div class="form show-desk" [ngClass]="className">
            <ng-container *ngTemplateOutlet="form?.template"></ng-container>
        </div>
        <div class="show-mob-tab" [ngClass]="{ 'light-theme': !config?.isHero }">
            @if (!config?.isHero) {
                <div>
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                </div>
            }
            <div class="button-cont">
                <app-button
                    [field]="{
                        type: buttonTypeEnum.PRIMARY,
                        label: config?.buttonConfig?.label ?? 'Know more',
                        size: fontSizesEnum.LARGE,
                        fontWeight: fontWeightsEnum.BOLD,
                    }"
                    (onClick)="toggleModal(true)"
                ></app-button>
            </div>
        </div>
    </div>
}

<app-modal [open]="formModal.show" [template]="modalFormWithBanner" [cssClass]="formModal.cssClass"></app-modal>

@if (config?.formType === virtualOfficeFormType.FLOATING_BUTTON && !(formComplete$ | async)) {
    <div>
        <div class="show-desk">
            <div class="floating-btn-contain" (click)="toggleModal(true)">
                <div class="floating-btn">
                    <div class="heading">Find your virtual office at the lowest price possible</div>
                    <app-link-with-icon
                        [LinkText]="'Request callback'"
                        [linkColor]="'white'"
                        [size]="'large'"
                    ></app-link-with-icon>
                </div>
            </div>
        </div>
    </div>
}

@if (config?.formType === virtualOfficeFormType.STICKY_BUTTON && !(formComplete$ | async)) {
    <div class="show-mob-tab">
        <div class="sticky-bottom-cont">
            <app-button
                [field]="{
                    type: buttonTypeEnum.PRIMARY,
                    label: 'Request callback',
                    size: fontSizesEnum.LARGE,
                    fontWeight: fontWeightsEnum.BOLD,
                }"
                (onClick)="toggleModal(true)"
            ></app-button>
        </div>
    </div>
}

@if (config?.formType === virtualOfficeFormType.STICKY_FORM && !(formComplete$ | async)) {
    <div class="show-desk">
        <div class="form-sticky" [ngClass]="(showStickyForm$ | async) ? 'show' : 'hide'">
            <div class="app-container d-flx j-cont-space-bet a-items-c">
                <div class="form-sticky-heading d-flx">
                    <span>Get Quote</span>
                </div>
                <app-forms
                    class="flx-1"
                    [config]="stickyFooterFormConfig"
                    (submit)="onStickyFormSubmit($event)"
                ></app-forms>
            </div>
        </div>
    </div>
}

<ng-template #modalFormWithBanner>
    <div class="d-flx">
        <div class="show-desk">
            <ng-container [ngTemplateOutlet]="formSideTemplate"></ng-container>
        </div>
        <app-modal-form-container
            (onClose)="toggleModal(false)"
            class="width-100 modal-container"
            containerClass="bg-color-white-desk bg-color-off-white-mob padding-24-desk padding-16-mob border-top-right-radius-4 border-bottom-right-radius-4"
        >
            <ng-container *ngTemplateOutlet="form?.template"></ng-container>
        </app-modal-form-container>
    </div>
</ng-template>

<ng-template #primaryInfoTemplate>
    <div class="primary-form">
        <ng-container *ngTemplateOutlet="formBody"></ng-container>
        @if (formError.visible) {
            <div class="error-message">{{ formError.content }}</div>
        }
    </div>
</ng-template>

<ng-template #successTemplate>
    @switch (formSuccessConfig.type) {
        @case (VirtualOfficeFormSuccessType.COUPON) {
            <div>
                <ng-container [ngTemplateOutlet]="successCouponTemplate"></ng-container>
            </div>
        }
        @default {
            <div class="success-form">
                <app-lead-success [config]="formSuccessConfig" [template]="successButtonTemplate"></app-lead-success>
            </div>
        }
    }
</ng-template>

<ng-template #successCouponTemplate>
    <div class="d-flx flx-d-clm gap-24">
        <div class="d-flx a-items-c gap-8">
            <div class="success-image-cont d-flx">
                <img class="image-contain" [src]="CommonIconsUrl.SUCCESS" alt="i" />
            </div>
            <h3 class="font-20 font-bold">{{ formSuccessConfig?.heading }}</h3>
        </div>

        <div class="flx-1 d-flx flx-d-clm gap-4">
            <p class="font-14">{{ formSuccessConfig?.description }}</p>

            <div class="flx-1 d-flx flx-d-clm gap-12 overflow-y-auto padding-vertical-8 coupons-cont">
                @for (coupon of formSuccessConfig?.coupons; track coupon) {
                    <div class="d-flx flx-d-clm gap-8 padding-12 border-1 border-color-grey border-radius-4">
                        <div class="d-flx a-items-c j-cont-space-bet">
                            <div
                                class="coupon-code border-radius-2 padding-4 text-color-primary font-16 font-bold text-transform-uppercase"
                            >
                                {{ coupon?.couponConfig?.code }}
                            </div>
                            <p
                                class="font-12 font-bold text-color-primary c-pointer"
                                (click)="handleCopyCode(coupon?.couponConfig?.code)"
                            >
                                Copy Code
                            </p>
                        </div>
                        <h4 class="font-14 font-bold">{{ coupon.displayText }}</h4>
                        <p class="font-12 text-color-medium-grey">{{ coupon.description }}</p>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #successButtonTemplate>
    <div class="success-template-cont">
        @if (!formSuccessConfig.showContactDetails) {
            <div class="text-c success-desc-cont">
                {{ buttonConfig.desc }}
            </div>
        }
        @if (buttonConfig.linkUrl) {
            <a class="c-pointer" (click)="handleSuccessButtonClick()">
                <app-button
                    [field]="{
                        type: buttonTypeEnum.PRIMARY,
                        label: buttonConfig.label,
                        size: fontSizesEnum.LARGE,
                        fontWeight: fontWeightsEnum.BOLD,
                    }"
                ></app-button>
            </a>
        }
    </div>
</ng-template>

<ng-template #formBody>
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
    @if (!config?.showCityDropdown || config?.showCityDropdown) {
        <div>
            <app-forms [config]="formConfig" (submit)="createLead($event)" [mobCtaTemplate]="formMobButtonTemplate">
                <div postFormFields class="show-mob-tab">
                    <ng-container [ngTemplateOutlet]="formSideTemplate"></ng-container>
                </div>
            </app-forms>
        </div>
    }
</ng-template>

<ng-template #formMobButtonTemplate let-buttonsTemplate="buttonsTemplate">
    <div class="form-button-cont padding-vertical-16 padding-horizontal-24 bg-color-white">
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #formSideTemplate>
    <app-lead-form-side-banner [config]="leadFormSideBannerConfig"></app-lead-form-side-banner>
</ng-template>

<ng-template #headerTemplate>
    <div class="d-flx flx-d-clm gap-12 margin-bottom-24">
        <div class="d-flx flx-d-clm gap-4">
            <h2 class="font-26 font-bold">
                {{ formHeaderConfig?.heading }}
            </h2>
            @if (formHeaderConfig?.description) {
                <p class="font-12 text-color-lighter-grey">
                    {{ formHeaderConfig?.description }}
                </p>
            }
        </div>

        @if (formHeaderConfig?.list?.length) {
            <div class="list-cont d-flx gap-8 flx-d-clm-row">
                @for (listItem of formHeaderConfig?.list; track listItem) {
                    <div class="list-item d-flx a-items-start margin-right-8">
                        <div class="d-flx a-items-c j-cont-cen list-item-icon-cont margin-right-8">
                            <img
                                class="height-100 width-100 object-fit-contain svg-cont d-flx"
                                [imageSrc]="listItem.svgLogo"
                                appImageShimmerLazyLoad
                                alt="feature"
                            />
                        </div>
                        <span class="font-12 font-bold">{{ listItem.description }}</span>
                    </div>
                }
            </div>
        }
    </div>
</ng-template>
