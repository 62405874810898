import { IHeroLandingPageWithImage } from '../../components/hero-landing-page-with-image/hero-landing-page-with-image.interface';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';
import {
    FormHeaderListType,
    IFormHeaderConfig,
} from '../../../../shared/ui-components/form-header/form-header.interface';
import { formPosition } from '../../../../shared/enums/enums';
import {
    CommercialLeasingFormType,
    ICommercialLeasingFormConfig,
} from '../../../../shared/app-components/form/commercial-leasing-form/commercial-leasing-form.interface';
import { CommercialLeasingFormSource } from '../../../../shared/app-components/form/enums';
import { HeroLandingPageWithImageSize } from '../../components/hero-landing-page-with-image/hero-landing-page-with-image.enum';
import { AnimationOptions } from 'ngx-lottie';
import { animatedImagePath } from '../../../../core/constants/constants';
import { IOfficeGuideSteps } from '../../components/office-guide-steps/office-guide-steps.component.interface';
import { IWorkspaceByRegionConfig } from '../../../../shared/component-modules/workspace/workspaces-by-region/workspaces-by-region.component';
import { IFooterLandingPageConfig } from '../../../../shared/standalone-components/footer-landing-page/footer-landing-page.interface';
import { ENTERPRISES_IMAGE_BASE_URL } from '../../../enterprises/enterprises.config';
import { IStickyFooterButton } from '../../../../shared/interfaces/space-details.interface';
import { StickyButtonIconPostion, StickyButtonType } from '../../../../shared/app-components/sticky-footer-button/enum';

const benefits = [
    {
        description: 'Zero brokerage',
        icon: {
            url: CommonIconsUrl.TICK_MARK_WHITE_ICON,
            alt: 'Customised Managed Offices',
        },
        tooltipConfig: {
            text: 'Save on brokerage fees-100% of your investment goes towards your workspace, with no unwanted calls.',
            position: 'bottom left',
        },
    },
    {
        description: 'Ethical dealing (Part of ANAROCK)',
        icon: {
            url: CommonIconsUrl.TICK_MARK_WHITE_ICON,
            alt: 'Flexible leases and shorter commitments',
        },
    },
    {
        description: '100% verified commercial spaces',
        icon: {
            url: CommonIconsUrl.TICK_MARK_WHITE_ICON,
            alt: 'Grade-A buildings in CBDs & premium locations',
        },
    },
    {
        description: 'Dedicated office space experts',
        icon: {
            url: CommonIconsUrl.TICK_MARK_WHITE_ICON,
            alt: 'pan india',
        },
    },
];
const animationOptions: AnimationOptions = {
    path: animatedImagePath.ORANGE_GEMINI,
    loop: true,
};
const clHeaderCommonConfig = {
    heading: 'Work in Style at Premium Office Spaces',
    description: 'Rent/Lease your ideal office in a flash',
};

const clLeadGenFormBenefits = [
    {
        description: 'Zero brokerage',
        svgLogo: CommonIconsUrl.TICK_MARK_ICON,
    },
    {
        description: 'Ethical dealing (Part of ANAROCK)',
        svgLogo: CommonIconsUrl.TICK_MARK_ICON,
    },
    {
        description: '100% verified commercial spaces',
        svgLogo: CommonIconsUrl.TICK_MARK_ICON,
    },
    {
        description: 'Dedicated office space experts',
        svgLogo: CommonIconsUrl.TICK_MARK_ICON,
    },
];

export const clHeroDeskConfig: IHeroLandingPageWithImage = {
    image: {
        url: `web/ssr/pages/landing-pages/commercial-leasing/hero-3`,
        alt: `commercial leasing`,
    },
    size: HeroLandingPageWithImageSize.MEDIUM,
    templateCss: 'height-100',
};

export const clHeroMobConfig = {
    clHeaderCommonConfig,
    benefits,
    image: {
        url: `web/ssr/pages/landing-pages/commercial-leasing/hero-mob`,
        alt: `commercial leasing`,
    },
};

export const clFormHeaderConfig: IFormHeaderConfig = {
    ...clHeaderCommonConfig,
    list: clLeadGenFormBenefits,
    listType: FormHeaderListType.VERTICAL_LIST,
    animationOptions,
};

export const clModalFormConfig: ICommercialLeasingFormConfig = {
    desk: null,
    mwebOpen: {
        heading: clHeaderCommonConfig.heading,
        subHeading: clHeaderCommonConfig.description,
        ctaText: 'Request Callback',
    },
    mwebClose: null,
    formType: CommercialLeasingFormType.MODAL,
    formSource: formPosition.MIDDLE,
    formHeaderConfig: {
        heading: 'Helping you find your perfect Office Space!',
        description: 'Speak with a workspace solution expert',
    },
};

export const clBottomStickyFormConfig: ICommercialLeasingFormConfig = {
    ...clModalFormConfig,
    formType: CommercialLeasingFormType.INLINE,
    inlineFormHeading: 'Get a call back!',
};
export const clDefaultFormConfig: ICommercialLeasingFormConfig = {
    desk: null,
    mwebClose: null,
    mwebOpen: null,
    formType: CommercialLeasingFormType.DEFAULT,
    formSource: CommercialLeasingFormSource.HERO,
    formHeaderConfig: { ...clFormHeaderConfig, isAnimationEnabled: true },
};

export const clBottomFormConfig: ICommercialLeasingFormConfig = {
    ...clDefaultFormConfig,
    formSource: CommercialLeasingFormSource.TESTIMONIAL,
    formHeaderConfig: {
        heading: 'Find your ideal office space',
        list: [
            {
                description: 'Zero brokerage',
                svgLogo: CommonIconsUrl.TICK_MARK_ICON,
            },
            {
                description: 'Pan India coverage',
                svgLogo: CommonIconsUrl.TICK_MARK_ICON,
            },
        ],
        listType: FormHeaderListType.SEQUENTIAL_LIST,
    },
};
export const clOfficeGuideSteps: IOfficeGuideSteps = {
    heading: '5 simple steps to your ideal office space',
    image: {
        url: `web/ssr/pages/landing-pages/commercial-leasing/5-steps`,
        alt: `office guide`,
    },
    ctaText: 'Connect with our experts for end-to-end support',
    steps: [
        {
            stepName: 'Step 1',
            text: 'Share your requirements with us & an expert consultant will be assigned to you',
            image: {
                url: `web/ssr/pages/landing-pages/commercial-leasing/step-1`,
                alt: `step-1`,
            },
            isSelected: false,
        },
        {
            stepName: 'Step 2',
            text: 'Our expert consultants will curate/select suitable options',
            image: {
                url: `web/ssr/pages/landing-pages/commercial-leasing/step-2`,
                alt: `step-2`,
            },
            isSelected: false,
        },
        {
            stepName: 'Step 3',
            text: 'We will schedule visits to selected properties with our on-ground team',
            image: {
                url: `web/ssr/pages/landing-pages/commercial-leasing/step-3`,
                alt: `step-3`,
            },
            isSelected: false,
        },
        {
            stepName: 'Step 4',
            text: 'Based on your preferences, we’ll help you close negotiations',
            image: {
                url: `web/ssr/pages/landing-pages/commercial-leasing/step-4`,
                alt: `step-4`,
            },
            isSelected: false,
        },
        {
            stepName: 'Step 5',
            text: 'Notary and lease registration with continued assistance until move-in',
            image: {
                url: `web/ssr/pages/landing-pages/commercial-leasing/step-5`,
                alt: `step-5`,
            },
            isSelected: false,
        },
    ],
};

export const clWorkspaceByRegionConfig: IWorkspaceByRegionConfig = {
    heading: 'Own an office space anywhere',
    subheading: `Want a location that best represents your business? Select an office space in the locality of your choice from our partner spaces.`,
    triggerAnimation: true,
};

export const clFooterConfig: IFooterLandingPageConfig = {
    text: `myHQ provides efficient, hyperlocal and customized office leasing, managed offices & flexible solutions. Flexible solutions include virtual offices, private cabins, day passes & on-demand meeting rooms in coworking spaces.`,
    isStickyButtonVisible: true,
};

export const clPickRightOfficeSectionConfig = {
    heading: 'Our enterprise-grade office space solutions',
    subheading:
        'Choose the right fit from our portfolio of enterprise-grade offerings with partnered office spaces across India',
    officeItems: [
        {
            title: 'Office/Commercial Spaces',
            description: 'Fully enclosed office for a single company',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/hero/enterprises-hero-4.jpg`,
            features: [
                'Long term contracts (3 or more years)',
                'Full customizations with self managed amenities',
                'Avg. space requirement starts from 5,000 sq. ft',
                'Ideal for 100+ team size',
            ],
        },
        {
            title: 'Managed Offices',
            description: 'Dedicated office space managed by a provider',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/managed-offices2.png`,
            features: [
                'Full-floor offices with private reception area',
                'Customized layouts, theme colors and design',
                'Fully managed operations & housekeeping',
                'Ideal for 50+ team size',
            ],
        },
        {
            title: 'Coworking Space',
            description: 'Private serviced office with shared amenities',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/private-offices-shared-access2.png`,
            features: [
                'Add/remove number of desks as per team size',
                'Hassle-free, immediately available',
                'Access to common space amenities',
                'Ideal for individual or small teams',
            ],
        },
        {
            title: 'Team Plan',
            description: 'Flexible day pass managed by one account',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/team-flexi.png`,
            features: [
                'Centralized dashboard for entire team',
                'Manage & track bookings at one place',
                'Centralized billing and expense management',
                'Ideal for remote, distributed & hybrid teams',
            ],
        },
        {
            title: 'Virtual Office',
            description: 'Registration & GST solutions for new businesses',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/virtual-office.png`,
            features: [
                'Benefits of a professional business address',
                'No physical office costs or high monthly rents',
                'Full documentation including rent agreement, NOC from landlord & utility bills',
            ],
        },
    ],
};

export const clStickyFooterButtonConfig: IStickyFooterButton = {
    label: 'WhatsApp Us',
    icon: CommonIconsUrl.WHATSAPP_LOGO,
    type: StickyButtonType.GREEN,
    iconPosition: StickyButtonIconPostion.LEFT,
};
