// Used when variables are independent of environment
export class ImageBaseUrl {
    // Usage Guide for Cloudfront: https://myhq.atlassian.net/wiki/x/AwD5Iw?atlOrigin=eyJpIjoiN2VmMThmY2VhMjIzNGE3Mjk2ZjMzZjk0Zjk1OTljMjgiLCJwIjoiYyJ9
    public static CLOUDFRONT_URL = 'https://d1s8lxxyjoicqu.cloudfront.net';
    public static CLOUDINARY_URL = 'https://res.cloudinary.com/myhq/';
    public static IMAGE_URL_BASE = 'https://res.cloudinary.com/myhq/image/upload';
    public static IMAGE_CONFIG_BASE = 'fl_lossy,f_auto,q_auto';
    public static COMMON_ASSETS = 'web/ssr/common/assets';
    public static IMAGE_CONFIG_BASE_FQ = 'f_auto,q_auto';
}

export class WebsiteBaseUrls {
    public static WORKSPACE_FREE_TRIAL = '/flexi/free-trial/details';
    public static WORKSPACE_SCHEDULE_VISIT = '/dedicated/coworking-space';
}

export class PhoneNumber {
    public static PHONE_NUMBER = '+91 9205006361';
} // Deprecated. Use ContactDetails Instead

export class ContactDetails {
    public static PHONE_NUMBER = {
        label: '+91 9205006361',
        href: 'tel://+91-9205006361',
    };
    public static EMAIL = {
        label: 'office@myhq.in',
        href: 'mailto://office@myhq.in',
    };
    public static ADDRESS = {
        label: 'Address: 1002, 10th Floor, B Wing, ONE BKC, G Block Bandra Kurla Complex, Mumbai – 400 051',
    };
}

export class AppDownloadLink {
    public static IOS = 'https://apps.apple.com/in/app/myhq/id1106061710';
    public static ANDROID = 'https://play.google.com/store/apps/details?id=com.myhq.hqapp';
}

export class SocialMediaLink {
    public static FACEBOOK = 'https://www.facebook.com/myhqspace';
    public static INSTAGRAM = 'https://www.instagram.com/myhq.in';
    public static TWITTER = 'https://twitter.com/myHQSpace';
    public static LINKEDIN = 'https://www.linkedin.com/company/myhqspace';
    public static GOOGLE_PLUS = 'https://plus.google.com/+myHQ_workspaces';
}

export const BookingSourceId = 'WEBAPP';
export const TrialBookingSourceId = 'WebApp';

export const PopularityIndex = 7;

export class GeoLocationOptions {
    public static TIMEOUT = 5000;
    public static ENABLE_HIGH_ACCURACY = true;
    public static MAXIMUM_AGE = 43200000; // 1/2day in milliseconds
}

export class PromotionConstants {
    public static WORKSPACE_COUNT = 5000;
    public static CITIES_LIVE = 5;
    public static COMPANIES_ASSOCIATED = 575;
    public static MEMBERS = 10000;
    public static COMMUNITY_BENEFITS = { COUNT: 100, WORTH: '10 Lacs' };
    public static CLIENTS_AM = '5,000';
}

export class AmConstants {
    public static OFFICE_SPACE_CONSULTANTS = 100;
    public static PARTNER_SPACES = 5000;
    public static CLIENTS_SERVED = 5000;
    public static SEATS_DELIVERED = 50000;
    public static CITIES = 30;
    public static AREA = 5000000;
}

export class AMCoverageAndSupply {
    public static AREA = {
        title: `5M+`,
        description: 'Sq.ft area',
    };
    public static PROPERTIES = {
        title: `${AmConstants.PARTNER_SPACES.toLocaleString()}+`,
        description: 'Verified properties',
    };
    public static CLIENTS = {
        title: `${AmConstants.CLIENTS_SERVED.toLocaleString()}+`,
        description: 'Corporate clients',
    };
    public static MANAGERS = {
        title: `${AmConstants.OFFICE_SPACE_CONSULTANTS.toLocaleString()}+`,
        description: 'Account managers',
    };
    public static CITIES = {
        title: `${AmConstants.CITIES.toLocaleString()}+`,
        description: 'Cities in India',
    };
    public static SEATS_DELIVERED = {
        title: `${AmConstants.SEATS_DELIVERED.toLocaleString()}+`,
        description: 'Seats delivered',
    };
}

export class UserReviewsConstants {
    public static MAX_REVIEW_VALUE = 5;
    public static MIN_REVIEW_VALUE = 1;
    public static REVIEWS_PER_PAGE = 5;
}

export enum animatedImagePath {
    OFFER_TAG = 'assets/lottie/offer-tag.json',
    GREEN_STAR = 'assets/lottie/green-star.json',
    SPARKLE = 'assets/lottie/sparkle.json',
    ORANGE_GEMINI = 'assets/lottie/orange-gemini.json',
    CONFETTI = 'assets/lottie/confetti.json',
    CALENDAR = 'assets/lottie/calendar.json',
}
