import { Component, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ICity } from '../../interfaces/location.interface';
import { ISelectCityModalConfig } from '../select-city-modal/select-city-modal.interface';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { SelectCityModalComponent } from '../select-city-modal/select-city-modal.component';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { flexiUrlRegex, meetingRoomsRegex, virtualOfficeUrlRegex } from '../../../core/constants/urls-regex';
import { productsMap } from '../../enums/enums';
import { map, startWith, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    selector: 'app-select-city',
    templateUrl: './select-city.component.html',
    styleUrls: ['./select-city.component.scss'],
    imports: [SelectCityModalComponent, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCityComponent implements OnDestroy {
    selectedCity: ICity;
    selectCityModalConfig: ISelectCityModalConfig;
    selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
        if (city) {
            this.selectedCity = city;
            this.toggleSelectCityModal(false);
        } else {
            this.toggleSelectCityModal(true);
        }

        this.cdRef.markForCheck();
    });

    constructor(
        private cdRef: ChangeDetectorRef,
        private selectCityService: SelectCityService,
        private utilsService: UtilsService,
        private router: Router
    ) {}

    product$ = this.utilsService.getRouterEvents$().pipe(
        map(({ url }) => url),
        startWith(this.router.url),
        map(url => {
            if (this.utilsService.getRegexPatternMatch([flexiUrlRegex.allUrls], url) >= 0) {
                return productsMap.FLEXI;
            }
            if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.allUrls], url) >= 0) {
                return productsMap.MEETING_ROOM;
            }
            if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.allUrls], url) >= 0) {
                return productsMap.VIRTUAL_OFFICE;
            }
            return null;
        }),
        map(product => (product ? product?.productNameBackend : null)),
        startWith(null)
    );

    ngOnDestroy() {
        this.selectCitySub.unsubscribe();
        this.toggleSelectCityModal(false);
    }

    toggleSelectCityModal(action: boolean) {
        this.selectCityModalConfig = {
            showModal: action,
        };
    }
}
