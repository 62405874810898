import { Observable } from 'rxjs';
import { menuCalloutType, menuEnum, menuType } from '../../enums/enums';
import { buttonType } from '../../../../enums/enums';
import { IExpandableLinks } from '../../common-header.interface';

export enum DropdownMenuItemType {
    DEFAULT = 'DEFAULT',
    ACCORDION = 'ACCORDION',
}

export type IDefaultMenuItem = {
    // DropdownMenuItemType.DEFAULT;
    id: string;
    imageUrl: string;
    title: string;
    description?: string;
    redirectUrl: Observable<string>;
    isExternalUrl?: boolean;
    comingSoon?: boolean;
    showTooltipInsteadOfDescription?: boolean;
};

export type IAccordionMenuItem = {
    type: DropdownMenuItemType.ACCORDION;
    imageUrl: string;
    title: string;
    items: Pick<IDefaultMenuItem, 'title' | 'description' | 'redirectUrl'>[];
};

export type IMenuWithDropdownMenuItem = IDefaultMenuItem | IAccordionMenuItem;

export interface IMenuWithDropdownSubMenu {
    id: menuEnum;
    title: string;
    menuItems: IMenuWithDropdownMenuItem[];
    description?: string;
    collapsed?: boolean;
    iconUrl?: string;
}

export interface IMenuWithDropdownConfig {
    id: menuEnum;
    title?: string;
    description?: string;
    type: menuType;
    show: boolean;
    collapsed?: boolean;
    btnType?: buttonType;
    showArrow?: boolean;
    isExternalUrl?: boolean;
    redirectUrl?: Observable<string>;
    iconUrl?: string;
    iconUrlSelected?: string;
    imageUrl?: string;
    comingSoon?: boolean;
    subMenus?: IMenuWithDropdownSubMenu[];
    calloutType?: menuCalloutType;
    expandableLinks?: IExpandableLinks[];

    /**
     * On mobile if true will render the submenu
     * in a single dropdown with parents config
     */
    mobileSingleMenu?: boolean;
}
