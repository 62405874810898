<app-modal-container class="height-100 d-block" [containerClass]="containerClass">
    @if (config?.closeable) {
        <div
            class="margin-left-auto a-items-c j-cont-end c-pointer margin-bottom-16 close-icon-container show-mob-tab-flx"
            (click)="handleModalClose()"
        >
            <div class="close-icon"></div>
        </div>
    }
    @if (config?.closeable) {
        <div
            class="a-items-c j-cont-cen c-pointer bg-color-white close-icon-container show-desk-flx"
            (click)="handleModalClose()"
        >
            <div class="close-icon-inner-container bg-color-black padding-4">
                <div class="close-icon close-icon-white"></div>
            </div>
        </div>
    }
    <ng-content></ng-content>
</app-modal-container>
