import { IStateOfTheArtConfig } from '../landing-pages/enterprises/enterprises.interface';
import { CommonIconsUrl } from '../../core/constants/common-icons-url';
import { ImageUnderlaySize } from '../../shared/ui-components/image-with-underlay/image-with-underlay.interface';
import { formPosition } from '../../shared/enums/enums';
import { AmConstants, ImageBaseUrl } from '../../core/constants/constants';
import { IFeaturedBrandsConfig } from '../../shared/standalone-components/featured-brands/featured-brands.interface';
import { ICompanyStatsConfig } from '../../shared/standalone-components/company-stats/company-stats.interface';
import { IStepperConfig } from '../../shared/standalone-components/stepper/stepper.interface';
import { IImageFormConfig } from '../landing-pages/managed-office/interfaces/interfaces';
import { of } from 'rxjs';
import { ILandingPageExpertSectionConfig } from '../landing-pages/landing-pages.interface';
import {
    EnterprisesFormType,
    IEnterprisesFormConfig,
} from '../../shared/app-components/form/enterprises-form/enterprises-form.interface';
import { IRequestCallbackConfig } from '../../shared/standalone-components/request-callback-card/request-callback-card.interface';

export const OFFICE_SPACE_IMAGE_BASE_URL = `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/landing-pages/office-space`;
export const ENTERPRISES_IMAGE_BASE_URL = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.IMAGE_CONFIG_BASE_FQ}/web/ssr/pages/enterprises`;
export const formHeader = 'Ready to move-in office Spaces';
export const mobileFormHeader = 'Affordable Coworking Spaces';

export const productDetailHeroSectionConfig = {
    contentConfig: {
        image: {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.IMAGE_CONFIG_BASE_FQ}/web/ssr/pages/enterprises/hero/enterprises-hero-4.jpg`,
            alt: 'managed office space',
        },
        heading: 'Largest office space advisory platform for enterprises',
        desc: 'Find and secure serviced office spaces for your team pan India. Maximize your business value, increase productivity, and reduce costs by upgrading your office with our comprehensive solutions.',
        heroBgColorClassname: 'bg-color',
        templateBgColorClassname: 'bg-color-white',
    },
    link: {
        text: '',
        url: of(''),
    },
};

export const leadGenFormBenefits = [
    {
        description: 'Dedicated office space consultant',
        svgLogo: CommonIconsUrl.CHECK_BLACK,
    },
    {
        description: 'Pan India coverage',
        svgLogo: CommonIconsUrl.CHECK_BLACK,
    },
    {
        description: 'Zero Brokerage',
        svgLogo: CommonIconsUrl.CHECK_BLACK,
    },
];

export const leadGenFormConfig: IEnterprisesFormConfig = {
    mwebClose: {
        heading: mobileFormHeader,
        ctaText: 'Request Callback',
        benefits: leadGenFormBenefits,
    },
    mwebOpen: {
        heading: 'Enter your details to request callback',
        ctaText: 'Request Callback',
    },
    desk: {
        heading: formHeader,
        subHeading: 'Our office space experts will get in touch to help you with your requirements.',
        ctaText: 'Request Callback',
    },
    showSuccessWithCTA: true,
    formType: EnterprisesFormType.DEFAULT,
    formSource: formPosition.HERO,
    showCityDropdownField: true,
};

export const leadGenModalFormConfig: IEnterprisesFormConfig = {
    ...leadGenFormConfig,
    desk: {
        ...leadGenFormConfig.desk,
        subHeading: 'Please provide your contact information to view all available options.',
        benefits: leadGenFormBenefits,
    },
    submitButtonLabel: 'Get Quote',
    formType: EnterprisesFormType.MODAL,
    formSource: formPosition.MIDDLE,
    showCityDropdownField: true,
};

export const leadGenImageFormConfig: IEnterprisesFormConfig & IImageFormConfig = {
    ...leadGenFormConfig,
    desk: {
        heading: 'Let’s get in touch?',
        benefits: leadGenFormBenefits,
        ctaText: 'Request Callback',
    },
    showSuccessWithCTA: true,
    formType: EnterprisesFormType.DEFAULT,
    formSource: formPosition.HERO,
    image: {
        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/landing-pages/office-space/office-space-hero.jpg`,
        name: 'home landing page form footer',
    },
    showCityDropdownField: true,
};

export const leadGenBrandModalFormConfig: IEnterprisesFormConfig = {
    ...leadGenModalFormConfig,
    showCityDropdownField: true,
};

export const featuredBrandConfig: IFeaturedBrandsConfig = {
    heading: 'Our Network Spans Across 100+ Office Space Brands',
    hasTwoRows: true,
    staticBrandImages: [
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/smartworks.png`,
            alt: 'smartworks',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/indiqube.png`,
            alt: 'indiqube',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/awfis.png`,
            alt: 'awfis',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/wework.png`,
            alt: 'wework',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/springboard.png`,
            alt: 'springboard',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/bhive.png`,
            alt: 'bhive',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/tablespace.png`,
            alt: 'tablespace',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/devx.png`,
            alt: 'devx',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/incuspaze.png`,
            alt: 'incuspaze',
        },
        {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/brands/urban-vault.png`,
            alt: 'urban-vault',
        },
    ],
};

export const stateOfTheArtConfig: IStateOfTheArtConfig = {
    heading: 'All-inclusive, state-of-the-art office space for enterprises',
    items: [
        {
            heading: 'Office Space for everyone',
            description:
                'Choose from private cabins, ready-to-move-in offices and custom built office spaces for enterprises',
            icon: CommonIconsUrl.SETTINGS_ICON,
        },
        {
            heading: 'Dedicated end-to-end consultation',
            description:
                'Dedicated myHQ consultant for deciding the right strategy to finding appropriate locations and securing best prices',
            icon: CommonIconsUrl.SHINY_STAR_ICON,
        },
        {
            heading: 'Transparent & economic solutions',
            description:
                'Simplify your office space expenditure with the most cost-effective enterprise solutions in the industry',
            icon: CommonIconsUrl.CHECK_ROUNDED_ARROW_ICON,
        },
    ],
    contentConfig: {
        image: {
            url: `${ENTERPRISES_IMAGE_BASE_URL}/enterprises-chair.jpg`,
            name: 'Chairs',
        },
        template: null,
        imgFlx: 'flx-2',
        templateFlx: 'flx-3',
        isReverse: false,
        useImgWithUnderlay: true,
        underlayImagesize: ImageUnderlaySize.MEDIUM,
        isATFContent: true,
    },
};

export const pickRightOfficeSectionConfig = {
    heading: 'Our enterprise-grade office space solutions',
    subheading:
        'Choose the right fit from our portfolio of enterprise-grade offerings with partnered office spaces across India',
    officeItems: [
        {
            title: 'Managed Offices',
            description: 'Dedicated office space managed by a provider',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/managed-offices2.png`,
            features: [
                'Full-floor offices with private reception area',
                'Customized layouts, theme colors and design',
                'Fully managed operations & housekeeping',
                'Ideal for 50+ team size',
            ],
        },
        {
            title: 'Office/Commercial Spaces',
            description: 'Fully enclosed office for a single company',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/private-offices-private-access2.png`,
            features: [
                'Long term contracts (3 or more years)',
                'Full customizations with self managed amenities',
                'Avg. space requirement starts from 5,000 sq. ft',
                'Ideal for 100+ team size',
            ],
        },
        {
            title: 'Coworking Space',
            description: 'Private serviced office with shared amenities',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/private-offices-shared-access2.png`,
            features: [
                'Add/remove number of desks as per team size',
                'Hassle-free, immediately available',
                'Access to common space amenities',
                'Ideal for individual or small teams',
            ],
        },
        {
            title: 'Team Plan',
            description: 'Flexible day pass managed by one account',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/team-flexi.png`,
            features: [
                'Centralized dashboard for entire team',
                'Manage & track bookings at one place',
                'Centralized billing and expense management',
                'Ideal for remote, distributed & hybrid teams',
            ],
        },
        {
            title: 'Virtual Office',
            description: 'Registration & GST solutions for new businesses',
            icon: `${ENTERPRISES_IMAGE_BASE_URL}/virtual-office.png`,
            features: [
                'Benefits of a professional business address',
                'No physical office costs or high monthly rents',
                'Full documentation including rent agreement, NOC from landlord & utility bills',
            ],
        },
    ],
    hybridOfficeItems: [],
};

export const requestCallbackSectionConfig: IRequestCallbackConfig = {
    icon: CommonIconsUrl.NOT_SURE,
    title: 'Need expert consultation for the best office space solution for your team?',
    description:
        'Talk to our office space consultant for comprehensive and cost-effective rentals for premium office spaces.',
};

export const caseStudiesSectionConfig = {
    heading: 'Case Studies: How myHQ facilitated efficient office space rentals for enterprises',
    description:
        'Delivering the right office space for Acciojobs in a tight timeframe was a rewarding experience and the myHQ Team is thrilled to have facilitated a seamless experience for Acciojobs.',
    redirectUrl: 'https://digest.myhq.in/acciojob/',
    company: {
        image: `${ENTERPRISES_IMAGE_BASE_URL}/acciojob-workspace.png`,
        logo: `${OFFICE_SPACE_IMAGE_BASE_URL}/clients/acciojob.svg`,
        name: 'AccioJob',
    },
    config: {
        items: [
            {
                title: 'Scalable Office',
                description:
                    'Acciojobs finalised a dedicated full floor office at 91Springboard for a team growing at the rate of 20%',
            },
            {
                title: 'Design & Layout',
                description:
                    'Customized layout to include studio and training rooms, carpeting, branding and logo placement',
            },
            {
                title: 'Negotiation & Representation',
                description:
                    "Deal re-structuring to include Acciojobs's expansion plans and 20% reduction in overhead expenses",
            },
        ],
    },
};

export const companyStatsSectionConfig: ICompanyStatsConfig = {
    heading: 'India’s Leading Marketplace for Tech-enabled, Flexible Workspace Solutions',
    subheading:
        'Powered by ANAROCK Group- Largest independent real-estate consulting with over 1800 professionals and 80,000+ channel partners',

    statItems: [
        {
            title: `${AmConstants.OFFICE_SPACE_CONSULTANTS.toLocaleString()}+`,
            description: 'Expert Consultants',
        },
        {
            title: `${AmConstants.CLIENTS_SERVED.toLocaleString()}+`,
            description: 'Clients Served',
        },
        {
            title: `${AmConstants.PARTNER_SPACES.toLocaleString()}+`,
            description: 'Partner Spaces',
        },
        {
            title: `${AmConstants.SEATS_DELIVERED.toLocaleString()}+`,
            description: 'Seats Delivered',
        },
    ],
};

interface GetOfficeSpaceSectionConfig extends IStepperConfig {
    heading: string;
    subheading: string;
    linkText: string;
}

export const productDetailGetOfficeSpaceSectionConfig: GetOfficeSpaceSectionConfig = {
    heading: 'Get the right office space in 4 easy steps',
    subheading:
        'myHQ provides end-to-end enterprise office solutions with expert support throughout & beyond the entire process',
    linkText: 'Request Callback',
    steps: [
        {
            icon: `${CommonIconsUrl.LEASE_ICON}`,
            heading: 'Source/Lease',
            description: 'We help you pick your ideal property while connecting you with the office space vendors.',
        },
        {
            icon: `${CommonIconsUrl.TICK_CIRCLE_OUTLINE_PRIMARY}`,
            heading: 'Agreement & Closure',
            description: 'Our experts assist you throughout the term negotiations for the office space.',
        },
        {
            icon: `${CommonIconsUrl.BUILD_ICON}`,
            heading: 'Design/Build',
            description: 'Our experts help you finalize the layout and budget for the interiors.',
        },
        {
            icon: `${CommonIconsUrl.DOUBLE_SETTINGS_ICON}`,
            heading: 'Operate & Manage',
            description: 'Let the office space partner handle operations while you work seamlessly.',
        },
    ],
};

export const enterprisesTrustedBrandsConfig = {
    heading: 'Our network spans across 100+ office space brands',
    imageUrl: `${ENTERPRISES_IMAGE_BASE_URL}/enterprises-pi-trusted-brands-carousel.svg`,
};

export const expertSectionConfig: ILandingPageExpertSectionConfig = {
    heading: 'Our Experts are eager to help you!',
    desc: 'Our team has helped more than 10,000 businesses & professionals find their perfect office. Our experts will help you with:',
    features: [
        'Space shortlisting',
        'Space tours',
        'Price negotiation',
        'Contract Signing',
        'Timely Handover',
        'Onboarding & Settling',
    ],
    experts: [
        {
            name: 'Ayush Jindal',
            designation: 'Private Office Expert',
            experience: '4+ yrs experience',
            photo: `${ImageBaseUrl.IMAGE_URL_BASE}/f_auto,q_auto/w_128/web/ssr/common/myhq-experts/bangalore-expert-ayush.jpg`,
        },
        {
            name: 'Neeraj',
            designation: 'Fixed desk Expert',
            experience: '5+ yrs experience',
            photo: `${ImageBaseUrl.IMAGE_URL_BASE}/f_auto,q_auto/w_128/web/ssr/common/myhq-experts/mumbai-expert-neeraj.jpg`,
        },
        {
            name: 'Garima',
            designation: 'Coworking Expert',
            experience: '7+ yrs experience',
            photo: `${ImageBaseUrl.IMAGE_URL_BASE}/f_auto,q_auto/w_128/web/ssr/common/myhq-experts/gurugram-expert-garima.jpg`,
        },
    ],
};

export const howItWorksConfig = {
    heading: 'How it works?',
    items: [
        {
            heading: 'Request an office',
            description:
                'Leave your details with us and we will allocate a dedicated enterprise manager for you to understand your requirements',
            icon: CommonIconsUrl.DATA_INFO_ALERT_DARK,
        },
        {
            heading: 'Shortlist and tour spaces',
            description:
                'Our team will curate a list of workspaces based on your requirements and preferred locations. Schedule a tour for your shortlisted workspace, and our team will guide you through the evaluation during your visit.',
            icon: CommonIconsUrl.WHERE_TO_VOTE_DARK,
        },
        {
            heading: 'Negotiate terms',
            description:
                'Basis the spaces you like, our team will negotiate for you and help you get the best deal possible.',
            icon: CommonIconsUrl.COMPARE_ARROWS_DARK,
        },
        {
            heading: 'Sign the deal + move in',
            description: 'Sign the agreement and move in as soon as 7 days!',
            icon: CommonIconsUrl.ASSIGNMENT_TURNED_IN_DARK,
        },
    ],
    contentConfig: {
        image: {
            url: `${ENTERPRISES_IMAGE_BASE_URL}/state-of-the-art-workspace-image.png`,
            name: 'Chairs',
        },
        imgFlx: 'flx-1',
        underlayImagesize: ImageUnderlaySize.LARGE,
        template: null,
        templateFlx: 'flx-1',
        isReverse: false,
        useImgWithUnderlay: true,
        isATFContent: true,
    },
};
