<app-prefix-banner />
<app-sticky-container />

@if (isMobileAppStaticPage || isLandingPage) {
    <router-outlet></router-outlet>
}

@if (!isMobileAppStaticPage && !isLandingPage) {
    <app-common-header [hidden]="(isFullscreen$ | async)?.hideCommonHeader"></app-common-header>
    <router-outlet></router-outlet>
    <app-common-footer [hidden]="(isFullscreen$ | async)?.hideCommonFooter"></app-common-footer>
    <div id="commonRichData" #commonRichData></div>
    @if (showUnsupportedCityProductModal) {
        <div>
            <app-unsupported-city-product-modal
                [config]="unsupportedCityProductModalConfig"
                (hideModal)="closeUnsupportedCityProductModal()"
            ></app-unsupported-city-product-modal>
        </div>
    }
}
