@if (isAnimated) {
    <div
        appReactiveStickyHeader
        class="header-container animated-header"
        [ngClass]="{ 'search-active': headerSearchConfig?.locationWorkspaceSearchConfig?.showSearchModal }"
    >
        <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
}
@if (!isAnimated) {
    <div class="header-container">
        <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
}

<!-- Auth Modals -->
@defer (when renderVoAuthModal) {
    @if (renderVoAuthModal) {
        <app-login-modal></app-login-modal>
    }
}

@defer (when renderMeetingRoomModal) {
    @if (renderMeetingRoomModal) {
        <app-mobile-otp-login-modal></app-mobile-otp-login-modal>
    }
}

<ng-template #header>
    <header class="app-container position-relative">
        @if (!device.isMobile) {
            <div>
                <div class="header-cont d-flx j-cont-space-bet">
                    <div class="logo-city-cont d-flx a-items-c">
                        @if (isLogoClickable) {
                            <a [routerLink]="homepageUrl | async" aria-label="myhq"
                                ><ng-container *ngTemplateOutlet="logo"></ng-container
                            ></a>
                        }
                        @if (!isLogoClickable) {
                            <a aria-label="myhq"><ng-container *ngTemplateOutlet="logo"></ng-container></a>
                        }
                        @if (showCityChange) {
                            <div class="city-cont">
                                <app-select-city></app-select-city>
                            </div>
                        }
                        @if (showHeaderSearch) {
                            <div
                                class="header-search-bar-cont show-tab-desk"
                                [ngClass]="{ 'show-search-in-sticky': showHeaderSearchInSticky }"
                            >
                                @if (headerSearchVariant === HeaderSearchVariant.DETAILED) {
                                    @defer {
                                        <app-search [config]="detailedHeaderSearchConfig" />
                                    }
                                } @else {
                                    @defer {
                                        <app-header-search
                                            [config]="headerSearchConfig"
                                            (menuChangeEvent)="searchSolutionChangeHandler($event)"
                                            (toggleSearchModalEvent)="toggleModal($event)"
                                            (selectLocationWorkspaceEvent)="onLocationWorkspaceSelectEvent($event)"
                                        />
                                    }
                                }
                            </div>
                        }
                    </div>
                    <div class="menu-cont d-flx a-items-c">
                        @for (menu of menus; track menuItemTrackBy($index, menu)) {
                            @if (menu.show) {
                                <div class="menu-list-item">
                                    @switch (menu.type) {
                                        @case (menuType.DROPDOWN) {
                                            <app-menu-with-dropdown [menu]="menu" />
                                        }
                                        @case (menuType.LOCATION_DROPDOWN) {
                                            @defer {
                                                <app-location-dropdown
                                                    [title]="'Locations'"
                                                    [selectedCity]="selectedCity"
                                                    [product]="selectedProduct"
                                                />
                                            }
                                        }
                                        @case (menuType.LINK) {
                                            <ng-container *ngTemplateOutlet="menuWithLink; context: { menu }" />
                                        }
                                        @case (menuType.LINK_BTN) {
                                            <ng-container *ngTemplateOutlet="menuWithLinkBtn; context: { menu }" />
                                        }
                                        @case (menuType.LOGIN_SIGNUP) {
                                            <ng-container *ngTemplateOutlet="loginSignup" />
                                        }
                                        @case (menuType.LOGIN_SIGNUP_FLEXI) {
                                            <ng-container *ngTemplateOutlet="loginSignupFlexi" />
                                        }
                                        @case (menuType.LOGOUT_BTN) {
                                            <ng-container *ngTemplateOutlet="logoutBtn" />
                                        }
                                        @case (menuType.LOGOUT_BTN_FLEXI) {
                                            <ng-container *ngTemplateOutlet="logoutBtnFlexi" />
                                        }
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
        }
        @if (device.isMobile) {
            <div>
                <ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
            </div>
        }
    </header>
</ng-template>
@if (menuModalMobile.isOpen) {
    <app-modals [template]="mobileMenuTemplate" (hideModal)="toggleMenuModal(false)"></app-modals>
}

<ng-template #mobileMenuTemplate>
    <div class="header-modal-cont">
        <div class="padding-horizontal-16">
            <ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
        </div>
        <div class="d-flx flx-d-clm">
            @for (menu of menus; track menu) {
                @if (menu.show) {
                    @if (menu.type === menuType.DROPDOWN) {
                        @if (menu.mobileSingleMenu) {
                            <app-mobile-dropdown-menu [menu]="getSubMenu(menu)" (menuItemClick)="onMenuClick()" />
                        } @else {
                            @for (subMenu of menu.subMenus; track subMenu.id) {
                                <app-mobile-dropdown-menu [menu]="subMenu" (menuItemClick)="onMenuClick()" />
                            }
                        }
                    }
                    @if (menu.type === menuType.LINK) {
                        <ng-container *ngTemplateOutlet="menuWithLink; context: { menu: menu }"></ng-container>
                    }
                    @if (menu.type === menuType.LINK_BTN) {
                        <ng-container *ngTemplateOutlet="menuWithLinkBtn; context: { menu: menu }"></ng-container>
                    }
                    @if (menu.type === menuType.CLICKABLE_LINK_BTN) {
                        <ng-container
                            *ngTemplateOutlet="menuWithClickableLinkBtnMobile; context: { menu: menu }"
                        ></ng-container>
                    }
                    @if (menu.type === menuType.LOGIN_SIGNUP) {
                        <ng-container *ngTemplateOutlet="loginSignup"></ng-container>
                    }
                    @if (menu.type === menuType.LOGIN_SIGNUP_FLEXI) {
                        <ng-container *ngTemplateOutlet="loginSignupFlexi"></ng-container>
                    }
                    @if (menu.type === menuType.LOGOUT_BTN) {
                        <ng-container *ngTemplateOutlet="logoutBtn"></ng-container>
                    }
                    @if (menu.type === menuType.LOGOUT_BTN_FLEXI) {
                        <ng-container *ngTemplateOutlet="logoutBtnFlexi"></ng-container>
                    }
                    @if (menu.type === menuType.EXPANDABLE_LINKS_PANEL) {
                        <ng-container *ngTemplateOutlet="expandableLinksPanel; context: { menu: menu }"></ng-container>
                    }
                }
            }
        </div>
    </div>
</ng-template>

<ng-template #menuWithLink let-menu="menu">
    @if (menu.isExternalUrl) {
        <a [href]="menu.redirectUrl | async" target="_blank" (click)="onMenuClick()">
            <ng-container [ngTemplateOutlet]="menuItem" [ngTemplateOutletContext]="{ menu }"></ng-container>
        </a>
    } @else {
        <a
            [routerLink]="menu.redirectUrl | async"
            routerLinkActive="bg-color-grey"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="onMenuClick()"
        >
            <ng-container [ngTemplateOutlet]="menuItem" [ngTemplateOutletContext]="{ menu }"></ng-container>
        </a>
    }
    <ng-template #menuItem let-menu="menu">
        <div class="d-flx a-items-c gap-8 menu-with-link-cont">
            @if (device.isMobile) {
                <img width="16" height="16" [src]="menu.iconUrl" alt="i" />
            }
            <div class="title-desc-cont">
                <div class="title">{{ menu.title }}</div>
                @if (menu.description) {
                    <div class="desc">{{ menu.description }}</div>
                }
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #menuWithLinkBtn let-menu="menu">
    @if (!menu.isExternalUrl) {
        <a
            [routerLink]="menu.redirectUrl | async"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
            #rla="routerLinkActive"
            (click)="onMenuClick()"
        >
            <div class="d-flx a-items-c j-cont-cen menu-with-link-btn-cont" [ngClass]="cssConfig[menu.btnType]">
                {{ menu.title }}
            </div>
        </a>
    }
    @if (menu.isExternalUrl) {
        <a [href]="menu.redirectUrl | async" target="_blank" (click)="onMenuClick()">
            <div class="d-flx a-items-c j-cont-cen menu-with-link-btn-cont" [ngClass]="cssConfig[menu.btnType]">
                {{ menu.title }}
            </div>
        </a>
    }
</ng-template>
<ng-template #menuWithClickableLinkBtnMobile let-menu="menu">
    <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
    <div class="menu-link-btn-cont d-flx" (click)="onMenuBtnClick(menu.id)">
        <div class="d-flx a-items-c menu-with-link-btn-clickable-cont">
            <div class="menu-icon"><img class="icon" [src]="menu.iconUrl" alt="i" /></div>
            <div class="title-cont">
                <div class="title">{{ menu.title }}</div>
            </div>
        </div>
        @if (menu.showArrow) {
            <div class="icon-cont">
                <img class="icon" [src]="commonIconsUrl.RIGHT_CHEVRON_GREY" alt="i" />
            </div>
        }
    </div>
</ng-template>

<ng-template #mobileHeader let-subMenus="subMenus">
    <div class="header-cont d-flx j-cont-space-bet a-items-c">
        <div class="d-flx">
            @if (showMenuIconOnMobile) {
                <div class="icon-cont d-flx a-items-c">
                    <div
                        class="svg-cont"
                        [ngClass]="{ 'close-icon-cont': menuModalMobile.isOpen }"
                        (click)="toggleMenuModal(!this.menuModalMobile.isOpen)"
                        [innerHTML]="menuModalMobile.isOpen ? crossIcon : menuIcon"
                    ></div>
                </div>
            }
            @if (isLogoClickable) {
                <a [routerLink]="homepageUrl | async" aria-label="myhq" (click)="onMenuClick()">
                    <ng-container *ngTemplateOutlet="logo"></ng-container>
                </a>
            }
            @if (!isLogoClickable) {
                <a aria-label="myhq"><ng-container *ngTemplateOutlet="logo"></ng-container></a>
            }
        </div>
        <div class="d-flx a-items-c">
            @if (showCityChange) {
                <div class="city-cont">
                    <app-select-city></app-select-city>
                </div>
            }
            @if (showHeaderSearch) {
                <div class="d-flx search-icon">
                    @if (headerSearchVariant === HeaderSearchVariant.DETAILED) {
                        @defer {
                            <app-search [config]="detailedHeaderSearchConfig" />
                        }
                    } @else {
                        @defer {
                            <app-location-workspace-mobile-modal-trigger
                                [locationWorkspaceSearchConfig]="locationWorkspaceSearchConfig"
                                [config]="locationWorkspaceHeaderSearchMob"
                                (locationWorkspaceSelectEvent)="onLocationWorkspaceSelectEvent($event)"
                            />
                        }
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #loginSignup>
    <app-login-signup-menu-item></app-login-signup-menu-item>
</ng-template>

<ng-template #loginSignupFlexi>
    <app-login-signup-flexi-menu-item></app-login-signup-flexi-menu-item>
</ng-template>

<ng-template #expandableLinksPanel let-menu="menu">
    <div class="d-flx flx-d-clm gap-12 padding-horizontal-16 padding-vertical-12">
        <div class="d-flx gap-8">
            <img width="16" height="16" [src]="menu.iconUrl" alt="i" />
            <div class="d-flx flx-d-clm gap-4">
                <div class="font-bold font-12">{{ menu.title }}</div>
                @if (menu.description) {
                    <div class="font-10 text-color-light-grey">{{ menu.description }}</div>
                }
            </div>
        </div>
        <app-expandable-links-panel
            (toggleMenuModal)="toggleMenuModal(false)"
            [config]="menu?.expandableLinks"
        ></app-expandable-links-panel>
    </div>
</ng-template>

<ng-template #logoutBtn>
    <app-logout-button [product]="productKeys.VIRTUAL_OFFICE"></app-logout-button>
</ng-template>
<ng-template #logoutBtnFlexi>
    <app-logout-button [product]="productKeys.MEETING_ROOM"></app-logout-button>
</ng-template>

<ng-template #logo>
    <div class="logo-cont d-flx a-items-c svg-cont" [innerHTML]="logoIcon"></div>
</ng-template>
