@if (bannerInstance$ | async; as bannerInstance) {
    <div #appPrefixBanner [class.sticky-banner]="bannerInstance?.bannerConfig?.isSticky">
        @if (bannerInstance?.visible) {
            @switch (bannerInstance?.bannerConfig?.type) {
                @case (productKeys.FLEXI) {
                    <div class="banner-cont">
                        <div class="close-icon-cont c-pointer" role="button" (click)="hideBanner()">
                            <img class="close-image" [src]="closeIcon.url" [alt]="closeIcon.alt" />
                        </div>
                        <div class="d-flx j-cont-space-bet a-items-c">
                            <div class="left-sec-cont">
                                <div class="heading-cont">
                                    <h5>Download the myHQ app</h5>
                                </div>
                                <div class="desc-cont">
                                    <p>Get a free trial at workspace of your choice</p>
                                </div>
                            </div>
                            <div class="right-sec-cont c-pointer" (click)="redirectToDownloadApp()">
                                <div class="button-cont">Get App</div>
                            </div>
                        </div>
                    </div>
                }
                @case (productKeys.VIRTUAL_OFFICE) {
                    <app-virtual-office-banner (onHideBanner)="hideBanner()"></app-virtual-office-banner>
                }
            }
        }
    </div>
}
