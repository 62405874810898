import { Component, Input } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { AmConstants, ImageBaseUrl } from '../../../../core/constants/constants';
import { OptimizedImageComponent } from '../../optimized-image/optimized-image.component';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from '!!raw-loader!svgo-loader!../../../../../assets/web/ssr/common/logo/logo-flat.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StarsIcon from '!!raw-loader!svgo-loader!../../../../../assets/web/ssr/common/assets/stars.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StarIcon from '!!raw-loader!svgo-loader!../../../../../assets/web/ssr/common/assets/star.svg';
import { ILeadFormSideBannerConfig } from './lead-form-side-banner.interface';

@Component({
    standalone: true,
    selector: 'app-lead-form-side-banner',
    templateUrl: './lead-form-side-banner.component.html',
    styleUrls: ['./lead-form-side-banner.component.scss'],
    imports: [OptimizedImageComponent],
})
export class LeadFormSideBannerComponent {
    @Input() config: ILeadFormSideBannerConfig = {
        heading: `Trusted by over ${AmConstants.CLIENTS_SERVED.toLocaleString()}+ Clients `,
        clientsLogo: {
            url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/clinets/am-clients-2.svg`,
            alt: 'banner',
        },
        features: ['Zero Brokerage Fees', 'Largest Coverage', 'Dedicated Workspace Experts', '100% Verified Listings'],
        featuresTitle: 'India’s Leading Office Space Provider',
    };

    logoIcon = this.sanitizer.bypassSecurityTrustHtml(Logo);
    starsIcon = this.sanitizer.bypassSecurityTrustHtml(StarsIcon);
    starIcon = this.sanitizer.bypassSecurityTrustHtml(StarIcon);

    constructor(private sanitizer: DomSanitizer) {}
}
