import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { IAuthDropdownItem } from '../../common-header.interface';
import { UrlGeneratorService } from '../../../../services/url-generator/url-generator.service';
import { menuEnum, menuType } from '../../enums/enums';
import { ICity } from '../../../../interfaces/location.interface';
import { buttonType, fontSizes, fontWeights, productsMap } from '../../../../enums/enums';
import { Subscription } from 'rxjs';
import { IButtonConfig } from '../../../../interfaces/button.interface';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { AuthStatus } from '../../../../../modules/auth/auth.enum';
import { IUserInfoResponse } from '../../../../../modules/auth/auth.interface';
import { environment } from '../../../../../../environments/environment';
import { SelectCityService } from '../../../../services/select-city/select-city.service';
import { NgTemplateOutlet, NgClass, TitleCasePipe } from '@angular/common';
import { ButtonsModule } from '../../../../ui-components/buttons/buttons.module';
import { DirectivesModule } from '../../../../directives/directives.module';
import { PipeModule } from '../../../../pipe/pipe.module';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ImageBaseUrl } from '../../../../../core/constants/constants';

@Component({
    selector: 'app-login-signup-menu-item',
    templateUrl: './login-signup-menu-item.component.html',
    styleUrls: ['./login-signup-menu-item.component.scss'],
    standalone: true,
    imports: [ButtonsModule, DirectivesModule, NgTemplateOutlet, NgClass, PipeModule, TitleCasePipe],
})
export class LoginSignupMenuItemComponent implements OnInit, OnDestroy {
    menuType = menuType;
    deviceInfo = this.utilsService.getDeviceInfo();
    dropdownLabel = 'Login / Sign up';
    userData: IUserInfoResponse;
    isLoggedIn = false;
    CommonIconsUrl = CommonIconsUrl;
    showDropdown = false;
    dropdownListItems: IAuthDropdownItem[] = [
        {
            id: menuEnum.PROFILE,
            type: menuType.ACCORDION,
            label: 'My Profile',
            show: true,
            icon: {
                url: CommonIconsUrl.PERSON_OUTLINED,
                alt: 'My Profile',
            },
            onClick: () => {
                this.handleDashboardRedirect(menuEnum.PROFILE);
            },
            comingSoon: false,
            items: [
                {
                    id: menuEnum.FLEXI,
                    type: menuType.LINK,
                    label: 'Day Pass',
                    show: true,
                    onClick: () => {
                        this.handleDashboardRedirect(menuEnum.FLEXI);
                    },
                },
                {
                    id: menuEnum.MEETING_ROOM,
                    type: menuType.LINK,
                    label: 'Meeting Room',
                    show: true,
                    onClick: () => {
                        this.handleDashboardRedirect(menuEnum.MEETING_ROOM);
                    },
                },
                {
                    id: menuEnum.VIRTUAL_OFFICE,
                    type: menuType.ACCORDION,
                    label: 'Virtual Office',
                    onClick: () => {
                        this.handleDropdownToggle(menuEnum.VIRTUAL_OFFICE);
                    },
                    show: true,
                    isOpen: true,
                    items: [
                        {
                            id: menuEnum.VO_SUBSCRIPTIONS,
                            type: menuType.LINK,
                            label: 'Subscriptions',
                            show: true,
                            onClick: () => {
                                this.handleDashboardRedirect(menuEnum.VO_SUBSCRIPTIONS);
                            },
                        },
                        {
                            id: menuEnum.VO_HELP_SUPPORT,
                            type: menuType.LINK,
                            label: 'Help & Support',
                            show: true,
                            onClick: () => {
                                this.handleDashboardRedirect(menuEnum.VO_HELP_SUPPORT);
                            },
                        },
                    ],
                },
                {
                    id: menuEnum.DEDICATED,
                    type: menuType.LINK,
                    label: 'Coworking Space',
                    show: false,
                    icon: {
                        url: CommonIconsUrl.FIXED_DESK_OUTLINED,
                        alt: 'Coworking Space',
                    },
                    url: this.urlGeneratorService.dedicatedProductUrl,
                    comingSoon: false,
                },
            ],
            isOpen: true,
        },
    ];

    logoutDropdownItem: IAuthDropdownItem = {
        id: menuEnum.LOGOUT,
        type: menuType.LINK,
        label: 'Log out',
        show: true,
        icon: {
            url: CommonIconsUrl.LOGOUT,
            alt: 'Log out',
        },
        comingSoon: false,
        onClick: () => {
            this.handleLogout();
        },
        cssClass: 'danger',
    };
    loginBtnConfig: IButtonConfig = {
        label: 'Login / Sign Up',
        type: buttonType.PRIMARY,
        size: fontSizes.MEDIUM_MOB_DESK,
        fontWeight: fontWeights.BOLD,
    };
    selectCitySub: Subscription;
    authStatusSub: Subscription;
    authUserDetailsSub: Subscription;

    constructor(
        private urlGeneratorService: UrlGeneratorService,
        private selectCityService: SelectCityService,
        private utilsService: UtilsService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(cityData => {
            if (cityData.city) {
                this.updateProductItemsStatus(cityData.city);
            }
        });
        this.authStatusSub = this.authService.getAuthStatusSub().subscribe(authStatus => {
            this.isLoggedIn = authStatus === AuthStatus.AUTHENTICATED;
            this.setDropdownLabel();
        });
        this.authUserDetailsSub = this.authService.getUserDataSub().subscribe(userData => {
            this.userData = userData;
            this.setDropdownLabel();
            this.setResellerDropdownListItems();
        });
    }

    ngOnDestroy() {
        if (this.selectCitySub) {
            this.selectCitySub.unsubscribe();
        }
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
        if (this.authUserDetailsSub) {
            this.authUserDetailsSub.unsubscribe();
        }
    }

    toggleDropdown(show: boolean): void {
        this.showDropdown = show;
    }

    handleLogout() {
        this.authService.logout().subscribe(success => {
            if (success) {
                this.authService.setUserData(null);
                this.authService.setAuthStatus(AuthStatus.UNAUTHENTICATED);
            }
        });
        this.toggleDropdown(false);
    }

    updateProductItemsStatus(city: ICity) {
        const supportedProducts = city.products.map(product => {
            return product.key;
        });

        this.dropdownListItems = this.dropdownListItems.map(listItem => {
            const product = this.getProduct(listItem.id);
            if (product && Object.keys(productsMap).includes(product.productNameFrontend)) {
                return {
                    ...listItem,
                    comingSoon: !supportedProducts.includes(product.productNameBackend),
                };
            }
            return listItem;
        });
    }

    getProduct(menuId: menuEnum) {
        switch (menuId) {
            case menuEnum.FLEXI:
                return productsMap.FLEXI;
            case menuEnum.DEDICATED:
                return productsMap.DEDICATED;
            case menuEnum.VIRTUAL_OFFICE:
                return productsMap.VIRTUAL_OFFICE;
        }
    }

    setDropdownLabel() {
        if (this.isLoggedIn && this.userData) {
            const { user } = this.userData;

            if (user?.name) {
                this.dropdownLabel = `Hi ${user.name}`;
            } else {
                this.dropdownLabel = 'My Account';
            }
        } else {
            this.dropdownLabel = 'Login / Sign up';
        }
    }

    setResellerDropdownListItems() {
        this.dropdownListItems = [
            {
                id: menuEnum.PROFILE,
                type: menuType.ACCORDION,
                label: 'My Profile',
                show: true,
                icon: {
                    url: CommonIconsUrl.PERSON_OUTLINED,
                    alt: 'My Profile',
                },
                onClick: () => {
                    this.handleDashboardRedirect(menuEnum.VO_SUBSCRIPTIONS);
                },
                comingSoon: false,
                items: [
                    {
                        id: menuEnum.VIRTUAL_OFFICE,
                        type: menuType.ACCORDION,
                        label: 'Virtual Office',
                        onClick: () => {
                            this.handleDropdownToggle(menuEnum.VIRTUAL_OFFICE);
                        },
                        show: true,
                        isOpen: true,
                        items: [
                            {
                                id: menuEnum.VO_SUBSCRIPTIONS,
                                type: menuType.LINK,
                                label: 'Subscriptions',
                                show: true,
                                onClick: () => {
                                    this.handleDashboardRedirect(menuEnum.VO_SUBSCRIPTIONS);
                                },
                            },
                        ],
                    },
                ],
                isOpen: true,
            },
        ];
    }

    handleMobileLoginCTA() {
        if (!this.isLoggedIn) {
            this.handleLoginCTA();
        } else {
            this.handleLogout();
        }
    }

    handleLoginCTA() {
        this.authService.toggleLoginModal(true);
    }

    handleDashboardRedirect(menuItem: menuEnum) {
        let url = '';
        switch (menuItem) {
            case menuEnum.PROFILE:
                url = `${environment.loginDashboardUrl}`;
                break;
            case menuEnum.FLEXI:
                url = `${environment.loginDashboardUrl}/flexi`;
                break;
            case menuEnum.MEETING_ROOM:
                url = `${environment.loginDashboardUrl}/meeting-room`;
                break;
            case menuEnum.VIRTUAL_OFFICE:
                url = `${environment.loginDashboardUrl}/virtual-office/subscriptions`;
                break;
            case menuEnum.VO_SUBSCRIPTIONS:
                url = `${environment.loginDashboardUrl}/virtual-office/subscriptions`;
                break;
            case menuEnum.VO_HELP_SUPPORT:
                url = `${environment.loginDashboardUrl}/virtual-office/support`;
                break;
        }
        window.location.href = url;
    }

    handleDropdownToggle(menuItem: menuEnum) {
        this.dropdownListItems = this.dropdownListItems.map(item => {
            if (item.type === menuType.ACCORDION) {
                if (item.id === menuItem) {
                    return { ...item, isOpen: !item.isOpen };
                } else {
                    item.items = item.items.map(subItem => {
                        if (subItem.type === menuType.ACCORDION && subItem.id === menuItem) {
                            return { ...subItem, isOpen: !subItem.isOpen };
                        }
                        return subItem;
                    });
                    return { ...item };
                }
            }

            return item;
        });
    }

    itemById(index: number, item: IAuthDropdownItem) {
        return item.id;
    }

    calcStyles(level: number) {
        const borderStyle = '1px solid #E0E0E0';

        if (level <= 2) {
            return {
                'margin-left': `${24 * (level - 1)}px`,
            };
        } else {
            return {
                'margin-left': `40px`,
                'border-left': borderStyle,
            };
        }
    }
}
