import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, startWith } from 'rxjs';
import { IBannerConfig } from './prefix-banner.interface';

@Injectable({
    providedIn: 'root',
})
export class PrefixBannerService {
    private bannerConfig$ = new BehaviorSubject<IBannerConfig | null>(null);
    private visible$ = new BehaviorSubject(false);
    private bannerInstance$ = combineLatest({
        bannerConfig: this.bannerConfig$,
        visible: this.visible$,
    });
    private height$ = new BehaviorSubject<number>(0);

    getStickyPrefixBannerHeight() {
        return combineLatest([this.getBannerInstance(), this.getHeight().pipe(startWith(0))]).pipe(
            map(([bannerInstance, height]) => {
                const h = bannerInstance?.bannerConfig?.isSticky ? height : 0;
                return Math.max(h, 0);
            })
        );
    }

    setAndShow(value: IBannerConfig) {
        this.bannerConfig$.next(value);
        this.visible$.next(true);
    }

    setHeight(height: number) {
        this.height$.next(height);
    }

    set(value: IBannerConfig) {
        this.bannerConfig$.next(value);
    }

    hide() {
        this.visible$.next(false);
        this.height$.next(0);
    }

    getBannerInstance() {
        return this.bannerInstance$;
    }

    getHeight() {
        return this.height$.asObservable();
    }
}
