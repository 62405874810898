// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Sanitizer } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { LinkWithIconSize } from './link-with-icon.enum';
import { DomSanitizer } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RightArrowIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/right-arrow-primary-flat.svg';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
export enum LineBreakType {
    SHOW_ELLIPSES = 'SHOW_ELLIPSES', // This is Default type
    BREAK_LINE = 'BREAK_LINE',
}

@Component({
    selector: 'app-link-with-icon',
    templateUrl: './link-with-icon.component.html',
    styleUrls: ['./link-with-icon.component.scss'],
    standalone: true,
    imports: [RouterLink, NgClass, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkWithIconComponent implements OnInit {
    constructor(private sanitizer: DomSanitizer) {}
    // TODO:: organise input in single object and declaring interface, Change default externalUrl To false;
    // create enum kind of thing to control color of link
    rightArrowIcon = this.sanitizer.bypassSecurityTrustHtml(RightArrowIcon);
    @Input() LinkText: string;
    @Input() url: string;
    @Input() hideEllipsis: string;
    @Input() isExternalUrl? = true;
    @Input() linkColor: string;
    @Input() lineBreakType?: LineBreakType;
    @Input() target? = '_self';
    @Input() size: LinkWithIconSize = LinkWithIconSize.MEDIUM;
    @Input() isFullWidth: boolean;
    @Input() disabled: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams?: any;

    // Used in analytics to track link click
    @Input()
    set sourceTrack(value: string) {
        if (this.queryParams) {
            this.queryParams = { ...this.queryParams, trk: value };
        } else {
            this.queryParams = { trk: value };
        }
    }

    @Output() callback = new EventEmitter();

    commonIconsUrl = CommonIconsUrl;
    cssConfigArray = [];

    ngOnInit() {
        // updating the cssConfig
        if (this.linkColor) {
            this.cssConfigArray.push(this.linkColor);
        }
        if (this.size) {
            this.cssConfigArray.push(this.size);
        }
        if (this.lineBreakType === LineBreakType.BREAK_LINE) {
            this.cssConfigArray.push('show-line-break');
        }
        if (this.isFullWidth) {
            this.cssConfigArray.push('full-width');
        }
    }

    onClickEmitEvent($event) {
        this.callback.emit($event);
    }
}
