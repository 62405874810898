import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule as BootstrapAccordionModule } from 'ngx-bootstrap/accordion';
import { AccordionComponent } from './accordion.component';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
    declarations: [AccordionComponent],
    imports: [CommonModule, BootstrapAccordionModule.forRoot(), DirectivesModule],
    exports: [AccordionComponent],
})
export class AccordionModule {} // TODOANGULAR14
